import { Dataset } from '../types';

// Feature flag pending rollout to prod
const STAGE = process.env['REACT_APP_STAGE'] || 'local';
const SUPPORTED_DATASET_TYPES =
  STAGE === 'dev' || STAGE === 'local'
    ? ['LAB', 'MEDICALCLAIMS', 'RXCLAIMS', 'EMR', 'HOSPITAL']
    : ['LAB', 'MEDICALCLAIMS', 'RXCLAIMS', 'EMR', 'HOSPITAL'];
const SUPPORTED_DATASET_IDS = STAGE === 'dev' || STAGE === 'local' ? [27, 263] : [27, 263];

export const isSupportedDataset = (dataset: Dataset) => {
  return (
    SUPPORTED_DATASET_TYPES.includes(dataset.datatype) || SUPPORTED_DATASET_IDS.includes(dataset.id)
  );
};
