import * as querystring from 'querystring';

import { Dictionary, ApplicationError } from '@healthverity/core';
import { HttpRequestProvider, HttpRequest, HttpResponse } from '../rest';

export const httpRequest: HttpRequestProvider = async (
  request: HttpRequest
): Promise<HttpResponse> => {
  const { baseUri, path: urlPath, method, headers, queryString, body } = request;
  const requestUrl = baseUri
    ? `${baseUri.endsWith('/') ? baseUri : `${baseUri}/`}${
        urlPath.startsWith('/') ? urlPath.substring(1) : urlPath
      }`
    : urlPath;
  const url = queryString ? `${requestUrl}?${querystring.stringify(queryString)}` : requestUrl;

  const conformedHeaders: [string, string][] = Object.entries(headers || {}).flatMap(
    ([name, value]) =>
      Array.isArray(value)
        ? (value.map((valueItem) => [name, valueItem]) as [string, string][])
        : [[name, value]]
  );

  const response = await fetch(url, {
    method,
    ...{ headers: conformedHeaders },
    ...(body && { body: JSON.stringify(body) }),
  });

  // unpack the headers //
  const responseHeaders: Dictionary<string> = {};
  response.headers.forEach((value, key) => (responseHeaders[key] = value));
  // console.log('response headers', responseHeaders);

  const responseBody = await response.json();
  if (response.status > 299) {
    const { error, ...otherBody } = responseBody;
    return {
      statusCode: response.status,
      statusText: response.statusText,
      headers: responseHeaders,
      body: otherBody,
      error: new ApplicationError(`Request failed with status code: ${response.status}`, error),
    };
  }

  return {
    statusCode: response.status,
    ...(response.statusText && { statusText: response.statusText }),
    headers: responseHeaders,
    body: responseBody,
  };
};
