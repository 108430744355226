import { Link } from 'react-router-dom';

import './DisplayToggle.scss';

export default function DisplayToggle({ isQuery }: { isQuery: boolean }) {
  const leftSelector = isQuery ? 'selected' : 'unselected';
  const rightSelector = isQuery ? 'unselected' : 'selected';
  return (
    <div className="toggle-container">
      <Link to={'/'} className={`button left ${leftSelector}`}>
        Queries
      </Link>
      <Link to={'/reports'} className={`button right ${rightSelector}`}>
        Reports
      </Link>
    </div>
  );
}
