import React, { useContext, useState, ReactText } from 'react';

import { toast } from 'react-toastify';

import GenericModal from '../Modal/shared/GenericModal';
import { ValueSet, ValueSetData } from '../../types';
import { generateRandomCharacterString } from '../../utils/random_characters';
import { ValueSetContext } from '../AppContent';
import { codeTypesWithMandatoryUppercase } from '../../constants';

import './ValueSetCreationModal.scss';

export function ValueSetCreationModal({
  selectedValueSets,
  setSelectedValueSets,
  isOpen,
  setIsOpen,
  valuesList,
  valuesCodeType,
  setValueCsvString,
}: {
  selectedValueSets: ValueSet[];
  setSelectedValueSets: (newSets: ValueSet[]) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  valuesList: string[];
  valuesCodeType: string;
  setValueCsvString: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [valueSetName, setValueSetName] = useState<string>('');
  const [valueSetDescription, setValueSetDescription] = useState<string>('');
  const VALUE_SET_ID_LENGTH = 8;

  const vSetCreationToastId = React.useRef<ReactText>('');

  const valueSetData: ValueSetData = useContext(ValueSetContext);
  const valueSetBackend = valueSetData.valueSetBackend;

  const setValueSetInfo = async () => {
    const newValueSetID: string = 'VS-'.concat(generateRandomCharacterString(VALUE_SET_ID_LENGTH));
    const cleanedValuesList = codeTypesWithMandatoryUppercase.includes(valuesCodeType)
      ? valuesList.map((val) => {
          return val.toUpperCase();
        })
      : valuesList;

    const newValueSet: ValueSet = {
      value_set_id: newValueSetID,
      name: valueSetName,
      values: cleanedValuesList,
      code_type: valuesCodeType,
      description: valueSetDescription,
    };

    closeAndClearState();
    if (valueSetBackend) {
      vSetCreationToastId.current = toast('Creating Value Set...', { autoClose: false });

      const valueSetsCopy = [...valueSetData.valueSets];
      const selectedValueSetsCopy = [...selectedValueSets];

      valueSetData.setValueSetData([]);
      let listValueSetsResults = [];
      try {
        // Create new Value Set
        await valueSetBackend.createValueSet(newValueSet);

        // Refresh all Value Sets
        listValueSetsResults = await valueSetBackend.listValueSets();
      } catch {
        toast.update(vSetCreationToastId.current, {
          render: "We're sorry, there was an issue with creating your Value Set.",
          type: toast.TYPE.WARNING,
          autoClose: 3000,
        });
        valueSetData.setValueSetData(valueSetsCopy);
        return;
      }
      toast.update(vSetCreationToastId.current, {
        render: 'Value Set successfully created!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      valueSetData.setValueSetData(listValueSetsResults.data);

      // Autopopulate currently selected sets with newly created Value Set
      const newValueSetFromBackend = listValueSetsResults.data.find(
        (vSet: ValueSet) => vSet.value_set_id === newValueSetID
      );
      if (newValueSetFromBackend && !selectedValueSetsCopy.includes(newValueSetFromBackend)) {
        setSelectedValueSets(selectedValueSetsCopy.concat([newValueSetFromBackend]));
      }

      // Clear selected codes now that the value set is created/added
      setValueCsvString('');
    }
  };

  const closeAndClearState = () => {
    // Clear all current component state when closing this modal
    setValueSetName('');
    setValueSetDescription('');
    setIsOpen(false);
  };

  return (
    <GenericModal
      headerText="Create Value Set"
      customModalClassName="value-set-creation-modal"
      customDismissButtonClassName="close-value-set-creation"
      customConfirmButtonClassName="save-value-set-creation"
      modalBodyClassName="value-set-creation-modal-body"
      show={isOpen}
      close={closeAndClearState}
      confirmButtonText={'Save'}
      dismissButtonText={'Cancel'}
      onSubmit={(e) => {
        e.preventDefault();
        setValueSetInfo();
      }}
      disableConfirm={!valueSetName}
    >
      <input
        type="text"
        placeholder="Value set name"
        className="gray-text-input"
        data-testid="value-set-name-text-box"
        onChange={(event: { target: { value: any } }) => setValueSetName(event.target.value)}
      />
      <textarea
        placeholder="Value set notes (Optional)"
        className="gray-text-input"
        data-testid="value-set-notes-text-box"
        cols={40}
        rows={10}
        onChange={(event: { target: { value: any } }) => setValueSetDescription(event.target.value)}
      />
    </GenericModal>
  );
}
