import { useState, FormEvent } from 'react';

import Select from 'react-select';

import GenericModal from './shared/GenericModal';
import { Dataset } from '../../types';

type AddFeedModalProps = {
  show: boolean;
  close: () => void;
  datasets: Dataset[];
  addFeeds: (datasets: Dataset[]) => void;
};

const AddFeedModal = ({ show, close, datasets, addFeeds }: AddFeedModalProps) => {
  const [selectedDatasets, setSelectedDatasets] = useState<Dataset[]>([]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    addFeeds(selectedDatasets);
    close();
  };

  const body = (
    <div className="query-blder-add-feed-body">
      <Select
        isMulti
        options={datasets}
        getOptionValue={(option) => option.id.toString()}
        getOptionLabel={(option) => option.name + ' - ' + option.datatype}
        onChange={(options) => setSelectedDatasets(options as Dataset[])}
        className="basic-single"
        classNamePrefix="select"
        name="feed"
      />
    </div>
  );

  return (
    <GenericModal
      headerText={'Add Feed'}
      show={show}
      close={close}
      dismissButtonText={'Close'}
      onSubmit={onSubmit}
      fixedWidth="720px"
    >
      {body}
    </GenericModal>
  );
};

export default AddFeedModal;
