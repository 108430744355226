import { Table } from 'semantic-ui-react';
import { StandardizedReferenceDataRow } from '../../../../types';

import './LabResultsTable.scss';

export const LabResultsTable = ({
  searchResults,
  selectedCodes,
  handleReferenceCodeToggle,
}: {
  searchResults: StandardizedReferenceDataRow[];
  selectedCodes: string;
  handleReferenceCodeToggle: (codesToAddOrRemove: string[], checkboxIsNowChecked: boolean) => void;
}) => {
  /**
   * Table to display Reference Service results relating to Lab/LIONC data
   *
   * @param searchResults - The results from the Reference Data Service
   * @param selectedCodes - Comma separated value string of all currently selected codes for this criteria type, ie "code1,code2"
   * @param handleReferenceCodeToggle - Setter which allows a user's selections in this component to be reflected upstream in the form
   */

  // Convert incoming valueCsvString string values to array of strings for easier manipulation
  const selectedCodesAsList = selectedCodes
    .split(',')
    .map((s) => s.trim())
    .filter(Boolean);

  const formatIDElement = (unformattedString: string): string =>
    unformattedString.toLowerCase().replaceAll(' ', '_');

  const isRowChecked = (codeToCheck: string) => {
    /** 
      Handles determining if a checkbox (single or multiple) should be in "clicked state"
      This will handle checkboxes for both single codes and for hierarchy/grouped codes
    */
    let selectedCodesSet: Set<string> = new Set([...selectedCodesAsList]);
    return selectedCodesSet.has(codeToCheck);
  };

  const renderRow = (row: StandardizedReferenceDataRow) => {
    const codeIsChecked = isRowChecked(row['code']);
    const checkboxID = formatIDElement('checkbox_code_' + row['code']);
    const labelJsx = (
      <label key={row['code']} htmlFor={checkboxID} className="lab-table-code-select">
        {row['code']}
      </label>
    );
    return (
      <Table.Row key={checkboxID}>
        <Table.Cell className="lab-results-table-checkbox-col">
          <input
            type="checkbox"
            onChange={(e) => handleReferenceCodeToggle([row['code']], !codeIsChecked)}
            checked={codeIsChecked}
            id={checkboxID}
          />
        </Table.Cell>
        <Table.Cell>{labelJsx}</Table.Cell>
        <Table.Cell>{row['name']}</Table.Cell>
      </Table.Row>
    );
  };

  // Render the table containing all lab results
  return (
    <Table fixed compact data-testid="lab-results-table" className='lab-results-table'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} className="lab-results-table-checkbox-col"></Table.HeaderCell>
          <Table.HeaderCell width={3}>LOINC</Table.HeaderCell>
          <Table.HeaderCell>Common name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{searchResults.map((row) => renderRow(row))}</Table.Body>
    </Table>
  );
};
