export type QueryExecution = QueryDetails & {
  query: Query;
};

export type QueryDetails = {
  name: string;
  description: string;
  opportunity_id: string;
};

export type QueryDetailDisplaySettings = {
  name: FieldDisplaySettings;
  description: FieldDisplaySettings;
  opportunity_id: FieldDisplaySettings;
};

export type Query = {
  version: string;
  inclusion_block: QueryExecutionBlock;
  exclusion_block: QueryExecutionBlock;
};

export type QueryExecutionBlock = {
  join_type: string;
  feed_groups: Array<QueryExecutionFeedGroup>;
};

export type QueryExecutionFeedGroup = {
  dataset_ids: Array<string>;
  criteria: Array<QueryCriteria>;
};

export type QueryBlocks = {
  inclusion_block: QueryBlock;
  exclusion_block: QueryBlock;
};

export type QueryBlock = {
  join_type: string;
  feed_groups: QueryFeedGroups;
};

export type QueryFeedGroups = {
  [id: string]: QueryFeedGroup;
};

export type QueryFeedGroup = {
  feeds: Array<Dataset>;
  criteria: Array<QueryCriteria>;
};

export type QueryCriteria =
  | QueryCriteriaAge
  | QueryCriteriaDateRange
  | QueryCriteriaDiagnosis
  | QueryCriteriaProcedure
  | QueryCriteriaLab
  | QueryCriteriaDrug
  | QueryCriteriaEnrollment
  | QueryCriteriaCohort
  | QueryCriteriaMortality;

export type QueryTextCriteria =
  | QueryCriteriaLabText
  | QueryCriteriaClinicalObservation
  | QueryCriteriaChargeDescription;

export type QueryCriteriaAge = {
  criteria_type: string | null;
  start_age?: string | null;
  end_age?: string | null;
  value_sets?: Array<ValueSet>;
};

export enum DateRangeType {
  service = 'service',
  encounter = 'encounter',
}

export type QueryCriteriaDateRange = {
  criteria_type: string | null;
  start_date?: string | null;
  end_date?: string | null;
  date_range_type: DateRangeType;
};

export type QueryCriteriaDiagnosis = {
  criteria_type: string | null;
  diagnosis_codes?: Array<string>;
  index_event?: IndexEventData | null;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaProcedure = {
  criteria_type: string | null;
  procedure_codes?: Array<string>;
  index_event?: IndexEventData | null;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaDrug = {
  criteria_type: string | null;
  drug_codes?: Array<string>;
  index_event?: IndexEventData | null;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaLab = {
  criteria_type: string | null;
  lab_codes?: Array<string>;
  index_event?: IndexEventData | null;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaCohort = {
  criteria_type: string | null;
  cohort_locations?: string;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaLabText = {
  criteria_type: string | null;
  lab_texts?: Array<string>;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaClinicalObservation = {
  criteria_type: string | null;
  clinical_observations?: Array<string>;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaChargeDescription = {
  criteria_type: string | null;
  charge_descriptions?: Array<string>;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaEnrollment = {
  criteria_type: string | null;
  enrollment_suppliers?: Array<string>;
  duration?: string;
  beforeDuration?: string;
  afterDuration?: string;
  value_sets?: Array<ValueSet> | null;
};

export type QueryCriteriaMortality = {
  criteria_type: string | null;
  value_sets?: Array<ValueSet> | null; // should never be used
};

export type Dataset = {
  id: number;
  name: string;
  description: string;
  datatype: string;
  data_provider: number;
  ingest_date: string;
  is_public: boolean;
  status: string;
};

export type QuerySummary = {
  request_id: string;
  name: string;
  description: string;
  opportunity_id: string;
  created_by: string;
  created_time: string;
  start_time: string;
  completion_time: string;
  status: string;
  results_json: string;
  result: number;
  query: string;
};

export type ValueSet = {
  value_set_id: string;
  name: string;
  values: string[];
  code_type: string;
  description: string;
  creation_date?: Date;
};

export type ValueSetData = {
  valueSets: ValueSet[];
  valueSetBackend: any;
  setValueSetData: (valueSets: ValueSet[]) => Promise<void>;
};

export type ApiData = {
  datasets: Dataset[];
  querySummaries: QuerySummary[];
  isRefreshingApiData: boolean;
  areQueriesLoading: boolean;
};

export type QueryActions = {
  rerunQuery: (requestId: string) => void;
  duplicateQuery: (requestId: string) => void;
  cancelQuery: (requestId: string) => void;
};

export type IndexEventData = {
  index_event_occurrence?: 'earliest' | 'latest';
  index_enrollment?: Array<QueryCriteriaEnrollment> | null;
};

export type ReportListResponse = {
  continuation_token: string;
  reports: Array<ReportModel>;
};

export type QueryListResponse = {
  continuation_token?: string;
  queries: Array<QuerySummary>;
};

export type ReportData = {
  reports: Array<ReportModel>;
  reportingBackend: any;
  isRefreshingReportData: boolean;
};

export type ReportModel = {
  id: string;
  name: string;
  location: string;
  start_time: string;
  completion_time: string;
  opportunity_id: string;
  status: string;
  created_by: string;
  parameters: ReportParameters;
  report_tables: Array<ReportTableListModel>;
};

export type ReportParameters = {
  supplier_ids?: Array<string>;
  start_date?: string | undefined;
  end_date?: string | undefined;
};

export type ReportTableListModel = {
  id: string;
  result: object;
  report_table_type: string;
  report_urn: string;
};

export type ReportPayload = {
  name: string | undefined;
  location: string | undefined;
  opportunity_id: string | undefined;
  created_by: string | undefined;
  parameters: ReportParameters | undefined;
  query_name?: string | undefined;
};

export type FieldDisplaySettings = {
  value?: string | undefined;
  display_type: string;
};

export type ReportPayloadDisplaySettings = {
  name: FieldDisplaySettings;
  location: FieldDisplaySettings;
  opportunity_id: FieldDisplaySettings;
  created_by: FieldDisplaySettings;
  query_name?: FieldDisplaySettings;
};

export type GenericSuccessResponse = {
  success: string;
};

export type SupplierListResponse = {
  continuation_token: string;
  suppliers: Array<SupplierModel>;
};

export type SupplierData = {
  suppliers: Array<SupplierOption>;
  supplierBackend: any;
  isRefreshingSupplierData: boolean;
};

export type SupplierModel = {
  id: string;
  name: string;
  data_type: string;
  enrollment_data_feeds: string;
};

export type SupplierOption = {
  value: Array<string>;
  label: string;
};

export type FeedGroupResult = {
  distinct_patient_count: number;
  qualifying_dataset_results: { [key: string]: number };
  existence_in_dataset_results: { [key: string]: number };
};

export type FeedResultRow = {
  key: string;
  name: string | undefined;
  data_type: string | undefined;
  existence_in_dataset_results: number | undefined;
  qualifying_dataset_results: number | undefined;
};

/*
 * Reference Data Types
 */

// Reference data context type
export type ReferenceData = {
  referenceDataBackend: any;
};

// Holder for standardized reference data rows
// Note: level1 is the broadest level while level4 is the most narrow
export type StandardizedReferenceDataRow = {
  code: string;
  name: string;
  level1?: string;
  level2?: string;
  level3?: string;
  level4?: string;
};

export type ReferenceCode = {
  code: string;
  name: string;
};

// Recursive type that describes the output of hierarchical/grouped data
export type HierarchicalReferenceDataLevel = {
  // the display name of the level, ie "J00-J99"
  level_name: string;
  // array of only codes, ie ["code1", "code2"]
  // used for convenience when adding or removing a whole hierarchy level
  level_codes_only?: string[];
  // the actual row objects with all containing data
  level_codes?: ReferenceCode[];
  child_levels?: HierarchicalReferenceDataLevel[];
};
