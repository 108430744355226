import {
  QueryCriteriaDiagnosis,
  QueryCriteriaDrug,
  QueryCriteriaLab,
  QueryCriteriaProcedure,
} from '../../../types';

export const formatCriteriaType = (criteriaTypeStr: string | null) => {
  /*
   * Format a criteria-type string to be title case
   * after replacing underscores with spaces.
   * Example: "date_range" --> "Date Range"
   */
  if (criteriaTypeStr === null) {
    return ' ';
  }
  criteriaTypeStr = criteriaTypeStr.replace('_', ' ');
  return criteriaTypeStr.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const criteriaContainsIndexEvent = (
  criteria: QueryCriteriaDiagnosis | QueryCriteriaProcedure | QueryCriteriaLab | QueryCriteriaDrug
) => {
  /* Determine if a given criteria contains data related to Index Events */
  return criteria.index_event !== undefined && criteria.index_event !== null;
};
