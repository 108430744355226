import { FormEvent, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { Header, Input } from 'semantic-ui-react';
import {
  QueryCriteriaEnrollment,
  QueryCriteriaDrug,
  QueryCriteriaDiagnosis,
  QueryCriteriaProcedure,
  QueryCriteriaLab,
} from '../../../types';
import { formatCriteriaType } from './utils';
import { SeparatorTuple } from './SeparatorTuple';
import GenericModal from '../../Modal/shared/GenericModal';
import './EnrollmentCriteria.scss';
import plus from '../../../images/plus.svg';
import { codeTypeToTextMapping } from '../../../constants';

const ENROLLMENT_SUPPLIER_OPTIONS = {
  medical_enrollment: [
    { value: '179', label: 'Private Source 20' },
    { value: '327', label: 'Blue Health Intelligence' },
    { value: '453', label: 'Source 9' },
  ],
  pharmacy_enrollment: [
    { value: '61', label: 'Private Source 17' },
    { value: '179', label: 'Private Source 20' },
    { value: '327', label: 'Blue Health Intelligence' },
    { value: '453', label: 'Source 9' },
  ],
};

export const EnrollmentCriteriaConfigure = ({
  criteria_type,
  onSubmit,
}: {
  criteria_type: string;
  onSubmit: (_: Array<string>, __: string) => void;
}) => {
  const [enrollmentSuppliers, setEnrollmentSuppliers] = useState<MultiValue<string> | undefined>(
    undefined
  );
  const [duration, setDuration] = useState<string | undefined>(undefined);
  const [enrollmentEntryModalIsOpen, setEnrollmentEntryModalIsOpen] = useState<boolean>(false);

  const clickHandler = () => {
    if (enrollmentSuppliers && duration) {
      const suppliers = enrollmentSuppliers.map((supplier) => supplier);
      onSubmit(suppliers, duration);
    }
  };

  const disableConfirm = (): boolean => {
    if (
      duration === undefined ||
      enrollmentSuppliers === undefined ||
      duration.length === 0 ||
      enrollmentSuppliers.length === 0
    ) {
      // Disable if variables undefined or empty
      return true;
    }
    return false;
  };

  if (!(criteria_type && criteria_type in ENROLLMENT_SUPPLIER_OPTIONS))
    throw new Error(`Encountered illegal criteria type: ${criteria_type}`);

  const selectOptions =
    criteria_type === 'medical_enrollment'
      ? ENROLLMENT_SUPPLIER_OPTIONS.medical_enrollment
      : ENROLLMENT_SUPPLIER_OPTIONS.pharmacy_enrollment;
  const criteriaNameText = codeTypeToTextMapping.get(criteria_type)?.singular;

  return (
    <div className={'query-blder-criteria-configure'} data-testid="enrollment-configure">
      <div className="criteria-arrow-body"></div>
      <div className="criteria-arrow-head"></div>
      <button
        data-testid="open-configure-modal"
        className={'query-blder-criteria-configure-expand-window'}
        onClick={() => {
          setEnrollmentEntryModalIsOpen(true);
        }}
      >
        Add Enrollment Criteria
      </button>
      <GenericModal
        headerText={'Enter ' + criteriaNameText + ' Criteria'}
        show={enrollmentEntryModalIsOpen}
        close={() => {
          setEnrollmentEntryModalIsOpen(false);
        }}
        onSubmit={(e) => {
          e.preventDefault();
          clickHandler();
          setEnrollmentEntryModalIsOpen(false);
        }}
        confirmButtonText={'Add'}
        dismissButtonText={'Cancel'}
        disableConfirm={disableConfirm()}
      >
        <Select
          isClearable
          isMulti
          className="enrollment-dropdown"
          classNamePrefix="enrollment-dropdown"
          options={selectOptions}
          onChange={(suppliers) => {
            setEnrollmentSuppliers(suppliers.map((option) => option.value));
          }}
        />
        <Input
          name={'enrollment-duration'}
          placeholder={'# of Days'}
          value={duration}
          className="duration-input"
          onChange={(e) => {
            e.preventDefault();
            setDuration(e.target.value);
          }}
        />
      </GenericModal>
    </div>
  );
};

export const IndexEnrollmentOptionConfigure = ({
  criteria,
  criteria_type,
  onSubmit,
}: {
  criteria: QueryCriteriaDrug | QueryCriteriaDiagnosis | QueryCriteriaProcedure | QueryCriteriaLab;
  criteria_type: string;
  onSubmit: (_: QueryCriteriaEnrollment | undefined) => void;
}) => {
  if (!(criteria_type && criteria_type in ENROLLMENT_SUPPLIER_OPTIONS))
    throw new Error(`Encountered illegal criteria type: ${criteria_type}`);

  // Find the array of objects in ENROLLMENT_SUPPLIER_OPTIONS corresponding to the initially selected suppliers
  const allOptions =
    criteria_type === 'medical_enrollment'
      ? ENROLLMENT_SUPPLIER_OPTIONS.medical_enrollment
      : ENROLLMENT_SUPPLIER_OPTIONS.pharmacy_enrollment;

  // Set state with criteria if defined
  const initialState = criteria?.index_event?.index_enrollment?.find(
    (x) => x.criteria_type === criteria_type
  );

  // Set up default suppliers, filtering those from allOptions that have been passed in from criteria
  // Use the values, e.g. Private Source 20, in the display
  const defaultSupplierIds = initialState?.enrollment_suppliers;
  const defaultSuppliers = defaultSupplierIds
    ? allOptions.filter((x) => defaultSupplierIds.includes(x.value))
    : undefined;
  const [enrollmentSuppliers, setEnrollmentSuppliers] = useState<MultiValue<string> | undefined>(
    defaultSuppliers?.map((x) => x.value)
  );

  // Set up before and after duration
  const [beforeDuration, setBeforeDuration] = useState<string | undefined>(
    initialState?.beforeDuration
  );
  const [afterDuration, setAfterDuration] = useState<string | undefined>(
    initialState?.afterDuration
  );

  // isRegistered is whether the option is read-only, i.e. the green 'plus' is clicked
  // isReadOnly is whether the whole form is read-only
  const [isRegistered, setIsRegistered] = useState<boolean>(initialState ? true : false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  const clickHandler = (e: FormEvent) => {
    e.preventDefault();
    if (enrollmentSuppliers && (beforeDuration || afterDuration)) {
      setIsRegistered(true);
      setIsReadOnly(true);
      const enrollment_suppliers = enrollmentSuppliers.map((supplier) => supplier);
      onSubmit({ criteria_type, enrollment_suppliers, beforeDuration, afterDuration });
    }
  };

  const componentClassName = isRegistered
    ? 'enrollment-criteria-registered'
    : 'query-blder-criteria-configure';

  const beforePlaceholder = isReadOnly ? beforeDuration || 'Days before' : 'Days before';
  const afterPlaceholder = isReadOnly ? afterDuration || 'Days after' : 'Days after';
  return (
    <div className={componentClassName} data-testid="enrollment-configure">
      <Select
        isClearable
        isMulti
        isDisabled={isReadOnly}
        className="enrollment-dropdown"
        options={allOptions}
        onChange={(suppliers) => setEnrollmentSuppliers(suppliers.map((option) => option.value))}
        defaultValue={defaultSuppliers}
      />
      <Input
        name={'enrollment-before-duration'}
        placeholder={beforePlaceholder}
        value={beforeDuration}
        className="duration-input"
        onChange={(e: any) => {
          e.preventDefault();
          setBeforeDuration(e.target.value);
        }}
        disabled={isReadOnly}
      />
      <Input
        name={'enrollment-after-duration'}
        placeholder={afterPlaceholder}
        value={afterDuration}
        className="duration-input"
        onChange={(e: any) => {
          e.preventDefault();
          setAfterDuration(e.target.value);
        }}
        disabled={isReadOnly}
      />
      {!isReadOnly && (
        <div className="enrollment-btn" onClick={clickHandler}>
          <div className="enrollment-icon">
            <img height="8" width="8" src={plus} alt="plus" />
          </div>
        </div>
      )}
    </div>
  );
};

const formatSuppliers = (criteria: QueryCriteriaEnrollment) => {
  if (!criteria.criteria_type || !(criteria.criteria_type in ENROLLMENT_SUPPLIER_OPTIONS))
    throw new Error(`Encountered illegal benefit type: ${criteria.criteria_type}`);
  const selectOptions =
    criteria.criteria_type === 'medical_enrollment'
      ? ENROLLMENT_SUPPLIER_OPTIONS.medical_enrollment
      : ENROLLMENT_SUPPLIER_OPTIONS.pharmacy_enrollment;

  // enrollment_suppliers is an array of feed ids specific to benefit type, e.g.
  // medical_enrollment, so here get the contract
  // name the is displayed in the UI, e.g. 'Private Source 17'
  if (!criteria.enrollment_suppliers) return [<p key="placeholder-element"></p>];
  const suppliers = criteria.enrollment_suppliers.map(
    (supplier) => selectOptions.find((x) => x.value === supplier)?.label
  );
  return suppliers.map((supplier, index) => (
    <SeparatorTuple
      key={'value-' + index}
      index={index}
      value={supplier || ''}
      sep={'OR'}
      sepStyle={'enrollment-separator-string'}
    />
  ));
};

export const EnrollmentCriteriaDisplay = ({ criteria }: { criteria: QueryCriteriaEnrollment }) => {
  return (
    <div>
      <div className={'enrollment-criteria-display-values'}>
        <span className={'enrollment-criteria-name'}>
          {formatCriteriaType(criteria.criteria_type)}:
        </span>
        <span>
          <b># of Days:</b> {criteria.duration}
        </span>
      </div>
      <div className={'enrollment-criteria-display-values light-background'}>
        <div className={'enrollment-criteria-name'}>Continuous Enrollment</div>
        <div className={'enrollment-criteria-item'}>{formatSuppliers(criteria)}</div>
      </div>
    </div>
  );
};

export const IndexEnrollmentOptionDisplay = ({
  criteria,
}: {
  criteria: QueryCriteriaDrug | QueryCriteriaDiagnosis | QueryCriteriaProcedure | QueryCriteriaLab;
}) => {
  return (
    <div>
      {criteria.index_event?.index_enrollment?.map((x) => (
        <div className="enrollment-container">
          <div className="enrollment-criteria-line">
            <div>
              {x.criteria_type === 'medical_enrollment'
                ? 'Continuous medical enrollment'
                : 'Continuous pharmacy enrollment'}
            </div>
            <div className="line-element">Range:</div>
            <div className={'display'}>{x.beforeDuration || '0'} days Before</div>
            <div className={'display'}>{x.afterDuration || '0'} days After</div>
          </div>
          <div className={'enrollment-flex-row'}>{formatSuppliers(x)}</div>
        </div>
      ))}
    </div>
  );
};
