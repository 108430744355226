/*
 * Components for handling the configuration of Index Events.
 * The rules of Index Event configuration are as follows:
 *   - Index Events can only be configured in the Inclusion Block of a Query
 *   - All Index Events in a Query must be of the same "occurrence_type" ("earliest" or "latest")
 *   - Index Events cannot be configured or updated when the Query is in Read-Only mode
 */
import { useContext } from 'react';
import { useRecoilState } from 'recoil';
import { Popup } from 'semantic-ui-react';

import {
  QueryBlock,
  QueryCriteriaDiagnosis,
  QueryCriteriaDrug,
  QueryCriteriaLab,
  QueryCriteriaProcedure,
  IndexEventData,
  QueryCriteriaEnrollment,
} from '../../../types';
import { ReactComponent as IndexEventSelectedIcon } from '../../../images/index-event-selected.svg';
import { ReactComponent as IndexEventUnselectedIcon } from '../../../images/index-event-unselected.svg';
import { indexEventOccurenceAtom } from '../QueryBuilder';
import { BlockDataContext, inclusionBlockAtom } from '../QueryBlock';
import { GroupDataContext } from '../FeedGroup';
import { ReadOnlyContext } from '../QueryBuilder';
import { criteriaContainsIndexEvent } from './utils';
import { IndexEnrollmentOptionConfigure, IndexEnrollmentOptionDisplay } from './EnrollmentCriteria';

import './IndexEventConfiguration.scss';

export const IndexEventIcon = ({
  criteria,
}: {
  criteria: QueryCriteriaDiagnosis | QueryCriteriaProcedure | QueryCriteriaDrug | QueryCriteriaLab;
}) => {
  const { blockType } = useContext(BlockDataContext);
  const { groupId } = useContext(GroupDataContext);
  const isReadOnlyView = useContext(ReadOnlyContext);

  const [inclusionBlockData, setInclusionBlockData] = useRecoilState(inclusionBlockAtom);
  if (blockType !== 'include') {
    return <></>;
  }

  const setCriteriaIndexEventConfig = (indexEventConfigData: IndexEventData | null) => {
    const inclusionBlockDataCopy: QueryBlock = JSON.parse(JSON.stringify(inclusionBlockData));
    const criteriaIndex: number = inclusionBlockDataCopy.feed_groups[groupId].criteria.findIndex(
      (crit) => crit.criteria_type === criteria.criteria_type
    );
    inclusionBlockDataCopy.feed_groups[groupId].criteria[criteriaIndex] = {
      ...criteria,
      index_event: indexEventConfigData,
    };
    setInclusionBlockData(inclusionBlockDataCopy);
  };

  let iconComponent = <></>;
  if (!isReadOnlyView) {
    iconComponent = (
      <Popup
        offset={[-8, 0]}
        size="mini"
        content="Set as index event"
        trigger={
          <IndexEventUnselectedIcon
            data-testid="index-event-icon"
            className="index-event-unselected"
            onClick={() => {
              if (!isReadOnlyView) {
                setCriteriaIndexEventConfig({});
              }
            }}
          />
        }
      />
    );
  }
  if (criteriaContainsIndexEvent(criteria)) {
    iconComponent = (
      <IndexEventSelectedIcon
        data-testid="index-event-icon"
        className="index-event-selected"
        onClick={() => {
          if (!isReadOnlyView) {
            setCriteriaIndexEventConfig(null);
          }
        }}
      />
    );
  }
  return (
    <div data-testid="index-event-icon-container" className="index-event-icon-container">
      {iconComponent}
    </div>
  );
};

/*
 * Update or create the indexEnrollment array of the critiria that can have index enrollment
 * If there's no indexEnrollmentOption, return null
 *
 * If there is an existing option of the type of 'indexEnrollmentOption' already in indexEnrollments,
 * (e.g. there's already a medical_enrollment), then replace it with the new one
 *
 * If there is not, then either appned the new option to the indexEnrollments array or create it if needed
 */
const updateOrCreateIndexEnrollments = ({
  criteria,
  indexEnrollmentOption,
}: {
  criteria: QueryCriteriaDiagnosis | QueryCriteriaProcedure | QueryCriteriaDrug | QueryCriteriaLab;
  indexEnrollmentOption: QueryCriteriaEnrollment | undefined;
}) => {
  if (!indexEnrollmentOption) return null;

  const indexEnrollments = criteria?.index_event?.index_enrollment;
  // If not present make array and return it
  if (!indexEnrollments) return [indexEnrollmentOption];

  const optionIndex = indexEnrollments.findIndex(
    (e) => e.criteria_type === indexEnrollmentOption.criteria_type
  );

  if (optionIndex === -1) {
    // Add element to the array if one of the same enrollment type isn't found
    return [...indexEnrollments, indexEnrollmentOption];
  }

  // Update if there already is one using slice because indexEnrollments is immutable
  return [
    ...indexEnrollments.slice(0, optionIndex),
    indexEnrollmentOption,
    ...indexEnrollments.slice(optionIndex + 1),
  ];
};

export const IndexEventConfiguration = ({
  criteria,
}: {
  criteria: QueryCriteriaDiagnosis | QueryCriteriaProcedure | QueryCriteriaDrug | QueryCriteriaLab;
}) => {
  const [indexEventOccurence, setIndexEventOccurenceAtom] = useRecoilState(indexEventOccurenceAtom);
  const [inclusionBlockData, setInclusionBlockData] = useRecoilState(inclusionBlockAtom);

  const isReadOnlyView = useContext(ReadOnlyContext);
  const { groupId } = useContext(GroupDataContext);

  const setIndexEnrollmentOptionConfig = (
    indexEnrollmentOption: QueryCriteriaEnrollment | undefined
  ) => {
    if (!indexEnrollmentOption) return;
    const inclusionBlockDataCopy: QueryBlock = JSON.parse(JSON.stringify(inclusionBlockData));
    const criteriaIndex: number = inclusionBlockDataCopy.feed_groups[groupId].criteria.findIndex(
      (crit) => crit.criteria_type === criteria.criteria_type
    );

    const updatedIndexEvent = {
      ...criteria.index_event,
      index_enrollment: updateOrCreateIndexEnrollments({ criteria, indexEnrollmentOption }),
    };

    inclusionBlockDataCopy.feed_groups[groupId].criteria[criteriaIndex] = {
      ...criteria,
      index_event: updatedIndexEvent,
    };
    setInclusionBlockData(inclusionBlockDataCopy);
  };

  return (
    <div data-testid="index-event-configuration" className="index-event-configuration">
      <div id="time-selection">
        <div id="record-timing-selection">
          <div
            className={`${indexEventOccurence === 'earliest' ? 'selected' : ''}`}
            onClick={() => {
              if (!isReadOnlyView) {
                setIndexEventOccurenceAtom('earliest');
              }
            }}
          >
            Earliest record
          </div>
          <div
            className={`${indexEventOccurence === 'latest' ? 'selected' : ''}`}
            onClick={() => {
              if (!isReadOnlyView) {
                setIndexEventOccurenceAtom('latest');
              }
            }}
          >
            Latest record
          </div>
        </div>
      </div>
      {isReadOnlyView ? (
        <IndexEnrollmentOptionDisplay criteria={criteria} />
      ) : (
        <div>
          <div>Medical enrollment:</div>
          <IndexEnrollmentOptionConfigure
            criteria={criteria}
            criteria_type="medical_enrollment"
            onSubmit={setIndexEnrollmentOptionConfig}
          />
          <div>Pharmacy enrollment:</div>
          <IndexEnrollmentOptionConfigure
            criteria={criteria}
            criteria_type="pharmacy_enrollment"
            onSubmit={setIndexEnrollmentOptionConfig}
          />
        </div>
      )}
    </div>
  );
};
