import { HttpRequestProvider, HttpRequest, HttpResponse } from '../rest';

import { httpRequest } from './request';

// make a request with AWS4 credentials //
export const oauthRequest = async (
  accessToken: string,
  request: HttpRequest
): Promise<HttpResponse> => {
  const signedRequest: HttpRequest = {
    ...request,
    headers: {
      ...request.headers,
      ...{ Authorization: `Bearer ${accessToken}` },
    },
  };
  return httpRequest(signedRequest);
};

// factory for AWS4 requests //
export const oauthRequestProvider = (
  accessTokenProvider: () => Promise<string>
): HttpRequestProvider => {
  return async (request) => oauthRequest(await accessTokenProvider(), request);
};
