/**
 * Return an array of the schemas defined by this service.
 */
export const getRestSchemas = (): { [key: string]: string } => ({
  restResource: `${__dirname}/schemas/resource.yaml`,
  restCollection: `${__dirname}/schemas/collection.yaml`,
  jsonPatch: `${__dirname}/schemas/json-patch.yaml`,
  restBulkGetRequest: `${__dirname}/schemas/bulk-get-request.yaml`,
  restCallbackHeader: `${__dirname}/schemas/callback-header.yaml`,
});
