import { useState } from 'react';
import { Header } from 'semantic-ui-react';

import GenericModal from '../../Modal/shared/GenericModal';
import { QueryCriteriaCohort } from '../../../types';
import { formatCriteriaType } from './utils';

export const CohortCriteriaConfigure = ({
  onSubmit,
}: {
  onSubmit: (location_id: string) => void;
}) => {
  const [locationId, setLocationId] = useState<string>('');
  const [valueEntryModalIsOpen, setValueEntryModalIsOpen] = useState<boolean>(false);

  return (
    <div className={'query-blder-criteria-configure'}>
      <div className="criteria-arrow-body"></div>
      <div className="criteria-arrow-head"></div>
      <button
        data-testid="open-configure-modal"
        className={'query-blder-criteria-configure-expand-window'}
        onClick={() => {
          setValueEntryModalIsOpen(true);
        }}
      >
        Add Cohort Location
      </button>
      <GenericModal
        headerText="Enter Cohort Location"
        show={valueEntryModalIsOpen}
        close={() => {
          setValueEntryModalIsOpen(false);
        }}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(locationId);
          setValueEntryModalIsOpen(false);
        }}
        confirmButtonText={'Add'}
        dismissButtonText={'Cancel'}
        disableConfirm={locationId.length === 0}
        customModalClassName={'cohort-location-entry-modal'}
      >
        <textarea
          className={'select-values-textarea gray-text-input'}
          data-testid="criteria-configure-enter-values"
          placeholder={'Add cohort location'}
          cols={55}
          rows={1}
          value={locationId}
          onChange={(event: { target: { value: any } }) => setLocationId(event.target.value)}
        ></textarea>
      </GenericModal>
    </div>
  );
};

export const CohortCriteriaDisplay = ({ criteria }: { criteria: QueryCriteriaCohort }) => {
  return (
    <>
      <span className={'query-blder-criteria-name'}>
        {formatCriteriaType(criteria.criteria_type)}:
      </span>
      <div className={'query-blder-criteria-display-values'}>
        <div className={'separator-value'}>{criteria.cohort_locations}</div>
      </div>
    </>
  );
};
