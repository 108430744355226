import './ModalErrorTag.scss';

const ModalErrorTag = ({ text, isIndented }: { text: string; isIndented: boolean }) => {
  let tagClasses = 'modal-error-tag';
  if (isIndented) {
    tagClasses = 'modal-error-tag indented';
  }
  return <div className={tagClasses}>{text}</div>;
};

export default ModalErrorTag;
