import { Dictionary } from '@healthverity/core';

import { HttpRequestProvider, HttpRequest } from './types';

export class RestApiClient {
  private provider: HttpRequestProvider;
  private baseUri?: string;
  public headers: Dictionary<string> = {};

  constructor(provider: HttpRequestProvider, baseUri?: string) {
    this.provider = provider;
    this.baseUri = baseUri;
  }

  protected async request<T>(request: HttpRequest): Promise<T> {
    const { body, error } = await this.provider({
      baseUri: this.baseUri,
      useCredentials: true, // default to secure //
      ...request,
      headers: {
        ...this.headers,
        ...request.headers,
      },
    });
    if (error) {
      throw error;
    }
    return body as unknown as T;
  }
}
