import { RestApiClient } from '@healthverity/rest';
import { ValueSet } from '../types';

export type ListValueSetsResponse = {
  data: ValueSet[];
  success: boolean;
};

/**
 * Class to handle API Requests to the Value Sets Service.
 */
export class ValueSetServiceApiClient extends RestApiClient {
  async listValueSets(): Promise<ListValueSetsResponse> {
    return await this.request<ListValueSetsResponse>({
      path: 'value_sets',
      method: 'GET',
    });
  }

  async createValueSet(value_set: ValueSet): Promise<any> {
    return await this.request<any>({
      path: 'value_sets',
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: value_set,
    });
  }
}


/**
 * Class to mock API Requests to the Value Sets Service.
 */
export class StubValueSetServiceApiClient extends ValueSetServiceApiClient {
  async listValueSets(): Promise<ListValueSetsResponse> {
    return {
      success: true,
      data: [
        {
          value_set_id: "VS-diag1",
          values: ["diag_code_1", "diag_code_2"],
          name: "Diagnosis code value set",
          code_type: "diagnosis_code",
          description: "",
          creation_date: new Date(2022, 1, 1),
        },
        {
          value_set_id: "VS-lab1",
          values: ["lab_code_1", "lab_code_2"],
          name: "Lab code value set",
          code_type: "lab_code",
          description: "",
          creation_date: new Date(2022, 2, 2),
        },
      ],
    };
  }

  async createValueSet(value_set: ValueSet): Promise<any> {
    return {
      success: "true",
    };
  }
}
