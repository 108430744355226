import React from 'react';

import { FormEvent } from 'react';
import './GenericModal.scss';

type CenterModalProps = {
  show: boolean;
  close: () => void;
  fixedHeight?: string;
  fixedWidth?: string;
  children: any;
};

/**
 * Generic component that displays
 * a rectangular modal in the center
 * of the screen.
 *
 * @param show - Boolean value to show/hide the modal
 * @param close - Handle the dismiss of modal
 * @param fixedHeight - Optional fixed height for this modal
 * @param fixedWidth - Optional fixed width for this modal
 * @param children - Body child components for the modal
 */
export function CenterModal({
  show,
  close,
  fixedHeight = '',
  fixedWidth = '',
  children,
}: CenterModalProps) {
  let centerModalClasses = 'center-modal';
  if (fixedHeight) {
    centerModalClasses = 'center-modal center-modal_fixed-height';
  }

  return (
    <React.Fragment>
      {show && (
        <React.Fragment>
          <div className="center-modal-background" onClick={close} />
          <div className={centerModalClasses} style={{ height: fixedHeight, width: fixedWidth }}>
            {children}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

type GenericModalProps = {
  show: boolean;
  close: () => void;
  onSubmit?: (e: FormEvent) => void;
  headerText: string;
  children: any;
  modalBodyClassName?: string;
  dismissButtonText: string;
  confirmButtonText?: string;
  disableConfirm?: boolean;
  customModalClassName?: string;
  customHeaderClassName?: string;
  customFooterClassName?: string;
  customDismissButtonClassName?: string;
  customConfirmButtonClassName?: string;
  hideSubmitButton?: boolean;
  extraButton?: any;
  extraButtonOnClick?: any;
  customExtraButtonClassName?: string;
  fixedHeight?: string;
  fixedWidth?: string;
};

/**
 * Generic component for creating Modals. It has a generic
 * header, a footer with a cancel and a submit button. The
 * CSS classes can be overridden by passing in specific classes
 * for a given attribute.
 *
 * @param show - Boolean value to show/hide the modal
 * @param close - Handle the dismiss of modal
 * @param onSubmit - Handle the click of confirm button
 * @param headerText - Text to display in the header
 * @param children - Body component for the modal
 * @param modalBodyClassName - CSS classname for the body
 * @param dismissButtonText - Text for "cancel" dismiss button
 * @param confirmButtonText - Text for "submit" confirm button
 * @param disableConfirm - Disable confirm button
 * @param customModalClassName - Custom CSS class for the Modal
 * @param customHeaderClassName - Custom CSS class for the header
 * @param customFooterClassName - Custom CSS class for the footer
 * @param customDismissButtonClassName - Custom CSS class for the cancel button
 * @param customConfirmButtonClassName - Custom CSS class for the submit button
 * @param hideSubmitButton - Optional hide of submit button
 * @param extraButton - Optional button that shows up in bottom left corner
 * @param extraButtonOnClick - OnClick for extraButton
 * @param customExtraButtonClassName - Custom CSS for extraButton
 * @param fixedHeight - Optional fixed height for this generic modal
 * @param fixedHeight - Optional fixed width for this generic modal
 */
export default function GenericModal({
  show,
  close,
  onSubmit = (e) => {
    e.preventDefault();
  },
  headerText,
  children,
  modalBodyClassName = '',
  dismissButtonText,
  confirmButtonText,
  disableConfirm = false,
  customModalClassName = '',
  customHeaderClassName = '',
  customFooterClassName = '',
  customDismissButtonClassName = '',
  customConfirmButtonClassName = '',
  hideSubmitButton = false,
  extraButton = null,
  extraButtonOnClick = null,
  customExtraButtonClassName = '',
  fixedHeight = '',
  fixedWidth = '',
}: GenericModalProps) {
  return (
    <CenterModal show={show} close={close} fixedHeight={fixedHeight} fixedWidth={fixedWidth}>
      <form className={'generic-modal ' + customModalClassName} onSubmit={onSubmit}>
        <div className={'generic-modal-header ' + customHeaderClassName}>
          <h1>{headerText}</h1>
        </div>
        <div className={'generic-modal-body ' + modalBodyClassName}>{children}</div>
        <div className={'generic-modal-footer ' + customFooterClassName}>
          {extraButton && (
            <input
              type="button"
              value={extraButton}
              onClick={extraButtonOnClick}
              className={
                'generic-modal-dismiss-btn generic-modal-extra-button-bottom-left ' +
                customExtraButtonClassName
              }
            />
          )}
          <input
            type="button"
            value={dismissButtonText}
            onClick={close}
            className={'generic-modal-dismiss-btn ' + customDismissButtonClassName}
          />
          {!hideSubmitButton && (
            <input
              data-testid="generic-modal-submission-form"
              type="submit"
              value={confirmButtonText}
              disabled={disableConfirm}
              className={'generic-modal-confirm-btn ' + customConfirmButtonClassName}
            />
          )}
        </div>
      </form>
    </CenterModal>
  );
}
