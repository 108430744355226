import { randomFillSync } from 'crypto';

const BUFFER_CACHE: { [key: number]: any } = {}; // eslint-disable-line
const ID_CHARS = 'abcdefghjkmnpqrstuvwxyz123456789';

const random = (bytes: number) => {
  let buffer = BUFFER_CACHE[bytes];
  if (!buffer) {
    buffer = Buffer.allocUnsafe(bytes);
    if (bytes <= 255) {
      BUFFER_CACHE[bytes] = buffer;
    }
  }
  return randomFillSync(buffer);
};

// generate a human-readable unique ID //
export const generateId = (size = 21) => {
  const bytes = random(size);
  let id = '';
  while (0 < size--) {
    id += ID_CHARS[bytes[size] & 31]; // eslint-disable-line
  }
  return id;
};

export const generateIdWithBytes = (bytes: Buffer, size = 21) => {
  let id = '';
  while (0 < size--) {
    id += ID_CHARS[bytes[size] & 31]; // eslint-disable-line
  }
  return id;
};
