interface PropertyErrors {
  [propertyName: string]: object | boolean | null | undefined;
}

/**
 * Custom application error class.
 */
export class ApplicationError extends Error {
  public statusCode?: number;

  public constructor(message: string, properties?: object) {
    super(message);
    this.name = this.constructor.name;
    // redefine message to be included in default serialization //
    Object.defineProperty(this, 'message', { value: message, enumerable: true });
    Error.captureStackTrace(this, this.constructor);

    // copy other properties //
    if (properties) {
      Object.assign(this, properties);
    }
  }

  public static fromPropertyErrors(
    message: string,
    propertyErrors: PropertyErrors,
    properties?: object
  ) {
    const errors = Object.entries(propertyErrors)
      .filter(([, item]) => item && typeof item === 'object')
      .map(([path, item]) => ({ path, ...(item as object) }));

    if (errors.length > 0) {
      throw new ApplicationError(message, { details: errors, ...properties });
    }
  }
}
