import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import { QueryCriteriaDateRange, DateRangeType, QueryCriteria } from '../../../types';
import { formatCriteriaType } from './utils';
import { useRef } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { criteriaTypes } from '../../../constants';

/*
 * Component for setting date range type
 */
const DateRangeTypeToggle = ({
  dateRangeType,
  setDateRangeType,
}: {
  dateRangeType: DateRangeType;
  setDateRangeType: React.Dispatch<React.SetStateAction<DateRangeType>>;
}) => {
  const serviceClass =
    dateRangeType === DateRangeType.service
      ? 'button-left selected-date-button'
      : 'button-left unselected-date-button';
  const encounterClass =
    dateRangeType === DateRangeType.encounter
      ? 'button-right selected-date-button'
      : 'button-right unselected-date-button';
  return (
    <div className={'query-blder-criteria-group'}>
      <div className={serviceClass} onClick={() => setDateRangeType(DateRangeType.service)}>
        Date range
      </div>
      <div className={encounterClass} onClick={() => setDateRangeType(DateRangeType.encounter)}>
        Encounter range
      </div>
      <div className="date-criteria-arrow-body"></div>
      <div className="criteria-arrow-head"></div>
    </div>
  );
};

/*
 * Component for setting date range type
 */
const DateRangeBadge = () => {
  return (
    <div className={'query-blder-criteria-group'}>
      <div className={'button-left button-right selected-date-button'}>Date range</div>
      <div className="date-criteria-arrow-body"></div>
      <div className="criteria-arrow-head"></div>
    </div>
  );
};
/*
 * Component for setting start date, end date, date range type, (e.g. service, encounter)
 */
const DateParametersConfigure = ({
  dateRangeToggleDisplay,
  onSubmit,
}: {
  dateRangeToggleDisplay: boolean | null;
  onSubmit: (startDate: Date, endDate: Date, dateRangeType: DateRangeType) => void;
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateRangeType, setDateRangeType] = useState<DateRangeType>(DateRangeType.service);
  const [calendarClosed, setCalendarClosed] = useState<boolean>(true);

  const startRef = useRef<null | DatePicker>(null);
  const onStartKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e && startRef.current && e.key === 'Tab') {
      startRef.current.setOpen(false);
    }
  };

  const endRef = useRef<null | DatePicker>(null);
  const onEndKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e && endRef.current && e.key === 'Tab') {
      endRef.current.setOpen(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate && calendarClosed) {
      onSubmit(startDate, endDate, dateRangeType);
    }
  }, [startDate, endDate, dateRangeType, calendarClosed]);

  return (
    <div className={'query-blder-criteria-configure service-date-configure'}>
      {dateRangeToggleDisplay ? (
        <DateRangeTypeToggle dateRangeType={dateRangeType} setDateRangeType={setDateRangeType} />
      ) : (
        <DateRangeBadge />
      )}

      <DatePicker
        ref={startRef}
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        onCalendarClose={() => setCalendarClosed(true)}
        onCalendarOpen={() => setCalendarClosed(false)}
        enableTabLoop={false}
        onKeyDown={onStartKeyDown}
        className="start-date"
      />
      <DatePicker
        ref={endRef}
        selected={endDate}
        onChange={(date: Date) => setEndDate(date)}
        onCalendarClose={() => setCalendarClosed(true)}
        onCalendarOpen={() => setCalendarClosed(false)}
        enableTabLoop={false}
        onKeyDown={onEndKeyDown}
        className="end-date"
      />
    </div>
  );
};

type DateCriteriaProps = {
  criteriaOptions: { value: string; label: string }[];
  addCriteria: (value: QueryCriteria) => void;
  setIsCriteriaHovered: (value: boolean) => void;
  setIsCriteriaConfiguring: (value: boolean) => void;
  dateRangeToggleDisplay: boolean | null;
};

/*
 * Component wrapping DateParameterConfigure, handling hover, adding criterias, etc
 */
export function DateCriteriaConfigure({
  addCriteria,
  setIsCriteriaHovered,
  setIsCriteriaConfiguring,
  dateRangeToggleDisplay,
}: DateCriteriaProps) {
  const [isHovered, setIsHovered] = useState(false);
  const onHover = (value: boolean) => {
    setIsHovered(value);
    setIsCriteriaHovered(value);
  };

  const configureCriteria = (type: string, values: object) => {
    addCriteria({ criteria_type: type, ...values });
    setIsCriteriaConfiguring(false);
  };

  return (
    <>
      <div
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
        className={
          dateRangeToggleDisplay === null
            ? 'query-blder-criteria-group-container disabled'
            : 'query-blder-criteria-group-container'
        }
        style={{ borderColor: isHovered ? '#939393' : '#D6D6D6' }}
      >
        <div className={'query-blder-criteria-group'}>
          {' '}
          <DateParametersConfigure
            dateRangeToggleDisplay={dateRangeToggleDisplay}
            onSubmit={(start_date: Date, end_date: Date, date_range_type: DateRangeType) => {
              configureCriteria(criteriaTypes.DATE_RANGE, {
                start_date: start_date.toISOString().substring(0, 10),
                end_date: end_date.toISOString().substring(0, 10),
                date_range_type: date_range_type,
              });
            }}
          />
        </div>
      </div>
      <div className={'logical-operator-line'}>
        <span className={'query-blder-criteria-logical-operator'}>AND</span>
      </div>
    </>
  );
}

/*
 * Component for display of date criteria
 */
export const DateCriteriaDisplay = ({ criteria }: { criteria: QueryCriteriaDateRange }) => {
  return (
    <>
      <span className={'query-blder-criteria-name'}>
        {formatCriteriaType(criteria.criteria_type)}:
      </span>
      <span className={'query-blder-criteria-name'}>
        {formatCriteriaType(criteria.date_range_type)}:
      </span>
      <div className={'query-blder-criteria-display-values'}>
        <div className={'query-blder-criteria-item'}>
          <b>From:</b> {criteria.start_date}
        </div>
        <div className={'query-blder-criteria-item'}>
          <b>To:</b> {criteria.end_date}
        </div>
      </div>
    </>
  );
};
