import { RestApiClient } from '@healthverity/rest';
import { HttpRequest } from '@healthverity/rest';
import {
  QueryExecution,
  QueryDetails,
  QueryBlock,
  QueryExecutionBlock,
  QuerySummary,
  QueryCriteria,
  GenericSuccessResponse,
} from '../types';
import { QueryListResponse } from '../types';

export type getQueriesType = {
  limit?: string;
  continuation_token?: string;
};

const QB_API_VERSION = '1';

/**
 * Assembles query building blocks into schema backend epects
 */
const _translateQuery = (
  queryDetails: QueryDetails,
  userEmail: string,
  indexEventOccurrence: 'earliest' | 'latest',
  inclusionBlock: QueryBlock,
  exclusionBlock: QueryBlock
): QueryExecution => {
  const cleanInclBlock: QueryExecutionBlock = _cleanQueryBlock(
    indexEventOccurrence,
    inclusionBlock
  );

  const cleanExclBlock: QueryExecutionBlock = _cleanQueryBlock(
    indexEventOccurrence,
    exclusionBlock
  );

  return {
    name: queryDetails.name,
    description: queryDetails.description,
    opportunity_id: queryDetails.opportunity_id,
    created_by: userEmail,
    query: {
      version: QB_API_VERSION,
      inclusion_block: cleanInclBlock,
      exclusion_block: cleanExclBlock,
    },
  } as QueryExecution;
};

/**
 * Translates a query block from frontend schema to backend schema
 */
const _cleanQueryBlock = (
  indexEventOccurrence: 'earliest' | 'latest',
  block: QueryBlock
): QueryExecutionBlock => {
  const cleaned_block: any = {
    ...block,
    feed_groups: Object.values(block.feed_groups)
      .filter((fg) => fg.feeds.length)
      .map((fg) => {
        const criteriaList: QueryCriteria[] = [];
        for (const criteria of fg.criteria) {
          if ('index_event' in criteria && criteria.index_event) {
            // index_event.index_enrollment is inserted into state elsewhere, but index_event_occurrence
            // is inserted here, so spread the current object so the existing state is preserved
            criteriaList.push({
              ...criteria,
              index_event: {
                ...criteria.index_event,
                index_event_occurrence: indexEventOccurrence,
              },
            });
          } else {
            const criteriaCopy = { ...criteria };
            if ('index_event' in criteriaCopy) {
              delete criteriaCopy['index_event'];
            }
            criteriaList.push(criteriaCopy);
          }
        }
        return {
          criteria: criteriaList,
          dataset_ids: fg.feeds.map((f) => f.id.toString()),
        };
      }),
  };

  cleaned_block.feed_groups.forEach((fg: any) => {
    delete fg.feeds;
  });
  return cleaned_block as QueryExecutionBlock;
};

export type ListQueryExecutionResponse = {
  data: Array<QuerySummary>;
  success: string;
  continuation_token: string;
};

/** Class to handle API Requests to the Cohort Service. */
export class CohortServiceApiClient extends RestApiClient {
  /**
   * submitQuery makes a request to the `/query_execution` endpoint which
   * submits a query and kicks off the computation
   */
  async submitQuery(
    queryDetails: QueryDetails,
    userEmail: string,
    indexEventOccurrence: 'earliest' | 'latest',
    inclusionBlock: QueryBlock,
    exclusionBlock: QueryBlock
  ): Promise<GenericSuccessResponse> {
    const queryPayload = _translateQuery(
      queryDetails,
      userEmail,
      indexEventOccurrence,
      inclusionBlock,
      exclusionBlock
    );
    const submissionRequest: HttpRequest = {
      path: 'query_execution',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: queryPayload,
    };
    return await this.request<GenericSuccessResponse>(submissionRequest);
  }

  /**
   * rerunQuery makes a request to the `/query_execution/{request_id}/rerun`
   * endpoint which triggers a new query to run which is a rerun
   * of the query given in the URL
   */
  async rerunQuery(requestId: string): Promise<GenericSuccessResponse> {
    const rerunRequest: HttpRequest = {
      path: `query_execution/${requestId}/rerun`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await this.request<GenericSuccessResponse>(rerunRequest);
  }

  /**
   * cancelQuery makes a request to the `/query_execution/{request_id}/cancel`
   * endpoint which causes a query to be canceled
   */
  async cancelQuery(requestId: string): Promise<GenericSuccessResponse> {
    const cancelRequest: HttpRequest = {
      path: `query_execution/${requestId}/cancel`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await this.request<GenericSuccessResponse>(cancelRequest);
  }

  /**
   * getQueries makes a request to the `/query_execution`
   * endpoint which lists all available query definitions
   */
  async getQueries({ limit, continuation_token }: getQueriesType): Promise<QueryListResponse> {
    const listResponse = await this.request<ListQueryExecutionResponse>({
      path: 'query_execution',
      method: 'GET',
      queryString:
        limit || continuation_token
          ? { limit: limit || '', continuation_token: continuation_token || '' }
          : undefined,
    });
    return {
      continuation_token: listResponse.continuation_token,
      queries: listResponse.data,
    };
  }
}

/** Class to mock API Requests to the Cohort Service.   */
export class StubCohortServiceApiClient extends CohortServiceApiClient {
  /**
   * submitQuery makes a request to the `/query_execution` endpoint which
   * submits a query and kicks off the computation
   */
  async submitQuery(
    queryDetails: QueryDetails,
    userEmail: string,
    indexEventOccurrence: 'earliest' | 'latest',
    inclusionBlock: QueryBlock,
    exclusionBlock: QueryBlock
  ): Promise<GenericSuccessResponse> {
    return {
      success: 'true',
    };
  }

  /**
   * rerunQuery makes a request to the `/query_execution/{request_id}/rerun`
   * endpoint which triggers a new query to run which is a rerun
   * of the query given in the URL
   */
  async rerunQuery(requestId: string): Promise<GenericSuccessResponse> {
    return {
      success: 'true',
    };
  }

  /**
   * cancelQuery makes a request to the `/query_execution/{request_id}/cancel`
   * endpoint which causes a query to be canceled
   */
  async cancelQuery(requestId: string): Promise<GenericSuccessResponse> {
    return {
      success: 'true',
    };
  }

  /**
   * getQueries makes a request to the `/query_execution`
   * endpoint which lists all available query definitions
   */
  async getQueries(): Promise<QueryListResponse> {
    return {
      continuation_token: '',
      queries: [],
    };
  }
}
