import { useAuth0 } from '@auth0/auth0-react';

import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { Confirm, Dropdown, Popup } from 'semantic-ui-react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import CreateReportModal from '../Modal/CreateReportModal';
import { ApiDataContext, QueryActionsContext } from '../AppContent';
import {
  QueryActions,
  QuerySummary,
  ReportParameters,
  ReportPayload,
  ReportPayloadDisplaySettings,
} from '../../types';
import { ReactComponent as KebabMenuIcon } from '../../images/kebab-menu.svg';
import { ReactComponent as RefreshIcon } from '../../images/refresh-icon.svg';
import { getDistinctPatientCount } from '../../utils/query_result_handler';
import { formatDate, calculateRunTimeStr } from '../../utils/format_date';
import { getDateRangeFromQuery, getInclusionSupplierIdsFromQuery } from '../../utils/query_parser';
import { useDoesElementHorizontalOverflow } from '../../hooks/useHorizontalOverflow';
import { displayPageLoadingAtom } from '../LoadingOverlay';
import { valueDisplayOptions } from '../../constants';
import getOppID from '../../utils/get_opp_id';

import SearchBar from './SearchBar';

import './Table.scss';

const QUERY_CHUNK_SIZE = 100;

type HeaderDisplayElement = {
  displayElement: JSX.Element;
  pixelWidth: string;
};
const getHeadersObject = (
  refreshQueryListData: () => Promise<void>
): { [key: string]: HeaderDisplayElement } => {
  return {
    query_name: {
      displayElement: <span>Query name</span>,
      pixelWidth: '230px',
    },
    opp_id: {
      displayElement: <span>Opp. ID</span>,
      pixelWidth: '70px',
    },
    created_by: {
      displayElement: <span>Created by</span>,
      pixelWidth: '100px',
    },
    saved_ran: {
      displayElement: <span>Saved/Ran</span>,
      pixelWidth: '170px',
    },
    run_time: {
      displayElement: <span>Run time</span>,
      pixelWidth: '90px',
    },
    status: {
      displayElement: <span>Status</span>,
      pixelWidth: '80px',
    },
    total_count: {
      displayElement: <span>Total count</span>,
      pixelWidth: '80px',
    },
    refresh_button: {
      displayElement: (
        <span
          onClick={() => {
            refreshQueryListData();
          }}
          className={'refresh-button-icon'}
        >
          <RefreshIcon />
        </span>
      ),
      pixelWidth: '40px',
    },
  };
};

export const STATUS_MAP: { [key: string]: string } = {
  success: 'Complete',
  failed: 'Error',
  canceled: 'Canceled',
  running: 'Running',
  pending: 'Pending',
};

/*
 * Renders one table using data passed in as props. Will display a no results
 * component when data prop is an empty list. Only displays valid rows
 */
export default function Table({
  refreshQueryListData,
  createReport,
}: {
  refreshQueryListData: () => Promise<void>;
  createReport: (payload: ReportPayload) => Promise<void>;
}) {
  const navigate = useNavigate();
  const { querySummaries, isRefreshingApiData, areQueriesLoading } = useContext(ApiDataContext);
  const queryActions: QueryActions = useContext(QueryActionsContext);
  const [numQueriesToDisplay, _setNumQueriesToDisplay] = useState(QUERY_CHUNK_SIZE);
  const [searchValue, setSearchValue] = useState('');
  const numQueriesRef = React.useRef(numQueriesToDisplay);

  const setNumQueriesToDisplay = (data: number) => {
    numQueriesRef.current = data;
    _setNumQueriesToDisplay(data);
  };
  const scrollableTableRef = useBottomScrollListener(
    () => {
      setNumQueriesToDisplay(numQueriesRef.current + QUERY_CHUNK_SIZE);
    },
    { offset: 20 }
  );

  const validRows = querySummaries?.filter((query) => {
    let queryMatchesFilter = true;
    if (searchValue.length > 0) {
      queryMatchesFilter =
        query.name.toLowerCase().includes(searchValue) ||
        query.created_by.toLowerCase().includes(searchValue) ||
        query.opportunity_id.toLowerCase().includes(searchValue);
    }
    return queryMatchesFilter;
  });

  let shouldShowTable = true;
  let preTableContent = null;
  if (isRefreshingApiData) {
    shouldShowTable = false;
    preTableContent = <div data-testid="table-loading" className="component-loading" />;
  } else if (validRows.length === 0) {
    shouldShowTable = false;
    preTableContent = <NoResults />;
  }
  const rowsToDisplay = validRows.slice(0, numQueriesToDisplay);
  const headersObject = getHeadersObject(async () => {
    setNumQueriesToDisplay(QUERY_CHUNK_SIZE);
    refreshQueryListData();
  });

  let searchBar = (
    <SearchBar
      onSearchSubmit={(searchValue) => {
        setSearchValue(searchValue.toLowerCase());
      }}
      onSearchClear={() => {
        setSearchValue('');
      }}
      searchPlaceholderText={'Search queries by name, creator or opportunity id...'}
      isLoading={isRefreshingApiData}
      minSearchLength={3}
    ></SearchBar>
  );

  let loadingRow = (
    <tr className="table-row">
      <td colSpan={7}>Loading...</td>
    </tr>
  );

  let tableContent = (
    <table className={`query-table ${!shouldShowTable ? 'hidden' : ''}`}>
      <thead>
        <tr className={'table-headers'}>
          {Object.keys(headersObject).map((header) => (
            <th
              key={header}
              className={'table-header'}
              style={{ width: headersObject[header].pixelWidth }}
            >
              {headersObject[header].displayElement}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowsToDisplay.map((querySummary: QuerySummary, index: number) => {
          const isRowWithinFirstFive = index < 5;
          const isRowWithinLastFive = rowsToDisplay.length - index <= 5;
          const shouldDropdownOpenUpwards = isRowWithinLastFive && !isRowWithinFirstFive;
          return (
            <Row
              key={index}
              index={index}
              querySummary={querySummary}
              dropdownUpward={shouldDropdownOpenUpwards}
              navigate={navigate}
              queryActions={queryActions}
              createReport={createReport}
            />
          );
        })}
        {areQueriesLoading ? loadingRow : null}
      </tbody>
    </table>
  );
  return (
    <div>
      <Container fluid style={{ display: 'flex', margin: '20px 0' }}>
        {searchBar}
      </Container>
      <div
        data-testid="query-list-table"
        ref={scrollableTableRef as React.LegacyRef<HTMLDivElement>}
        className={'table-container'}
      >
        {preTableContent}
        {tableContent}
      </div>
    </div>
  );
}

type RowProperties = {
  index: number;
  querySummary: QuerySummary;
  navigate: NavigateFunction;
  dropdownUpward: boolean;
  queryActions: QueryActions;
  createReport: (payload: ReportPayload) => Promise<void>;
};

/* Renders an individual query execution row in the table */
function Row({
  index,
  querySummary,
  navigate,
  dropdownUpward,
  queryActions,
  createReport,
}: RowProperties) {
  // Use a Horizontal Overflow Hook to conditionally show a Popup
  const detailsLinkRef = useRef<HTMLTableCellElement>(null);
  const doesDetailLinkOverflow = useDoesElementHorizontalOverflow(
    detailsLinkRef,
    querySummary.name
  );

  // Pre-calculate the value of the Created By column of this row
  const createdBySplit = querySummary.created_by.split('@');
  let createdByDisplayValue = createdBySplit[0];
  if (!createdByDisplayValue) {
    createdByDisplayValue = '--';
  }

  // Temporary logic to handle if created_time is empty
  let createdTimeValue = querySummary.created_time || querySummary.start_time;

  return (
    <tr data-testid={`table-row-${index}`} className={'table-row'} style={{ cursor: 'auto' }}>
      <Popup
        content={querySummary.name}
        position={'top center'}
        size={'small'}
        disabled={!doesDetailLinkOverflow}
        hideOnScroll={true}
        trigger={
          <td ref={detailsLinkRef}>
          <Link to = {`/${querySummary.request_id}/details`} className="details-link">
              <b>{querySummary.name}</b>
          </Link>
          </td>
        }
      ></Popup>
      <td>{querySummary.opportunity_id}</td>
      <td>{createdByDisplayValue}</td>
      <td>{formatDate(createdTimeValue)}</td>
      <td>{calculateRunTimeStr(createdTimeValue, querySummary.completion_time)}</td>
      <td>{STATUS_MAP[querySummary.status]}</td>
      <td>{getDistinctPatientCount(querySummary)}</td>
      <td className="action-menu-cell">
        <QueryExecutionActions
          querySummary={querySummary}
          dropdownIcon={<KebabMenuIcon />}
          dropdownUpward={dropdownUpward}
          queryActions={queryActions}
          createReport={createReport}
        />
      </td>
    </tr>
  );
}

type ConfirmationProperties = {
  headerStr: string;
  onConfirm: (request_id: string) => void;
};
type QueryExecutionActionsProps = {
  querySummary: QuerySummary;
  dropdownIcon: any;
  queryActions: QueryActions;
  dropdownUpward: boolean;
  createReport: (payload: ReportPayload) => Promise<void>;
};
/*
 * Component to handle the actions that a User can
 * perform on a single Query Execution Row.
 */
export function QueryExecutionActions({
  querySummary,
  dropdownIcon,
  queryActions,
  dropdownUpward,
  createReport,
}: QueryExecutionActionsProps) {
  const { rerunQuery, duplicateQuery, cancelQuery } = queryActions;
  const [confirmActionType, setConfirmActionType] = useState<string>('');
  const [reportModalIsOpen, setReportModalIsOpen] = useState<boolean>(false);
  const { user } = useAuth0();

  // useMemo to avoid unnecessary recalculation and re-rendering. defaultReportPayload
  // depends only on querySummary
  const defaultReportPayload: ReportPayload = useMemo(() => {
    const tableLocation = `query_builder.${querySummary.request_id}`;
    const [startDate, endDate] = getDateRangeFromQuery(querySummary.query);
    const defaultSupplierIds = querySummary
      ? getInclusionSupplierIdsFromQuery(querySummary.query)
      : undefined;
    const defaultParams: ReportParameters = {
      supplier_ids: defaultSupplierIds,
      start_date: startDate,
      end_date: endDate,
    };

    return {
      name: '',
      location: tableLocation,
      opportunity_id: querySummary.opportunity_id,
      created_by: '',
      parameters: defaultParams,
      query_name: querySummary.name,
    };
  }, [querySummary]);

  let displayValues: Partial<ReportPayloadDisplaySettings> = {
    location: {
      value: `${querySummary.name} (${defaultReportPayload.location})`,
      display_type: valueDisplayOptions.READ_ONLY,
    },
  };
  if (getOppID(user?.tenant_id)) {
    displayValues.opportunity_id = {
      display_type: valueDisplayOptions.HIDDEN,
    };
  }

  const [reportPayload, setReportPayload] = useState<ReportPayload>(defaultReportPayload);

  const setPageLoading = useSetRecoilState(displayPageLoadingAtom);

  const isQueryCancelable = (querySummary: QuerySummary): boolean => {
    return querySummary.status === 'running' || querySummary.status === 'pending';
  };
  const generateConfirmationProperties = (
    querySummary: QuerySummary,
    actionType: string
  ): ConfirmationProperties => {
    switch (actionType) {
      case 'rerun':
        return {
          headerStr: `Action confirmation: re-run the query "${querySummary.name}"`,
          onConfirm: rerunQuery,
        };
      case 'cancel':
        return {
          headerStr: `Action confirmation: cancel the query "${querySummary.name}"`,
          onConfirm: cancelQuery,
        };
      default:
        return {
          headerStr: `ERROR: Action type unsupported`,
          onConfirm: (_) => {},
        };
    }
  };
  const confirmationProps = generateConfirmationProperties(querySummary, confirmActionType);
  const reqId = querySummary.request_id;

  const closeReportModalHandler = () => {
    setReportModalIsOpen(false);
  };

  const submitReportHandler = async () => {
    setPageLoading(true);
    await createReport(reportPayload);
    setPageLoading(false);
    closeReportModalHandler();
  };

  return (
    <>
      <CreateReportModal
        isOpen={reportModalIsOpen}
        onSubmit={submitReportHandler}
        onClose={closeReportModalHandler}
        payload={reportPayload}
        setPayload={setReportPayload}
        payloadDisplay={displayValues}
      />
      <Confirm
        data-testid={`action-confirm-${reqId}`}
        open={confirmActionType !== ''}
        header={confirmationProps['headerStr']}
        content={'Are you sure you want to perform this action?'}
        onCancel={(e) => {
          setConfirmActionType('');
        }}
        onConfirm={(e) => {
          setConfirmActionType('');
          confirmationProps['onConfirm'](reqId);
        }}
      />
      <Dropdown
        data-testid={`action-kebab-menu-${reqId}`}
        className="action-kebab-menu"
        icon={dropdownIcon}
        upward={dropdownUpward}
        direction={'left'}
      >
        <Dropdown.Menu
          className="action-kebab-dropdown"
          data-testid={`action-kebab-dropdown-${reqId}`}
        >
          <Dropdown.Item
            data-testid={`rerun-kebab-dropdown-${reqId}`}
            onClick={() => {
              setConfirmActionType('rerun');
            }}
            icon="undo"
            text="Rerun query"
          />
          <Dropdown.Item
            data-testid={`duplicate-kebab-dropdown-${reqId}`}
            onClick={() => {
              duplicateQuery(reqId);
            }}
            icon="clone"
            text="Duplicate query"
          />
          <Dropdown.Item
            data-testid={`generate-report-kebab-dropdown-${reqId}`}
            onClick={() => {
              setReportModalIsOpen(true);
            }}
            disabled={querySummary.status !== 'success'}
            icon="pencil"
            text="Generate report"
          />
          <Dropdown.Item
            data-testid={`cancel-kebab-dropdown-${reqId}`}
            onClick={() => {
              setConfirmActionType('cancel');
            }}
            disabled={!isQueryCancelable(querySummary)}
            icon="x"
            text="Cancel query"
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

const NoResults = () => {
  return (
    <div data-testid="table-no-results" className="no-results">
      <span>No Queries Found</span>
    </div>
  );
};
