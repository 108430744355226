import { atom } from 'recoil';
import { useRecoilValue } from 'recoil';

export const displayPageLoadingAtom = atom<boolean>({
  key: 'displayPageLoading',
  default: false,
});
const PageLoadingOverlay = ({}) => {
  const displayPageLoading = useRecoilValue(displayPageLoadingAtom);
  if (!displayPageLoading) {
    return <></>;
  }
  return <div className="page-loading"></div>;
};
export default PageLoadingOverlay;
