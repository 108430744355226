import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ReportPayload } from 'src/types';
import Table from '../Table/Table';
import './QueryList.scss';
import plus from '../../images/plus.svg';
import DisplayToggle from '../DisplayToggle/DisplayToggle';
// STAGE use for feature deployment of cohort attribute reporting. Logic for STAGE
// here and below should be removed when reports are deployed to prod
const STAGE = process.env['REACT_APP_STAGE'] || 'local';

export default function QueryList({
  refreshQueryListData,
  createReport,
}: {
  refreshQueryListData: () => Promise<void>;
  createReport: (payload: ReportPayload) => Promise<void>;
}) {
  return (
    <div className="query-list-container">
      <div className="query-list-header">
        <span className="query-list-title">HealthVerity Marketplace query builder</span>
        <DisplayToggle isQuery={true} />
        <div className="query-list-buttons">
          <div className="new-query-btn">
            <div className="new-query-icon">
              <img height="13" width="13" src={plus} />
            </div>

            <Link to="/build" className="new-query-text">
              <span>New query</span>
            </Link>
          </div>
        </div>
      </div>
      <hr className="content-divider" />
      <div className="query-list-content" data-testid="query-list-content">
        <Table refreshQueryListData={refreshQueryListData} createReport={createReport} />
      </div>
    </div>
  );
}
