import { createContext, useContext, useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';

import { Dataset, QueryBlock, QueryFeedGroup } from '../../types';
import Group from './FeedGroup';
import { ReadOnlyContext } from './QueryBuilder';

const generateGroupId = () => Math.random().toString(16).slice(4);
export const createEmptyGroup = () => ({ [generateGroupId()]: { criteria: [], feeds: [] } });

export const inclusionBlockAtom = atom<QueryBlock>({
  key: 'inclusionBlockData',
  default: {
    join_type: 'and',
    feed_groups: createEmptyGroup(),
  },
});
export const exclusionBlockAtom = atom<QueryBlock>({
  key: 'exclusionBlockData',
  default: {
    join_type: 'and',
    feed_groups: createEmptyGroup(),
  },
});

type BlockData = {
  blockType: string;
  joinType: string;
};
export const BlockDataContext = createContext<BlockData>({ blockType: '', joinType: '' });

type BlockProps = {
  blockType: string;
  block: QueryBlock;
  datasets: Dataset[];
};
const Block = ({ blockType, block, datasets }: BlockProps) => {
  const joinTypes = ['and', 'or'];

  const isReadOnlyView = useContext(ReadOnlyContext);

  const setInclusionBlock = useSetRecoilState(inclusionBlockAtom);
  const setExclusionBlock = useSetRecoilState(exclusionBlockAtom);
  const setBlockState = (data: QueryBlock) => {
    if (blockType === 'include') {
      return setInclusionBlock(data);
    }
    return setExclusionBlock(data);
  };

  const changeJoinType = (type: string, isDisabled = false) =>
    isDisabled ? {} : setBlockState({ ...block, join_type: type });

  useEffect(() => {
    let groups = block.feed_groups;

    // Add empty group if no group present
    if (!Object.keys(groups).length) {
      setBlockState({
        ...block,
        feed_groups: createEmptyGroup(),
      });
    }
  }, [block]);

  const addGroup = () =>
    setBlockState({
      ...block,
      feed_groups: {
        ...block.feed_groups,
        ...createEmptyGroup(),
      },
    });

  const removeGroup = (id: string) => {
    let groupsCopy = { ...block.feed_groups };
    delete groupsCopy[id];

    // Add empty group if last group was removed
    if (!Object.keys(groupsCopy).length) {
      groupsCopy = createEmptyGroup();
    }

    setBlockState({
      ...block,
      feed_groups: groupsCopy,
    });
  };

  const setGroup = (id: string, group: QueryFeedGroup) =>
    setBlockState({
      ...block,
      feed_groups: {
        ...block.feed_groups,
        [id]: group,
      },
    });

  const replicateGroup = (id: string) => {
    const groupsCopy = { ...block.feed_groups };
    const newId = generateGroupId();
    setGroup(newId, groupsCopy[id]);
  };

  return (
    <BlockDataContext.Provider value={{ blockType: blockType, joinType: block.join_type }}>
      <div className={`query-bldr-block-${blockType}`}>
        <div className={'query-bldr-block-sidebar'}>
          {Object.keys(block.feed_groups).length > 1 && (
            <div
              onClick={() =>
                changeJoinType(
                  joinTypes[(joinTypes.indexOf(block.join_type) + 1) % 2],
                  isReadOnlyView
                )
              }
              className={`query-bldr-block-join-type-container-${block.join_type}`}
            >
              <div className={'query-bldr-block-join-type'}>{block.join_type.toUpperCase()}</div>
            </div>
          )}
        </div>

        <div className={`query-bldr-block-type-container-${blockType}`}>
          <div className={`query-bldr-block-type`}>{blockType.toUpperCase()}</div>
        </div>

        <div className={`query-blder-feed-groups`}>
          {Object.entries(block.feed_groups).map(([id, group], index) => (
            <Group
              key={id}
              id={id}
              index={index}
              group={group}
              setGroup={setGroup}
              removeGroup={removeGroup}
              replicateGroup={replicateGroup}
              datasets={datasets}
            />
          ))}

          {/* Only render add group button if they all have feeds attached */}
          {!isReadOnlyView &&
            Object.values(block.feed_groups).every((fg: QueryFeedGroup) => fg.feeds.length) && (
              <button onClick={addGroup} className={'query-blder-add-criteria-group'}>
                +
              </button>
            )}
        </div>
      </div>
    </BlockDataContext.Provider>
  );
};

export default Block;
