import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';

import { oauthRequestProvider } from '@healthverity/http-request/oauth';
import { Link } from 'react-router-dom';

import AppContent from './AppContent';
import UnauthorizedPage from './ErrorPage/UnauthorizedPage';
import PasswordExpiredPage from './ErrorPage/PasswordExpiredPage';
import { CohortServiceApiClient } from '../backend/cohort_service';
import { DatasetServiceApiClient } from '../backend/dataset_service';
import { ValueSetServiceApiClient } from '../backend/valueset_service';
import { ReportingServiceApiClient } from '../backend/reporting_service';
import { ReferenceServiceApiClient } from '../backend/reference_service';
import { errorSelector } from 'recoil';

const AUTH0_AUDIENCE = process.env['REACT_APP_AUTH0_AUDIENCE'] || '';
const AUTH0_CLIENT_ID = process.env['REACT_APP_AUTH0_CLIENT_ID'] || '';
const AUTH0_DOMAIN = process.env['REACT_APP_AUTH0_DOMAIN'] || '';
const DSM_BACKEND_BASE_URL = process.env['REACT_APP_DSM_BACKEND_BASE_URL'] || '';
const QB_BACKEND_BASE_URL = process.env['REACT_APP_QB_BACKEND_BASE_URL'] || '';
const REPORTING_BACKEND_BASE_URL = QB_BACKEND_BASE_URL + '/cohort-reporting';
const REFERENCE_SERVICE_BASE_URL = process.env['REACT_APP_REFERENCE_SERVICE_BASE_URL'] || '';

export const DSM_REQUIRED_PERMS = ['dataset_mgmt:read:dataset'];
export const QB_REQUIRED_PERMS = [
  'wishbone:read:query_execution',
  'wishbone:write:query_execution',
];
export const VS_REQUIRED_PERMS = ['wishbone:write:query_execution'];
export const REPORTING_REQUIRED_PERMS = ['wishbone:read:reports', 'wishbone:write:reports'];
export const REFERENCE_SERVICE_REQUIRED_PERMS = ['read:reference'];

/**
 * Outer component to handle setting up Auth0Provider for content within the app.
 */
const EntryPointAuth0 = () => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      scope={QB_REQUIRED_PERMS.concat(DSM_REQUIRED_PERMS)
        .concat(REPORTING_REQUIRED_PERMS)
        .concat(REFERENCE_SERVICE_REQUIRED_PERMS)
        .join(' ')}
    >
      <InnerEntryPointAuth0 />
    </Auth0Provider>
  );
};

/**
 * Inner component to handle using values provided by Auth0Provider to determine
 * the state of authentication and display the App's content.
 */
const InnerEntryPointAuth0 = () => {
  // State related to sign-in and permissions
  const {
    user,
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(undefined);

  // Once the user is authenticated, check their access token
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({ qbScope }).then((token) => {
        const parsed = jwt_decode(token) as { scope: string };
        const perms = parsed.scope.split(' ');
        setIsAuthorized(QB_REQUIRED_PERMS.every((s) => perms.indexOf(s) > -1));
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  // If the user is not logged in, redirect them to the login screen
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect();
    }
  }, [loginWithRedirect, isAuthenticated, isLoading, error]);

  // Generate Auth0 Request-Providers
  const dsmScope = DSM_REQUIRED_PERMS.join(' ');
  const dsmScopedAuth0Provider = oauthRequestProvider(() => getAccessTokenSilently({ dsmScope }));
  const qbScope = QB_REQUIRED_PERMS.join(' ');
  const qbScopedAuth0Provider = oauthRequestProvider(() => getAccessTokenSilently({ qbScope }));
  const vsScope = VS_REQUIRED_PERMS.join(' ');
  const vsScopedAuth0Provider = oauthRequestProvider(() => getAccessTokenSilently({ vsScope }));
  const reportingScope = REPORTING_REQUIRED_PERMS.join(' ');
  const reportingScopedAuth0Provider = oauthRequestProvider(() =>
    getAccessTokenSilently({ reportingScope })
  );
  const referenceServiceScope = REFERENCE_SERVICE_REQUIRED_PERMS.join(' ');
  const referenceScopedAuth0Provider = oauthRequestProvider(() =>
    getAccessTokenSilently({ referenceServiceScope })
  );

  // Create Service Clients for each service
  const datasetManagementBackend: DatasetServiceApiClient = new DatasetServiceApiClient(
    dsmScopedAuth0Provider,
    DSM_BACKEND_BASE_URL
  );
  const queryBuilderBackend: CohortServiceApiClient = new CohortServiceApiClient(
    qbScopedAuth0Provider,
    QB_BACKEND_BASE_URL
  );
  const valueSetBackend: ValueSetServiceApiClient = new ValueSetServiceApiClient(
    vsScopedAuth0Provider,
    QB_BACKEND_BASE_URL
  );
  const reportingBackend: ReportingServiceApiClient = new ReportingServiceApiClient(
    reportingScopedAuth0Provider,
    REPORTING_BACKEND_BASE_URL
  );
  const referenceServiceBackend: ReferenceServiceApiClient = new ReferenceServiceApiClient(
    referenceScopedAuth0Provider,
    REFERENCE_SERVICE_BASE_URL
  );

  // Generic Logout function
  const handleLogout = () => logout({ returnTo: window.location.origin });

  if (isAuthorized !== true && isAuthorized !== false && isAuthorized !== undefined) {
    return (
      <div className="auth-message">
        <p>There has been an authorization issue.</p>
      </div>
    );
  }

  // If there is an expired password and user email is known, reset password
  if (error && error?.message === 'Your password has expired.') {
    return <PasswordExpiredPage />;
  }

  // If there's no explicit error and authorization is unknown,
  // then auth is still loading.
  if (isAuthorized === undefined) {

    return <div title="loading-spinner" className="page-loading"></div>;
  }

  // If there is an authentication error, show that error message
  if (error) {
    return (
      <div className="auth-message">
        <span>Oops... {error?.message}</span>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  // If the user is not authorized, show an authorization failure message
  if (isAuthorized === false) {
    return <UnauthorizedPage user={user} handleLogout={handleLogout} />;
  }

  // Authorization and authentication are complete and validated.
  // Display the App's content
  return (
    <AppContent
      handleLogout={handleLogout}
      queryBuilderBackend={queryBuilderBackend}
      datasetManagementBackend={datasetManagementBackend}
      valueSetBackend={valueSetBackend}
      reportingBackend={reportingBackend}
      referenceServiceBackend={referenceServiceBackend}
    />
  );
};

export default EntryPointAuth0;
