import { useState } from 'react';
import { List, Divider, Segment } from 'semantic-ui-react';
import { codeTypeToTextMapping } from '../../../../constants';
import {
  StandardizedReferenceDataRow,
  HierarchicalReferenceDataLevel,
  ReferenceCode,
} from '../../../../types';

export const GenericReferenceHeirarchy = ({
  searchResults,
  selectedCodes,
  handleReferenceCodeToggle,
  codeType,
}: {
  searchResults: HierarchicalReferenceDataLevel[];
  selectedCodes: string;
  handleReferenceCodeToggle: (codesToAddOrRemove: string[], checkboxIsNowChecked: boolean) => void;
  codeType: string;
}) => {
  /**
   * Renders a components that displays hierarchial/nested reference data into the appropriate format.
   * Allows users to check or uncheck codes at any level of data and uses the provided setter to update
   * the values upstream
   *
   * @param searchResults - The results from the Reference Data Service that has been formatted into a nested data structure
   * @param selectedCodes - Comma separated value string of all currently selected codes for this criteria type, ie "code1,code2"
   * @param handleReferenceCodeToggle - Setter which allows a user's selections in this component to be reflected upstream in the form
   * @param codeType - Which type of code (diagnosis, lab, etc) is currently selected
   */

  // For managing which hierarchy levels are toggled to be displayed
  const [visibleLevelsState, setVisibleLevelsState] = useState<string[]>([]);

  // Convert incoming valueCsvString string values to array of strings for easier manipulation
  const selectedCodesAsList = selectedCodes
    .split(',')
    .map((s) => s.trim())
    .filter(Boolean);

  const formatIDElement = (unformattedString: string) : string => unformattedString.toLowerCase().replaceAll(" ", "_")

  const checkIfAllCodesInState = (codesToCheck: string[]) => {
    /** 
      Handles determining if a checkbox (single or multiple) should be in "clicked state"
      This will handle checkboxes for both single codes and for hierarchy/grouped codes
    */
    let areAllCodesInHierarchyChecked = true;
    let selectedCodesSet: Set<string> = new Set([...selectedCodesAsList]);
    codesToCheck.forEach((codeToCheck) => {
      if (!selectedCodesSet.has(codeToCheck)) {
        // At least one isn't checked, set to false
        areAllCodesInHierarchyChecked = false;
      }
    });
    return areAllCodesInHierarchyChecked;
  };

  const toggleLevelVisibility = (level_name: string) => {
    /**
     * Handles toggling hierarchy level visibility
     */
    let visibleLevelsSet: Set<string> = new Set([...visibleLevelsState]);
    if (visibleLevelsSet.has(level_name)) {
      visibleLevelsSet.delete(level_name);
    } else {
      visibleLevelsSet.add(level_name);
    }

    setVisibleLevelsState([...Array.from(visibleLevelsSet)]);
  };

  // const renderCodes = (codes: StandardizedReferenceDataRow[] | ReferenceCode[] | undefined) => {
  const renderCodes = (codes: ReferenceCode[] | undefined) => {
    /**
     * Renders the final level of the tree, which is just an array of codes
     *
     * @param codes - Flat array of ReferenceCode data
     */

    if (codes === undefined || !codes.length) {
      return null;
    }

    const codeLabel = codeTypeToTextMapping.get(codeType)?.codeLabel ?? '';

    // Alphabetical sort - this can probably be moved to the recursive groupByLevels method
    const sortDataByCode = (data: StandardizedReferenceDataRow[]) =>
      data.sort((a: StandardizedReferenceDataRow, b: StandardizedReferenceDataRow) =>
        a.code.localeCompare(b.code)
      );

    // Map through all sorted codes and display the checkbox
    return sortDataByCode([...codes]).map((code) => {
      const codeAsArrayOfCodes = [code['code']];
      const codeIsChecked = checkIfAllCodesInState(codeAsArrayOfCodes);
      const checkboxID = formatIDElement('checkbox_code_' + code['code']);
      const checkboxLabel = (
        <label htmlFor={checkboxID} className="">
          <strong>
            {codeLabel}: {code['code']}
          </strong>{' '}
          {code['name']}
        </label>
      );

      // Renders a list element containing a checkbox and label for a single code
      return (
        <List.Item key={code['code']} style={{ margin: '5px' }}>
          <input
            type="checkbox"
            id={checkboxID}
            onChange={(e) => handleReferenceCodeToggle(codeAsArrayOfCodes, !codeIsChecked)}
            checked={codeIsChecked}
          />{' '}
          {checkboxLabel}
        </List.Item>
      );
    });
  };

  const renderHierarchy = (searchResults: HierarchicalReferenceDataLevel[]) => {
    /**
    Renders all the groups in a single level

    This is a recursive method & will keep calling "renderHierarchy" until we reach the final
    level, where we will then just "renderCodes"

    * @param searchResults - The results from the Reference Data Service that has been formatted into a nested data structure
    */

    // Alphabetical sort
    const sortDataByLevelName = (data: HierarchicalReferenceDataLevel[]) =>
      data.sort((a: HierarchicalReferenceDataLevel, b: HierarchicalReferenceDataLevel) =>
        a.level_name.localeCompare(b.level_name)
      );

    // Map through all levels at this part of the hierarchy and recursively display the tree
    return sortDataByLevelName(searchResults).map(
      (topLevelValue: HierarchicalReferenceDataLevel) => {
        // Extract all expected data
        const { level_name, child_levels, level_codes, level_codes_only } = topLevelValue;

        // Get any child levels as an array
        const childrenAsArray: HierarchicalReferenceDataLevel[] = child_levels
          ? Object.values(child_levels)
          : [];
        const allLevelCodesAsarray: string[] = level_codes_only
          ? Object.values(level_codes_only)
          : [];

        // Determine if we have another row of child levels, or if we should display codes
        const isNextLevelCodes = childrenAsArray.length > 0;

        // Determine if this level of checkbox should be set
        const codeIsChecked = checkIfAllCodesInState(allLevelCodesAsarray);
        const checkboxLabel = 'Select all applicable codes in ' + level_name;
        const checkboxID = formatIDElement('checkbox_level_' + level_name);

        // Determine if child levels should be visible based on the toggle
        const levelIsVisible = visibleLevelsState.includes(level_name);
        const headerId = formatIDElement('header_level_' + level_name);
        const markerIcon = levelIsVisible ? 'chevron down' : 'chevron right';

        const labelJsx = (
          <label key={level_name} htmlFor={checkboxID} className="">
            {checkboxLabel}
          </label>
        );

        // Renders the current tree of the hierarchy
        // and recursively renders the child levels and finally
        // the list of codes
        return (
          <List>
            <List.Item key={level_name}>
              <List.Content>
                <List.Header
                  onClick={() => toggleLevelVisibility(level_name)}
                  style={{ cursor: 'pointer' }}
                  id={headerId}
                >
                  <List.Icon name={markerIcon} /> {level_name}
                </List.Header>
                {/* Conditionally show details for this level */}
                {levelIsVisible && (
                  <List.Description style={{ marginLeft: '10px' }}>
                    <Divider />
                    <List.Item key={checkboxID} style={{ margin: '5px' }}>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleReferenceCodeToggle(allLevelCodesAsarray, !codeIsChecked)
                        }
                        checked={codeIsChecked}
                        id={checkboxID}
                      />{' '}
                      {labelJsx}
                    </List.Item>
                    {/* Conditionally show another recursive level, or on final level, show the list of codes */}
                    {isNextLevelCodes ? renderHierarchy(childrenAsArray) : renderCodes(level_codes)}
                  </List.Description>
                )}
              </List.Content>
            </List.Item>
          </List>
        );
      }
    );
  };

  // Render the full hierarchy of data
  return <Segment>{renderHierarchy(searchResults)}</Segment>;
};
