import {
  QueryCriteria,
  QueryTextCriteria,
  QueryCriteriaDrug,
  QueryCriteriaProcedure,
  QueryCriteriaLab,
  QueryCriteriaChargeDescription,
  QueryCriteriaClinicalObservation,
  QueryCriteriaLabText,
  ValueSet,
  QueryCriteriaDiagnosis,
} from '../../../types';
import { criteriaContainsIndexEvent, formatCriteriaType } from './utils';
import { SeparatorTuple } from './SeparatorTuple';
import { IndexEventIcon, IndexEventConfiguration } from './IndexEventConfiguration';
import { codeTypes, codeTypesWithIndexEvents } from '../../../constants';
import './IndexEventConfiguration.scss';

const criteriaSupportsIndexEvent = (criteria: QueryCriteria | QueryTextCriteria): boolean => {
  if (criteria.criteria_type === null) {
    return false;
  }
  return codeTypesWithIndexEvents.includes(criteria.criteria_type);
};

export const GenericCriteriaDisplay = ({
  criteria,
}: {
  criteria: QueryCriteria | QueryTextCriteria;
}) => {
  let displayValueSets = [<p key="placeholder-element"></p>];
  let displayValues = [<p key="placeholder-element"></p>];

  // Populates the display elements for values and value sets
  const populatedValueAndValueSetElements = (
    values: string[] | undefined,
    value_sets: ValueSet[] | null | undefined
  ) => {
    const hasValues = values && values.length > 0;
    const hasValueSets = value_sets && value_sets.length > 0;

    if (hasValueSets) {
      displayValueSets = value_sets.map((valueSet, index) => (
        <SeparatorTuple
          key={'vset-' + index}
          index={index}
          value={`Value Set - ${valueSet.name}`}
          sep={'OR'}
        />
      ));
      if (hasValues) {
        displayValueSets.push(
          <span key="separator-or" className="separator-string">
            OR
          </span>
        );
      }
    }
    if (hasValues) {
      displayValues = values.map((value, index) => (
        <SeparatorTuple key={'value-' + index} index={index} value={value} sep={'OR'} />
      ));
    }
  };

  // casts data for display by criteria code type
  switch (criteria.criteria_type) {
    case codeTypes.DRUG_CODE:
      const drugCriteria: QueryCriteriaDrug = criteria;
      populatedValueAndValueSetElements(drugCriteria.drug_codes, drugCriteria.value_sets);
      break;

    case codeTypes.DIAGNOSIS_CODE:
      const diagnosisCriteria: QueryCriteriaDiagnosis = criteria;
      populatedValueAndValueSetElements(
        diagnosisCriteria.diagnosis_codes,
        diagnosisCriteria.value_sets
      );
      break;

    case codeTypes.PROCEDURE_CODE:
      const procedureCriteria: QueryCriteriaProcedure = criteria;
      populatedValueAndValueSetElements(
        procedureCriteria.procedure_codes,
        procedureCriteria.value_sets
      );
      break;

    case codeTypes.LAB_CODE:
      const labCriteria: QueryCriteriaLab = criteria;
      populatedValueAndValueSetElements(labCriteria.lab_codes, labCriteria.value_sets);
      break;

    case codeTypes.LAB_TEXT:
      const labTextCriteria: QueryCriteriaLabText = criteria;
      populatedValueAndValueSetElements(labTextCriteria.lab_texts, labTextCriteria.value_sets);
      break;

    case codeTypes.CLINICAL_OBSERVATION:
      const clinicalObservationCriteria: QueryCriteriaClinicalObservation = criteria;
      populatedValueAndValueSetElements(
        clinicalObservationCriteria.clinical_observations,
        clinicalObservationCriteria.value_sets
      );
      break;

    case codeTypes.CHARGE_DESCRIPTION:
      const chargeDescriptionCriteria: QueryCriteriaChargeDescription = criteria;
      populatedValueAndValueSetElements(
        chargeDescriptionCriteria.charge_descriptions,
        chargeDescriptionCriteria.value_sets
      );
      break;
  }

  // Conditionally create index config elements
  const indexConfigElement = !criteriaContainsIndexEvent(criteria) ? (
    <></>
  ) : (
    <>
      <div className="flex-break" />
      <IndexEventConfiguration criteria={criteria} />
    </>
  );
  const indexIconElement = !criteriaSupportsIndexEvent(criteria) ? (
    <></>
  ) : (
    <>
      <IndexEventIcon criteria={criteria} />
    </>
  );

  return (
    <>
      {indexIconElement}
      <span className={'query-blder-criteria-name'}>
        {formatCriteriaType(criteria.criteria_type)}:
      </span>
      {displayValueSets}
      {displayValues}
      {indexConfigElement}
    </>
  );
};
