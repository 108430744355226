import React, { useState, useRef, useContext, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import GenericModal from './shared/GenericModal';
import ReportOppButton from './ReportOppInput';
import ModalErrorTag from './ModalErrorTag';
import { SupplierContext } from '../AppContent';
import { ReportPayload, ReportPayloadDisplaySettings, SupplierOption } from '../../types';
import {
  getSupplierOptionsFromSupplierIds,
  getSupplierIdsIncludingEnrollmentFromSupplierOptions,
  ymdStringToDate,
} from '../../utils/query_parser';
import { valueDisplayOptions } from '../../constants';

import 'react-datepicker/dist/react-datepicker.css';
import './CreateReportModal.scss';
import './RunQueryModal.scss';

type CreateReportModalProps = {
  isOpen: boolean;
  onSubmit: (e: React.FormEvent) => void;
  onClose: () => void;
  payload: ReportPayload;
  setPayload: (value: ReportPayload) => void;
  payloadDisplay?: Partial<ReportPayloadDisplaySettings>;
};

// payloadDisplay allows us to indicate fields as read-only, and supply display values.
// These display values do not necessarily match the actual values in the payload

const CreateReportModal = ({
  isOpen,
  onSubmit,
  onClose,
  payload,
  setPayload,
  payloadDisplay,
}: CreateReportModalProps) => {
  // Source/supplier selection
  const { suppliers } = useContext(SupplierContext);

  // SupplierOption: contains supplier id for the source, and also supplier ids for any connected enrollment sources
  // payload.parameters.supplier_ids: contains supplier ids for all sources in the report. Does not know about
  // connected enrollment sources unless we tell it
  const [supplierOptions, setSupplierOptions] = useState<SupplierOption[]>([]);

  // On component mount, we add any enrollment sources that correspond to the sources in our payload
  // If we do not add these enrollment sources, the report will be unable to use enrollment data
  useEffect(() => {
    const defaultSupplierOptions = payload.parameters?.supplier_ids
      ? getSupplierOptionsFromSupplierIds(payload.parameters?.supplier_ids, suppliers)
      : undefined;

    if (defaultSupplierOptions === undefined) return;

    setSupplierOptions(defaultSupplierOptions);
    const newParameters = {
      ...payload?.parameters,
      supplier_ids: getSupplierIdsIncludingEnrollmentFromSupplierOptions(defaultSupplierOptions),
    };
    const newPayload = { ...payload, parameters: newParameters };
    setPayload(newPayload);
  }, []);

  // Track whether form has been submitted.
  // Only display validation messages if a submission has been attempted
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);

  // Report name input
  const isNameValid = payload.name !== undefined && payload.name !== '';
  const shouldShowNameError = wasFormSubmitted && !isNameValid;

  // Opportunity ID input
  const isOppIdValid = payload.opportunity_id && /^[0-9]{6}$/.test(payload.opportunity_id);
  const shouldShowOppIdError = wasFormSubmitted && !isOppIdValid;
  const showOppId = payloadDisplay?.opportunity_id?.display_type !== valueDisplayOptions.HIDDEN;

  // Created by user input
  const isCreatedValid = payload.created_by !== undefined && payload.created_by !== '';
  const shouldShowCreatedError = wasFormSubmitted && !isCreatedValid;

  // Cohort location input
  const isLocationValid = payload.location !== undefined && /^[\w._]+$/.test(payload.location);
  const shouldShowLocationError = wasFormSubmitted && !isLocationValid;

  // Start date and end date inputs
  const areDateFieldsValid =
    payload.parameters?.start_date !== undefined &&
    payload.parameters?.end_date !== undefined &&
    payload.parameters?.end_date >= payload.parameters?.start_date;
  const shouldShowDateError = wasFormSubmitted && !areDateFieldsValid;

  const areAllFieldsValid =
    isNameValid && isOppIdValid && isCreatedValid && isLocationValid && areDateFieldsValid;

  const closeModal = () => {
    onClose();
    setWasFormSubmitted(false);
  };
  const submitButton = (e: React.FormEvent): void => {
    if (areAllFieldsValid) {
      onSubmit(e);
    } else {
      toast.warn('Report not submitted, please resolve all validation issues', {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
    setWasFormSubmitted(true);
    e.preventDefault();
  };

  // Custom logic to close Datepickers on Tab presses
  const startRef = useRef<null | DatePicker>(null);
  const onStartKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e && startRef.current && e.key === 'Tab') {
      startRef.current.setOpen(false);
    }
  };
  const endRef = useRef<null | DatePicker>(null);
  const onEndKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e && endRef.current && e.key === 'Tab') {
      endRef.current.setOpen(false);
    }
  };

  return (
    <GenericModal
      onSubmit={submitButton}
      close={closeModal}
      headerText={'New report'}
      show={isOpen}
      dismissButtonText={'Cancel'}
      customHeaderClassName={'query-blder-details-header'}
      modalBodyClassName={'query-blder-details-body'}
      customModalClassName={'query-blder-details-modal'}
      confirmButtonText={'Generate report     ►'}
      fixedHeight="80vh"
    >
      <h5>Data sources</h5>
      <Select
        isClearable
        isMulti
        className="supplier-dropdown"
        classNamePrefix="-dropdown"
        placeholder="Select"
        options={suppliers}
        defaultValue={supplierOptions}
        onChange={(suppliers) => {
          const newParameters = {
            ...payload.parameters,
            supplier_ids: suppliers.flatMap((option) => option.value),
          };
          setPayload({ ...payload, parameters: newParameters });
        }}
      />
      <h5>Report name</h5>
      <Input
        name={'report-name'}
        className={'query-blder-details-modal-input-element query-name-input'}
        placeholder={'Enter name'}
        value={payload.name}
        onChange={(e) => setPayload({ ...payload, name: e.target.value })}
      ></Input>
      {shouldShowNameError && <ModalErrorTag isIndented={false} text="Enter a valid name" />}

      {payloadDisplay?.opportunity_id?.display_type !== valueDisplayOptions.HIDDEN && (
        <React.Fragment>
          <h5>Opp. ID</h5>
          <ReportOppButton
            payload={payload}
            setPayload={setPayload}
            wrongInput={shouldShowOppIdError}
            onBlur={() => {}}
          ></ReportOppButton>
          {shouldShowOppIdError && (
            <ModalErrorTag isIndented={false} text="Enter a valid opp. ID" />
          )}
        </React.Fragment>
      )}

      <h5>Created by</h5>
      <Input
        name={'created_by'}
        className={'query-blder-details-modal-input-element query-name-input'}
        placeholder={'Enter your ID/email'}
        value={payload.created_by}
        onChange={(e) => setPayload({ ...payload, created_by: e.target.value })}
      ></Input>
      {shouldShowCreatedError && <ModalErrorTag isIndented={false} text="Enter a valid ID/Email" />}

      <h5>Table location</h5>
      <Input
        name={'location'}
        className={'query-blder-details-modal-input-element query-name-input'}
        placeholder={'Enter location'}
        value={payloadDisplay?.location?.value ?? payload.location}
        onChange={(e) => setPayload({ ...payload, location: e.target.value })}
        readOnly={payloadDisplay?.location?.display_type === valueDisplayOptions.READ_ONLY}
      ></Input>
      {shouldShowLocationError && (
        <ModalErrorTag isIndented={false} text="Enter a valid table location" />
      )}
      <div className="date-container">
        <div>
          <h5>From</h5>
          <DatePicker
            ref={startRef}
            selected={ymdStringToDate(payload.parameters?.start_date)}
            onChange={(date: Date) => {
              const newParameters = {
                ...payload?.parameters,
                start_date: date.toISOString().substring(0, 10) || undefined,
              };
              newParameters && setPayload({ ...payload, parameters: newParameters });
            }}
            onCalendarClose={() => {}}
            onCalendarOpen={() => {}}
            enableTabLoop={false}
            onKeyDown={onStartKeyDown}
            className="date-input"
            placeholderText="Select"
          />
        </div>
        <div>
          <h5>To</h5>
          <DatePicker
            ref={endRef}
            selected={ymdStringToDate(payload.parameters?.end_date)}
            onChange={(date: Date) => {
              const newParameters = {
                ...payload.parameters,
                end_date: date.toISOString().substring(0, 10),
              };
              setPayload({ ...payload, parameters: newParameters });
            }}
            onCalendarClose={() => {}}
            onCalendarOpen={() => {}}
            enableTabLoop={false}
            onKeyDown={onEndKeyDown}
            className="date-input"
            placeholderText="Select"
          />
        </div>
      </div>
      {shouldShowDateError && (
        <ModalErrorTag isIndented={false} text="Enter a valid start and end date" />
      )}
    </GenericModal>
  );
};

export default CreateReportModal;
