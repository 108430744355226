import { useState } from 'react';
import { Button, Grid, Header, Menu, Tab, Message } from 'semantic-ui-react';

import { toast } from 'react-toastify';

import GenericModal from '../../Modal/shared/GenericModal';
import { ValueSet } from '../../../types';
import { ValueSetCreationModal } from '../../Modal/ValueSetCreationModal';
import { ValueSetSelector } from '../../Table/ValueSetSelector';
import { ValueSetSelections } from '../ValueSetSelections';
import { codeTypeToTextMapping, codeTypesWithReferenceServiceSupport } from '../../../constants';
import { stringToCsv } from '../../../utils/string_to_csv';
import { stringMatchesRegex, getCodeTypeValidationRegex } from '../../../utils/code_validation';
import { codeTypesWithMandatoryUppercase } from '../../../constants';
import { ReferenceServiceSearch } from './ReferenceServiceSearch/ReferenceServiceSearch';
import './CommonCriteriaConfigure.scss';

export const CommonCriteriaConfigure = ({
  onSubmit,
  selectedCriteria,
}: {
  onSubmit: (csvString: string, valueSets: ValueSet[]) => void;
  selectedCriteria: string;
}) => {
  const [valueCsvString, setValueCsvString] = useState<string>('');
  const [valueEntryModalIsOpen, setValueEntryModalIsOpen] = useState<boolean>(false);
  const [valueSetCreationModalIsOpen, setValueSetCreationModalIsOpen] = useState<boolean>(false);
  const [selectedValueSets, setSelectedValueSets] = useState<ValueSet[]>([]);

  const singularText = codeTypeToTextMapping.get(selectedCriteria)?.singular || '';
  const pluralText = codeTypeToTextMapping.get(selectedCriteria)?.plural || '';
  const shouldShowReferenceSearchComponent =
    codeTypesWithReferenceServiceSupport.includes(selectedCriteria);
  const messageContent = shouldShowReferenceSearchComponent
    ? `Use the search bar to search for codes, select one or more ${singularText} value sets, or enter ${pluralText} manually.`
    : `Select one or more ${singularText} value sets or enter ${pluralText} manually.`;

  let criteriaSelectionPanes = [];
  if (shouldShowReferenceSearchComponent) {
    criteriaSelectionPanes.push({
      menuItem: (
        <Menu.Item key="criteria-tab-search-codes" data-testid="criteria-tab-search-codes">
          Search codes
        </Menu.Item>
      ),
      render: () => (
        <ReferenceServiceSearch
          selectedCriteria={selectedCriteria}
          valueCsvString={valueCsvString}
          setValueCsvString={setValueCsvString}
        />
      ),
    });
  }
  criteriaSelectionPanes.push({
    menuItem: (
      <Menu.Item key="criteria-tab-value-sets" data-testid="criteria-tab-value-sets">
        Value sets
      </Menu.Item>
    ),
    render: () => (
      <ValueSetSelector codeType={selectedCriteria} onValueSetSelection={addValueSet} />
    ),
  });

  const addValuesToQuery = () => {
    const cleanedValueCsvString = codeTypesWithMandatoryUppercase.includes(selectedCriteria)
      ? valueCsvString.toUpperCase()
      : valueCsvString;

    onSubmit(cleanedValueCsvString, selectedValueSets);
    setValueEntryModalIsOpen(false);
  };

  const addValueSet = (valueSet: ValueSet) => {
    if (!selectedValueSets.includes(valueSet)) {
      setSelectedValueSets(selectedValueSets.concat(valueSet));
    }
  };

  const disableConfirm: boolean = valueCsvString === '' && selectedValueSets.length === 0;

  const openCreateNewValueSetModal = () => {
    const valueSetCreationValues: string[] = stringToCsv(valueCsvString, selectedCriteria);
    const criteriaTypeRegex = getCodeTypeValidationRegex(selectedCriteria);
    if (!criteriaTypeRegex) {
      setValueSetCreationModalIsOpen(true);
      return;
    }

    const invalidValues = [];
    for (let value of valueSetCreationValues) {
      if (!stringMatchesRegex(value, criteriaTypeRegex)) {
        invalidValues.push(value);
      }
    }
    if (invalidValues.length) {
      const joinedInvalidValues = '"' + invalidValues.join('", "') + '"';
      toast.warn(
        'Value Set cannot be created because the following values are invalid: ' +
          joinedInvalidValues,
        { autoClose: false }
      );
      return;
    }

    setValueSetCreationModalIsOpen(true);
  };

  // TODO(jamcentire): refactor this to use context and a reducer to manage state - eliminate passing states and setters
  return (
    <div className={'query-blder-criteria-configure'}>
      <div className="criteria-arrow-body"></div>
      <div className="criteria-arrow-head"></div>
      <button
        data-testid="open-configure-modal"
        className={'query-blder-criteria-configure-expand-window'}
        onClick={() => {
          setValueEntryModalIsOpen(true);
        }}
      >
        Add {pluralText}
      </button>
      <GenericModal
        headerText={'Add ' + pluralText}
        show={valueEntryModalIsOpen}
        close={() => {
          setSelectedValueSets([]);
          setValueCsvString('');
          setValueEntryModalIsOpen(false);
        }}
        onSubmit={(e) => {
          e.preventDefault();
          addValuesToQuery();
        }}
        dismissButtonText={'Cancel'}
        confirmButtonText={'Add'}
        customModalClassName={'value-set-modal'}
        disableConfirm={disableConfirm}
        fixedHeight={'75vh'}
      >
        <Grid className="value-set-modal-grid fixed-height-grid" columns={2} divided padded>
          <Grid.Column className="scrolling-pane">
            <div className="configure-modal-tab">
              <Tab
                menu={{ secondary: true, attached: false, pointing: true }}
                panes={criteriaSelectionPanes}
              />
            </div>
          </Grid.Column>
          <Grid.Column className="scrolling-pane">
            <div className="value-and-value-set-header-bar">
              <Header as="h5">Enter {pluralText}</Header>
              <Button
                className={'value-set-modal-button ' + (valueCsvString ? '' : 'disabled')}
                data-testid="open-create-valueset-modal"
                type="button"
                onClick={openCreateNewValueSetModal}
              >
                CREATE VALUE SET
              </Button>
            </div>
            <div data-testid="configure-modal-header" className="value-set-modal-header-message">
              <Message id="criteria-config-message" positive>
                <p>{messageContent}</p>
              </Message>
            </div>
            {selectedValueSets.length > 0 && (
              <div className="selected-value-sets-container">
                <ValueSetSelections
                  valueSets={selectedValueSets}
                  setValueSets={setSelectedValueSets}
                  csvString={valueCsvString}
                  setCsvString={setValueCsvString}
                  codeType={selectedCriteria}
                />
              </div>
            )}
            <div>
              <textarea
                className={'select-values-textarea gray-text-input'}
                data-testid="criteria-configure-enter-values"
                placeholder={`Add ${singularText}`}
                cols={55}
                rows={10}
                value={valueCsvString}
                onChange={(event: { target: { value: any } }) =>
                  setValueCsvString(event.target.value)
                }
              ></textarea>
            </div>
            <div>
              <Button
                className={
                  'value-set-modal-button clear-text-button ' + (valueCsvString ? '' : 'disabled')
                }
                type="button"
                onClick={() => setValueCsvString('')}
              >
                CLEAR TEXT
              </Button>
            </div>
          </Grid.Column>
        </Grid>
      </GenericModal>
      <ValueSetCreationModal
        selectedValueSets={selectedValueSets}
        setSelectedValueSets={setSelectedValueSets}
        isOpen={valueSetCreationModalIsOpen}
        setIsOpen={setValueSetCreationModalIsOpen}
        valuesList={stringToCsv(valueCsvString, selectedCriteria)}
        valuesCodeType={selectedCriteria}
        setValueCsvString={setValueCsvString}
      />
    </div>
  );
};
