import { useContext, useState } from 'react';
import Select from 'react-select';
import { Icon } from 'semantic-ui-react';

import {
  QueryCriteria,
  ValueSet,
  QueryCriteriaDateRange,
  QueryCriteriaAge,
  QueryCriteriaEnrollment,
} from '../../types';
import { codeTypes, criteriaTypes } from '../../constants';

import { AgeCriteriaConfigure, AgeCriteriaDisplay } from './CriteriaTypeLogic/AgeCriteria';
import {
  EnrollmentCriteriaConfigure,
  EnrollmentCriteriaDisplay,
} from './CriteriaTypeLogic/EnrollmentCriteria';
import { CohortCriteriaConfigure, CohortCriteriaDisplay } from './CriteriaTypeLogic/CohortCriteria';
import { GenericCriteriaDisplay } from './CriteriaTypeLogic/GenericCriteria';
import { DateCriteriaDisplay } from './CriteriaTypeLogic/DateRangeCriteria';
import { ReadOnlyContext } from './QueryBuilder';
import { CommonCriteriaConfigure } from './CriteriaTypeLogic/CommonCriteriaConfigure';
import {
  MortalityCriteriaConfigure,
  MortalityCriteriaDisplay,
} from './CriteriaTypeLogic/MortalityCriteria';
import { stringToCsv } from '../../utils/string_to_csv';

import './Criteria.scss';

type CriteriaProps = {
  criteria: QueryCriteria | null;
  criteriaOptions: { value: string; label: string }[];
  addCriteria: (value: QueryCriteria) => void;
  removeCriteria: (criteria: QueryCriteria) => void;
  setIsCriteriaHovered: (value: boolean) => void;
  setIsCriteriaConfiguring: (value: boolean) => void;
};
const Criteria = ({
  criteria,
  criteriaOptions,
  addCriteria,
  removeCriteria,
  setIsCriteriaHovered,
  setIsCriteriaConfiguring,
}: CriteriaProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const onHover = (value: boolean) => {
    setIsHovered(value);
    setIsCriteriaHovered(value);
  };
  const isReadOnlyView = useContext(ReadOnlyContext);

  const closeCriteria = () =>
    criteria ? removeCriteria(criteria) : setIsCriteriaConfiguring(false);

  const renderCriteria = () => {
    return !!criteria ? (
      <CriteriaDisplay criteria={criteria} />
    ) : (
      <CriteriaConfigure
        criteriaOptions={criteriaOptions}
        addCriteria={addCriteria}
        setIsCriteriaConfiguring={setIsCriteriaConfiguring}
      />
    );
  };

  return (
    <>
      <div
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
        className={'query-blder-criteria-group-container'}
        style={{ borderColor: isHovered ? '#939393' : '#D6D6D6' }}
      >
        <div className={'query-blder-criteria-group'}>{renderCriteria()}</div>
        {!isReadOnlyView && (
          <div onClick={closeCriteria} className={'query-blder-close-group'}>
            <Icon name="close" />
          </div>
        )}
      </div>
      <div className={'logical-operator-line'}>
        <span className={'query-blder-criteria-logical-operator'}>AND</span>
      </div>
    </>
  );
};

type CriteriaConfigureProps = {
  criteriaOptions: { value: string; label: string }[] | undefined;
  addCriteria: (value: QueryCriteria) => void;
  setIsCriteriaConfiguring: (value: boolean) => void;
};

const CriteriaConfigure = ({
  criteriaOptions,
  addCriteria,
  setIsCriteriaConfiguring,
}: CriteriaConfigureProps) => {
  const [selectedCriteria, setSelectedCriteria] = useState<string | null>(null);

  const onSubmitCriteria = (
    csvString: string,
    valueSets: ValueSet[],
    codeType: string,
    optionalParameters?: object
  ) => {
    let criteriaValues: string[] = stringToCsv(csvString, codeType);

    switch (codeType) {
      case codeTypes.DIAGNOSIS_CODE: {
        configureCriteria(
          codeType,
          { diagnosis_codes: criteriaValues, index_event: null },
          valueSets
        );
        break;
      }
      case codeTypes.PROCEDURE_CODE: {
        configureCriteria(
          codeType,
          { procedure_codes: criteriaValues, index_event: null },
          valueSets
        );
        break;
      }
      case codeTypes.DRUG_CODE: {
        configureCriteria(codeType, { drug_codes: criteriaValues, index_event: null }, valueSets);
        break;
      }
      case codeTypes.LAB_CODE: {
        configureCriteria(codeType, { lab_codes: criteriaValues, index_event: null }, valueSets);
        break;
      }
      case codeTypes.LAB_TEXT: {
        configureCriteria(codeType, { lab_texts: criteriaValues }, valueSets);
        break;
      }
      case codeTypes.CLINICAL_OBSERVATION: {
        configureCriteria(codeType, { clinical_observations: criteriaValues }, valueSets);
        break;
      }
      case codeTypes.CHARGE_DESCRIPTION: {
        configureCriteria(codeType, { charge_descriptions: criteriaValues }, valueSets);
        break;
      }
      case codeTypes.MEDICAL_ENROLLMENT:
      case codeTypes.PHARMACY_ENROLLMENT: {
        configureCriteria(codeType, {
          ...optionalParameters,
          enrollment_suppliers: criteriaValues,
        });
        break;
      }
      case codeTypes.MORTALITY: {
        configureCriteria(codeType, {}, valueSets);
        break;
      }
      case criteriaTypes.COHORT_LOCATION: {
        configureCriteria(codeType, { cohort_locations: criteriaValues }, valueSets);
        break;
      }
    }
  };

  const renderConfigureCriteria = () => {
    switch (selectedCriteria) {
      case criteriaTypes.AGE:
        return (
          <AgeCriteriaConfigure
            onSubmit={(start_age: string, end_age: string) => {
              configureCriteria(criteriaTypes.AGE, { start_age, end_age });
            }}
          />
        );

      case codeTypes.DIAGNOSIS_CODE:
      case codeTypes.PROCEDURE_CODE:
      case codeTypes.DRUG_CODE:
      case codeTypes.LAB_CODE:
        return (
          <div className="common-criteria-configure-box">
            <CommonCriteriaConfigure
              onSubmit={(csv_string: string, value_sets: ValueSet[]) => {
                onSubmitCriteria(csv_string, value_sets, selectedCriteria);
              }}
              selectedCriteria={selectedCriteria}
            />
          </div>
        );
      case codeTypes.LAB_TEXT:
        return (
          <div className="common-criteria-configure-box">
            <CommonCriteriaConfigure
              onSubmit={(csv_string: string, value_sets: ValueSet[]) => {
                onSubmitCriteria(csv_string, value_sets, selectedCriteria);
              }}
              selectedCriteria={selectedCriteria}
            />
          </div>
        );
      case codeTypes.CLINICAL_OBSERVATION:
        return (
          <div className="common-criteria-configure-box">
            <CommonCriteriaConfigure
              onSubmit={(csv_string: string, value_sets: ValueSet[]) => {
                onSubmitCriteria(csv_string, value_sets, selectedCriteria);
              }}
              selectedCriteria={selectedCriteria}
            />
          </div>
        );
      case codeTypes.CHARGE_DESCRIPTION:
        return (
          <div className="common-criteria-configure-box">
            <CommonCriteriaConfigure
              onSubmit={(csv_string: string, value_sets: ValueSet[]) => {
                onSubmitCriteria(csv_string, value_sets, selectedCriteria);
              }}
              selectedCriteria={selectedCriteria}
            />
          </div>
        );
      case codeTypes.MEDICAL_ENROLLMENT:
      case codeTypes.PHARMACY_ENROLLMENT:
        return (
          <div className="common-criteria-configure-box">
            <EnrollmentCriteriaConfigure
              criteria_type={selectedCriteria}
              onSubmit={(enrollmentSuppliers: string[], duration: string) => {
                onSubmitCriteria(enrollmentSuppliers.toString(), [], selectedCriteria, {
                  duration: duration,
                });
              }}
            />
          </div>
        );
      case criteriaTypes.COHORT_LOCATION:
        return (
          <CohortCriteriaConfigure
            onSubmit={(location_id: string) => {
              onSubmitCriteria(location_id, [], selectedCriteria);
            }}
          />
        );
      case codeTypes.MORTALITY:
        return (
          <div className="common-criteria-configure-box">
            <MortalityCriteriaConfigure
              criteria_type={selectedCriteria}
              onSubmit={() => {
                onSubmitCriteria('', [], selectedCriteria);
              }}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const configureCriteria = (type: string, values: object, value_sets?: ValueSet[]) => {
    let criteria_values: QueryCriteria = { criteria_type: type, ...values };
    if (value_sets) {
      criteria_values.value_sets = value_sets;
    }
    addCriteria(criteria_values);
    setIsCriteriaConfiguring(false);
  };

  return (
    <div className={'query-blder-criteria-configure'}>
      <Select
        options={criteriaOptions}
        onChange={(option) => setSelectedCriteria(option ? option.value : option)}
        isClearable
        className="criteria-options-select"
        classNamePrefix="select"
      />
      {renderConfigureCriteria()}
    </div>
  );
};

const CriteriaDisplay = ({ criteria }: { criteria: QueryCriteria }) => {
  const renderDisplayCriteria = () => {
    switch (criteria.criteria_type) {
      case criteriaTypes.AGE:
        return <AgeCriteriaDisplay criteria={criteria as QueryCriteriaAge} />;
      case criteriaTypes.DATE_RANGE:
        return <DateCriteriaDisplay criteria={criteria as QueryCriteriaDateRange} />;
      case codeTypes.DIAGNOSIS_CODE:
      case codeTypes.PROCEDURE_CODE:
      case codeTypes.DRUG_CODE:
      case codeTypes.LAB_CODE:
      case codeTypes.LAB_TEXT:
      case codeTypes.CLINICAL_OBSERVATION:
      case codeTypes.CHARGE_DESCRIPTION:
        return <GenericCriteriaDisplay criteria={criteria} />;
      case codeTypes.MEDICAL_ENROLLMENT:
      case codeTypes.PHARMACY_ENROLLMENT:
        return <EnrollmentCriteriaDisplay criteria={criteria as QueryCriteriaEnrollment} />;
      case criteriaTypes.COHORT_LOCATION:
        return <CohortCriteriaDisplay criteria={criteria} />;
      case codeTypes.MORTALITY:
        return <MortalityCriteriaDisplay criteria={criteria} />;
      default:
        return <></>;
    }
  };
  return <div className={'query-blder-criteria-display'}>{renderDisplayCriteria()}</div>;
};

export default Criteria;
