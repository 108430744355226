export const codeTypes = {
  DIAGNOSIS_CODE: 'diagnosis_code',
  PROCEDURE_CODE: 'procedure_code',
  DRUG_CODE: 'drug_code',
  LAB_CODE: 'lab_code',
  LAB_TEXT: 'lab_text',
  CLINICAL_OBSERVATION: 'clinical_observation',
  CHARGE_DESCRIPTION: 'charge_description',
  MEDICAL_ENROLLMENT: 'medical_enrollment',
  PHARMACY_ENROLLMENT: 'pharmacy_enrollment',
  MORTALITY: 'mortality',
};

export const codeTypesWithReferenceServiceSupport = [
  codeTypes.DIAGNOSIS_CODE,
  codeTypes.DRUG_CODE,
  codeTypes.PROCEDURE_CODE,
  codeTypes.LAB_CODE,
];

export const codeTypesWithIndexEvents = [
  codeTypes.DIAGNOSIS_CODE,
  codeTypes.PROCEDURE_CODE,
  codeTypes.LAB_CODE,
  codeTypes.DRUG_CODE,
];

export const datasetTypes = {
  EMR: 'EMR',
  LAB: 'LAB',
  CONSUMER: 'CONSUMER',
  HOSPITAL: 'HOSPITAL',
  MEDICAL_CLAIMS: 'MEDICALCLAIMS',
  RX_CLAIMS: 'RXCLAIMS',
};

export const criteriaTypes = {
  AGE: 'age',
  DATE_RANGE: 'date_range',
  COHORT_LOCATION: 'cohort_location',
};

export const HV_TENANT_ID = 't-hv000000';

/*
 * Map between Tenant IDs from Auth0 and Opp ID's in Salesforce
 * for external organizations
 */
export const externalOrganizationsTenantOppIdMap = new Map([
  ['t-to000000', '990024'],
  ['t-ex000001', '007642'],
  ['t-ex000002', '008098'],
  ['t-ex000003', '008777'],
]);

// TODO: We've included HOSPITAL in lab codes, because PS 87 has lab data. This is a hard-coded fix
// we would like to eliminate
export const queryCriteriaOptions = [
  {
    value: criteriaTypes.AGE,
    label: 'Ages',
    validDatasetTypes: Object.values(datasetTypes),
  },
  {
    value: codeTypes.DIAGNOSIS_CODE,
    label: 'Diagnosis Codes',
    validDatasetTypes: [
      datasetTypes.EMR,
      datasetTypes.LAB,
      datasetTypes.HOSPITAL,
      datasetTypes.MEDICAL_CLAIMS,
      datasetTypes.RX_CLAIMS,
    ],
  },
  {
    value: codeTypes.PROCEDURE_CODE,
    label: 'Procedure Codes',
    validDatasetTypes: [
      datasetTypes.EMR,
      datasetTypes.LAB,
      datasetTypes.HOSPITAL,
      datasetTypes.MEDICAL_CLAIMS,
    ],
  },
  {
    value: codeTypes.DRUG_CODE,
    label: 'Drug Codes',
    validDatasetTypes: [datasetTypes.EMR, datasetTypes.MEDICAL_CLAIMS, datasetTypes.RX_CLAIMS],
  },
  {
    value: codeTypes.LAB_CODE,
    label: 'Lab Codes',
    validDatasetTypes: [datasetTypes.EMR, datasetTypes.LAB, datasetTypes.HOSPITAL],
  },
  {
    value: codeTypes.LAB_TEXT,
    label: 'Lab Text',
    validDatasetTypes: [datasetTypes.EMR, datasetTypes.LAB],
  },
  {
    value: codeTypes.CLINICAL_OBSERVATION,
    label: 'Clinical Observations Text',
    validDatasetTypes: [datasetTypes.EMR],
  },
  {
    value: codeTypes.CHARGE_DESCRIPTION,
    label: 'Charge Desc',
    validDatasetTypes: [datasetTypes.HOSPITAL],
  },
  {
    value: codeTypes.MEDICAL_ENROLLMENT,
    label: 'Medical Enrollment',
    validDatasetTypes: Object.values(datasetTypes),
  },
  {
    value: codeTypes.PHARMACY_ENROLLMENT,
    label: 'Pharmacy Enrollment',
    validDatasetTypes: Object.values(datasetTypes),
  },
  {
    value: criteriaTypes.COHORT_LOCATION,
    label: 'Cohort Location',
    validDatasetTypes: Object.values(datasetTypes),
  },
  {
    value: codeTypes.MORTALITY,
    label: 'Mortality',
    validDatasetTypes: [datasetTypes.CONSUMER],
  },
];
export const codeTypesWithMandatoryUppercase = [codeTypes.DIAGNOSIS_CODE, codeTypes.PROCEDURE_CODE];

export const codeTypeToTextMapping = new Map([
  [
    codeTypes.DIAGNOSIS_CODE,
    {
      singular: 'diagnosis code',
      plural: 'diagnosis codes',
      codeLabel: 'ICD10',
    },
  ],
  [
    codeTypes.PROCEDURE_CODE,
    {
      singular: 'procedure code',
      plural: 'procedure codes',
      codeLabel: 'Procedure',
    },
  ],
  [
    codeTypes.DRUG_CODE,
    {
      singular: 'drug code',
      plural: 'drug codes',
      codeLabel: 'NDC',
    },
  ],
  [
    codeTypes.LAB_CODE,
    {
      singular: 'lab code',
      plural: 'lab codes',
    },
  ],
  [
    codeTypes.LAB_TEXT,
    {
      singular: 'lab text',
      plural: 'lab text strings',
    },
  ],
  [
    codeTypes.CLINICAL_OBSERVATION,
    {
      singular: 'clinical observation',
      plural: 'clinical observations',
    },
  ],
  [
    codeTypes.CHARGE_DESCRIPTION,
    {
      singular: 'charge description',
      plural: 'charge descriptions',
    },
  ],
  [
    codeTypes.MEDICAL_ENROLLMENT,
    {
      singular: 'Medical Enrollment',
      plural: 'Medical Enrollments',
    },
  ],
  [
    codeTypes.PHARMACY_ENROLLMENT,
    {
      singular: 'Pharmacy Enrollment',
      plural: 'Pharmacy Enrollments',
    },
  ],
]);

export const valueDisplayOptions = {
  READ_ONLY: 'read_only',
  HIDDEN: 'hidden',
};
