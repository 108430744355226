import { useEffect, useState, useContext, useRef } from 'react';
import { Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { formatReferenceDataIntoHierarchy } from '../../../../utils/reference_code_hierarchy';
import { StandardizedReferenceDataRow, HierarchicalReferenceDataLevel } from '../../../../types';
import SearchBar from '../../../Table/SearchBar';
import { codeTypeToTextMapping, codeTypes } from '../../../../constants';
import { ReferenceDataContext } from '../../../AppContent';
import { GenericReferenceHeirarchy } from './GenericReferenceHeirarchy';
import { LabResultsTable } from './LabResultsTable';

import './ReferenceServiceSearch.scss';

export const ReferenceServiceSearch = ({
  selectedCriteria,
  valueCsvString,
  setValueCsvString,
}: {
  selectedCriteria: string;
  valueCsvString: string;
  setValueCsvString: (value: string) => void;
}) => {
  /**
   * Renders a component that allows users to search for reference data codes by code, name, description, etc
   *
   * @param selectedCriteria - The current criteria type (eg: codeTypes.DIAGNOSIS_CODE)
   * @param valueCsvString - Comma separated value string of all currently selected codes for this criteria type
   * @param setValueCsvString - State setter which will allow this component to make changes to selected codes
   */

  // Pull the Reference API Client from context
  const { referenceDataBackend } = useContext(ReferenceDataContext);

  // Holds current search term
  const [searchValue, setSearchValue] = useState('');
  // Holds formatted results from the Reference Data Service
  const [referenceDataResults, setReferenceDataResults] = useState<StandardizedReferenceDataRow[]>(
    []
  );
  // Whether a search is active
  const [isLoading, setIsLoading] = useState(false);

  // Convert incoming valueCsvString string values to array of strings for easier manipulation
  const selectedCodesAsList = valueCsvString
    .split(',')
    .map((s) => s.trim())
    .filter(Boolean);

  const pluralText = codeTypeToTextMapping.get(selectedCriteria)?.plural || '';

  const makeReferenceAPISearch = async (): Promise<StandardizedReferenceDataRow[]> => {
    /**
     * Use the referenceServiceClient stored in state to make the appropriate
     **/
    const searchArgs = { search_terms: searchValue };
    switch (selectedCriteria) {
      case codeTypes.DIAGNOSIS_CODE:
        return await referenceDataBackend.searchDiagnosis(searchArgs);
      case codeTypes.DRUG_CODE:
        return await referenceDataBackend.searchDrug(searchArgs);
      case codeTypes.PROCEDURE_CODE:
        const zipProcedureSearches = async () => {
          const resultsICD10 = await referenceDataBackend.searchProcedureICD10(searchArgs);
          const resultsHCPCSCPT = await referenceDataBackend.searchProcedureHCPCSCPT(searchArgs);
          const mergedObjects = [...resultsHCPCSCPT, ...resultsICD10];
          return mergedObjects;
        };
        return await zipProcedureSearches();
      case codeTypes.LAB_CODE:
        return await referenceDataBackend.searchLab(searchArgs);
      default:
        console.error('Invalid selectedCriteria: ' + selectedCriteria);
    }
    return [];
  };

  type Timer = ReturnType<typeof setTimeout>;
  const timer = useRef<Timer>();
  const debounce = (fn: Function, ms = 1000) => {
    return function (this: any, ...args: any[]) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => fn.apply(this, args), ms);
    };
  };
  const warnDataLimitExceeded = () => {
    toast.error(
      'WARNING: Results displayed are limited to 10,000 codes. Refine your search criteria.',
      {
        closeOnClick: true,
        hideProgressBar: true,
      }
    );
  };

  const searchReferenceData = async () => {
    setIsLoading(true);

    // Make request to the Reference Data Service

    makeReferenceAPISearch()
      .then((referenceDataResults: StandardizedReferenceDataRow[]) => {
        setReferenceDataResults(referenceDataResults);
        if (referenceDataResults.length === 10000) {
          // TODO remove me once pagination is implemented
          warnDataLimitExceeded();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error('An error occurred while searching for code. Please try again later.', {
          position: 'top-right',
          hideProgressBar: true,
          closeOnClick: true,
        });
        setReferenceDataResults([]);
        setIsLoading(false);
        return;
      });
  };
  const debouncedSearch = debounce(searchReferenceData, 500);

  useEffect(() => {
    /**
     * When searchValue changes, either reset the search or make a new
     */
    (async () => {
      if (searchValue === '') {
        setReferenceDataResults([]);
        setIsLoading(false);
        return;
      } else {
        setIsLoading(true);
      }
      debouncedSearch();
    })();
  }, [searchValue]);

  const handleReferenceCodeToggle = (
    codesToAddOrRemove: string[],
    checkboxIsNowChecked: boolean
  ) => {
    /* Handles toggling removing one or more codes */

    let selectedCodesSet: Set<string> = new Set([...selectedCodesAsList]);

    if (checkboxIsNowChecked) {
      // Add all codes provided
      codesToAddOrRemove.forEach((codeToAddOrRemove) => selectedCodesSet.add(codeToAddOrRemove));
    } else {
      // Removes all codes provided
      codesToAddOrRemove.forEach((codeToAddOrRemove) => selectedCodesSet.delete(codeToAddOrRemove));
    }

    const selectedCodesBackAsList: string[] = [...Array.from(selectedCodesSet)];

    // Save in state
    setValueCsvString(selectedCodesBackAsList.join(', '));
  };

  let searchBar = (
    <SearchBar
      onSearchSubmit={(searchValue) => {
        setSearchValue(searchValue.toLowerCase());
      }}
      onSearchClear={() => {
        setSearchValue('');
      }}
      searchPlaceholderText={`Search ${pluralText} by name or code...`}
      isLoading={isLoading}
      minSearchLength={3}
    />
  );

  const renderSearchResults = () => {
    // No search terms, show nothing
    if (!searchValue.length) {
      return null;
    }

    // Still loading, show nothing
    if (isLoading) {
      return null;
    }

    // Search is active, but no results, show message
    if (!referenceDataResults.length) {
      return (
        <Message data-testid="reference-search-no-results">
          No results returned for "{searchValue}".
        </Message>
      );
    }

    switch (selectedCriteria) {
      case codeTypes.DIAGNOSIS_CODE:
      case codeTypes.DRUG_CODE:
      case codeTypes.PROCEDURE_CODE:
        const referenceDataResultsAsHierarchy: HierarchicalReferenceDataLevel[] =
          formatReferenceDataIntoHierarchy(referenceDataResults);
        return (
          <GenericReferenceHeirarchy
            searchResults={referenceDataResultsAsHierarchy}
            selectedCodes={valueCsvString}
            handleReferenceCodeToggle={handleReferenceCodeToggle}
            codeType={selectedCriteria}
          />
        );
      case codeTypes.LAB_CODE:
        return (
          <LabResultsTable
            searchResults={referenceDataResults}
            selectedCodes={valueCsvString}
            handleReferenceCodeToggle={handleReferenceCodeToggle}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="reference-code-search-bar">{searchBar}</div>
      <div className="reference-code-results-section ">{renderSearchResults()}</div>
    </>
  );
};
