import { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { Slide, ToastContainer } from 'react-toastify';

import Navbar from './Navbar/Navbar';
import QueryBuilder, { QueryBuilderFindSummaryWrapper } from './QueryBuilder/QueryBuilder';
import QueryDetail from './QueryDetail/QueryDetail';
import QueryList from './QueryList/QueryList';
import ReportList from './CohortReports/ReportList';
import {
  CohortServiceApiClient,
  DatasetServiceApiClient,
  ValueSetServiceApiClient,
  ReportingServiceApiClient,
  ReferenceServiceApiClient,
  getReportsType,
  getQueriesType,
} from '../backend';
import {
  ApiData,
  QueryActions,
  QuerySummary,
  Dataset,
  ValueSet,
  ValueSetData,
  ReportData,
  ReportModel,
  ReportPayload,
  SupplierOption,
  SupplierData,
  ReferenceData,
  SupplierModel,
} from '../types';
import { isSupportedDataset } from '../utils/dataset_filter';
import { groupByKey } from '../utils/query_parser';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { displayPageLoadingAtom } from './LoadingOverlay';
import PageLoadingOverlay from './LoadingOverlay';

const BACKEND_QUERY_LIMIT = '100';
const MAX_BACKEND_CALLS = 100;

export const QueryActionsContext = createContext<QueryActions>({
  rerunQuery: (_: string) => {},
  duplicateQuery: (_: string) => {},
  cancelQuery: (_: string) => {},
});
type QueryActionsWrapperProps = {
  refreshQueryListData: () => Promise<void>;
  queryBuilderBackend: CohortServiceApiClient;
  children: JSX.Element;
};
const QueryActionsWrapper = ({
  refreshQueryListData,
  queryBuilderBackend,
  children,
}: QueryActionsWrapperProps) => {
  const navigate = useNavigate();
  const setPageLoading = useSetRecoilState(displayPageLoadingAtom);

  const rerunQuery = (requestId: string) => {
    setPageLoading(true);
    queryBuilderBackend
      .rerunQuery(requestId)
      .then(async () => {
        setPageLoading(false);
        navigate('/');
        refreshQueryListData();
      })
      .catch((reason) => {
        console.log(`rerunQuery encountered an error: ${reason}`);
        setPageLoading(false);
      });
  };
  const duplicateQuery = (requestId: string) => {
    navigate(`/${requestId}/duplicate`);
  };
  const cancelQuery = (requestId: string) => {
    setPageLoading(true);
    queryBuilderBackend
      .cancelQuery(requestId)
      .then(async () => {
        setPageLoading(false);
        navigate('/');
        refreshQueryListData();
      })
      .catch((reason) => {
        console.log(`cancelQuery encountered an error: ${reason}`);
        setPageLoading(false);
      });
  };

  return (
    <QueryActionsContext.Provider
      value={{
        rerunQuery: rerunQuery,
        duplicateQuery: duplicateQuery,
        cancelQuery: cancelQuery,
      }}
    >
      {children}
    </QueryActionsContext.Provider>
  );
};

const DATASET_LIST_PARAMS = {
  is_public: 'true',
  status: 'active',
};
export const ApiDataContext = createContext<ApiData>({
  datasets: [],
  querySummaries: [],
  isRefreshingApiData: false,
  areQueriesLoading: false,
});
export const ValueSetContext = createContext<ValueSetData>({
  valueSets: [],
  valueSetBackend: null,
  setValueSetData: async (valueSets: ValueSet[]) => {},
});
export const ReportContext = createContext<ReportData>({
  reports: [],
  reportingBackend: null,
  isRefreshingReportData: false,
});
export const SupplierContext = createContext<SupplierData>({
  suppliers: [],
  supplierBackend: null,
  isRefreshingSupplierData: false,
});
export const ReferenceDataContext = createContext<ReferenceData>({
  referenceDataBackend: null,
});
type AppContentProps = {
  handleLogout: () => void;
  queryBuilderBackend: CohortServiceApiClient;
  datasetManagementBackend: DatasetServiceApiClient;
  valueSetBackend: ValueSetServiceApiClient;
  reportingBackend: ReportingServiceApiClient;
  referenceServiceBackend: ReferenceServiceApiClient;
};
function AppContent({
  handleLogout,
  queryBuilderBackend,
  datasetManagementBackend,
  valueSetBackend,
  reportingBackend,
  referenceServiceBackend,
}: AppContentProps) {
  const [querySummaries, setQuerySummaries] = useState<QuerySummary[]>([]);
  const [isRefreshingApiData, setIsRefreshingApiData] = useState(false);
  const [areQueriesLoading, setAreQueriesLoading] = useState(false);
  const [datasets, setDatasets] = useState<Dataset[]>([]);
  const [valueSets, setValueSets] = useState<ValueSet[]>([]);
  const [reports, setReports] = useState<ReportModel[]>([]);
  const [isRefreshingReportData, setIsRefreshingReportData] = useState(false);
  const [supplierOptions, setSupplierOptions] = useState<SupplierOption[]>([]);
  const [isRefreshingSupplierData, setIsRefreshingSupplierData] = useState(false);

  const refreshQueryListData = async () => {
    if (areQueriesLoading) {
      return;
    }
    setIsRefreshingApiData(true);
    setAreQueriesLoading(true);

    let allQueries: QuerySummary[] = [];
    let currentContinuationToken = undefined;
    let backendCalls = 0;
    while (backendCalls < MAX_BACKEND_CALLS) {
      const getQueriesArgs: getQueriesType = {
        limit: BACKEND_QUERY_LIMIT,
        continuation_token: currentContinuationToken,
      };
      const { continuation_token, queries } = await queryBuilderBackend.getQueries(getQueriesArgs);
      allQueries = [...allQueries, ...queries];
      setQuerySummaries(allQueries);
      setIsRefreshingApiData(false);

      // Set new continuation_token if exists, otherwise exit loop
      if (!continuation_token) {
        break;
      } else {
        // Backend-calls variable helps prevent runaway iterations
        backendCalls++;
        currentContinuationToken = continuation_token;
      }
    }
    setAreQueriesLoading(false);
  };

  const setValueSetData = async (valueSets: ValueSet[]) => {
    valueSets.forEach((vs: ValueSet) => {
      if (vs.creation_date) {
        // Date is received as string - must parse to Date object
        vs.creation_date = new Date(vs.creation_date);
      }
    });
    setValueSets(valueSets);
  };

  // Pull data from ReportingService while continuation_token is defined
  const refreshReportListData = async () => {
    setIsRefreshingReportData(true);

    const allReports = [];
    let currentContinuationToken = undefined;
    let backendCalls = 0;
    while (backendCalls < MAX_BACKEND_CALLS) {
      const getReportsArgs: getReportsType = {
        limit: BACKEND_QUERY_LIMIT,
        continuation_token: currentContinuationToken,
      };
      const { continuation_token, reports } = await reportingBackend.getReports(getReportsArgs);
      allReports.push(...reports);

      // Set new continuation_token if exists, otherwise exit loop
      if (!continuation_token) {
        break;
      } else {
        // Backend-calls variable helps prevent runaway iterations
        backendCalls++;
        currentContinuationToken = continuation_token;
      }
    }
    setReports(allReports);
    setIsRefreshingReportData(false);
  };

  // Create a new report using ReportingService
  const createReport = async (payload: ReportPayload) => {
    await reportingBackend.createReport(payload);
  };

  // Set supplier option value by combining id and enrollment data feeds, if present, into an array
  // enrollment_data_feeds is in the form '[1, 2]' so convert it to form ['1','2']
  const setSupplierOptionValue = (supplier: SupplierModel): Array<string> => {
    const idArray = [supplier.id];
    const enrollmentArray = JSON.parse(supplier.enrollment_data_feeds).map(String);
    return enrollmentArray.length ? idArray.concat(enrollmentArray) : idArray;
  };

  // Pull data for report suppliers. Uses {value, label} form expected by the drop down.
  // Created here so it can be done once when app loads. label formed like
  // "Private Source 20 (Enrollment)" because some names already have a dash
  const refreshSupplierListData = async () => {
    setIsRefreshingSupplierData(true);

    const { suppliers } = await reportingBackend.getSuppliers();

    // Make the label in the form 'name data_type' with single ids as value
    // enrollment_data_feeds is in the form '[1, 2]' so convert it to form ['1','2']
    const supplierOptionsBeforeGrouping = suppliers.map((supplier) => ({
      value: setSupplierOptionValue(supplier),
      label: `${supplier.name} (${supplier.data_type})`,
    }));

    // Group and sort by label. Now value is an array of ids
    const supplierOptions = groupByKey(supplierOptionsBeforeGrouping).sort((a, b) =>
      a.label < b.label ? -1 : 1
    );
    setSupplierOptions(supplierOptions);
    setIsRefreshingSupplierData(false);
  };

  useEffect(() => {
    (async () => {
      // Load initial querySummary data
      refreshQueryListData();

      // Load reports
      refreshReportListData();

      // Load suppliers
      refreshSupplierListData();

      // Load initial dataset data
      const listDatasetsResults = await datasetManagementBackend.listDatasets(DATASET_LIST_PARAMS);
      const supportedDatasets = listDatasetsResults.datasets.filter(isSupportedDataset);
      setDatasets(supportedDatasets);

      // Load value sets
      const listValueSetsResults = await valueSetBackend.listValueSets();
      setValueSetData(listValueSetsResults.data);
    })();
  }, []);

  if (datasets.length === 0 || (querySummaries.length === 0 && areQueriesLoading)) {
    // If there's no explicit error and authorization is unknown, then
    // auth is still loading.
    return <div title="loading-spinner" className="page-loading"></div>;
  }

  const appContainerElement = (
    <div className={'app-container'}>
      <ToastContainer transition={Slide} />
      <Navbar onLogout={handleLogout} />
      <QueryActionsWrapper
        refreshQueryListData={refreshQueryListData}
        queryBuilderBackend={queryBuilderBackend}
      >
        <Routes>
          <Route
            path="/"
            element={
              <QueryList refreshQueryListData={refreshQueryListData} createReport={createReport} />
            }
          />
          <Route
            path="/:queryId/details"
            element={
              <QueryDetail queryBuilderBackend={queryBuilderBackend} createReport={createReport} />
            }
          />
          <Route
            path="/build"
            element={
              <QueryBuilder key="build-component" queryBuilderBackend={queryBuilderBackend} />
            }
          />
          <Route
            path="/:queryId/duplicate"
            element={
              <QueryBuilderFindSummaryWrapper
                key="duplicate-component"
                queryBuilderBackend={queryBuilderBackend}
              />
            }
          />
          <Route
            path="/reports"
            element={
              <ReportList
                refreshReportListData={refreshReportListData}
                createReport={createReport}
              />
            }
          />
        </Routes>
      </QueryActionsWrapper>
    </div>
  );

  return (
    <Router>
      <PageLoadingOverlay />
      <ApiDataContext.Provider
        value={{
          datasets: datasets,
          querySummaries: querySummaries,
          isRefreshingApiData: isRefreshingApiData,
          areQueriesLoading: areQueriesLoading,
        }}
      >
        <ValueSetContext.Provider
          value={{
            valueSets: valueSets,
            valueSetBackend: valueSetBackend,
            setValueSetData: setValueSetData,
          }}
        >
          <ReportContext.Provider
            value={{
              reports: reports,
              reportingBackend: reportingBackend,
              isRefreshingReportData: isRefreshingReportData,
            }}
          >
            <SupplierContext.Provider
              value={{
                suppliers: supplierOptions,
                supplierBackend: reportingBackend,
                isRefreshingSupplierData: isRefreshingSupplierData,
              }}
            >
              <ReferenceDataContext.Provider
                value={{
                  referenceDataBackend: referenceServiceBackend,
                }}
              >
                {appContainerElement}
              </ReferenceDataContext.Provider>
            </SupplierContext.Provider>
          </ReportContext.Provider>
        </ValueSetContext.Provider>
      </ApiDataContext.Provider>
    </Router>
  );
}
export default AppContent;
