import { ReactComponent as ResultSortArrows } from '../../images/results-sort-arrows.svg';

import './SortArrows.scss';

export const SortArrows = ({ sortSettings, thisCol }: { sortSettings: any; thisCol: string }) => {
  let selectedArrow = '';
  if (sortSettings.activeColumn === thisCol && !sortSettings.isAscending) {
    selectedArrow = 'sort-up-arrow-highlight';
  } else if (sortSettings.activeColumn === thisCol && sortSettings.isAscending) {
    selectedArrow = 'sort-down-arrow-highlight';
  }
  return (
    <div className={'sort-arrows-container'}>
      <ResultSortArrows className={selectedArrow} />
    </div>
  );
};
