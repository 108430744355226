import { externalOrganizationsTenantOppIdMap } from '../constants';

/*
 ** Return hard coded opportunity IDs, with undefined as default.
 ** Note that tenant IDs for organizations are set up in Auth0
 ** and opportunity IDs for outside organizations appear here
 */
export default function getOppID(tenantID: string | undefined): string | undefined {
  if (!tenantID) return undefined;
  return externalOrganizationsTenantOppIdMap.get(tenantID);
}
