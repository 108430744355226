import { createContext, useContext, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import { DateCriteriaConfigure } from './CriteriaTypeLogic/DateRangeCriteria';
import Criteria from './Criteria';
import AddFeedModal from '../Modal/AddFeedModal';
import { Dataset, QueryFeedGroup, QueryCriteria } from '../../types';
import { ReadOnlyContext } from './QueryBuilder';
import { queryCriteriaOptions } from '../../constants';

export type GroupData = { groupId: string };
export const GroupDataContext = createContext<GroupData>({ groupId: '' });

const DISPLAY_DATE_TOGGLE_DATATYPE = 'NOT_HIDDEN'; // TODO: 'EMR' if not hidden;
// Feed 27 = ObituaryData
// Feed 263 = Source 90
const HARDCODED_MORTALITY_FEED_IDS = [27, 263];
// TODO remove this hardcoded dataset exclusion
const INVALID_DATASET_IDS = [23, 41, 57, 58, 318, 348, 446];

type GroupProps = {
  id: string;
  index: number;
  group: any;
  setGroup: (id: string, group: QueryFeedGroup) => void;
  removeGroup: (id: string) => void;
  replicateGroup: (id: string) => void;
  datasets: Dataset[];
};
const Group = ({
  id,
  index,
  group,
  setGroup,
  removeGroup,
  replicateGroup,
  datasets,
}: GroupProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCriteriaHovered, setIsCriteriaHovered] = useState(false);
  const isFocused = isHovered && !isCriteriaHovered;
  const [showAddFeedModal, setShowAddFeedModal] = useState(false);
  const [isCriteriaConfiguring, setIsCriteriaConfiguring] = useState(true);

  const isReadOnlyView = useContext(ReadOnlyContext);

  const nonSelectedDatasets = datasets.filter(
    (dataset) =>
      !group.feeds.some((someDataset: Dataset) => someDataset.id === dataset.id) &&
      !INVALID_DATASET_IDS.includes(dataset.id)
  );

  const addFeeds = (newDatasets: Dataset[]) =>
    setGroup(id, { ...group, feeds: [...group.feeds, ...newDatasets] });

  const removeFeed = (feed_id: number) =>
    setGroup(id, { ...group, feeds: group.feeds.filter((f: Dataset) => f.id !== feed_id) });

  const criteriaOptions = queryCriteriaOptions.filter(
    (co: { value: string; label: string; validDatasetTypes: Array<string> }) => {
      const criteriaIsAlreadySelected = group.criteria.some(
        (c: QueryCriteria) => co.value === c.criteria_type
      );
      const someSelectedDatasetSupportsCriteria = group.feeds.some((someDataFeed: Dataset) =>
        co.validDatasetTypes.includes(someDataFeed.datatype)
      );

      return !criteriaIsAlreadySelected && someSelectedDatasetSupportsCriteria;
    }
  );

  const addCriteria = (criteria: QueryCriteria) =>
    setGroup(id, { ...group, criteria: [...group.criteria, criteria] });

  const removeCriteria = (criteria: QueryCriteria) =>
    setGroup(id, {
      ...group,
      criteria: group.criteria.filter((c: QueryCriteria) => c !== criteria),
    });

  const dateRangeCriteria =
    group.criteria.find((c: QueryCriteria) => c.criteria_type === 'date_range') || null;

  const dateRangeToggleDisplay: boolean | null =
    group.feeds.length === 0
      ? null
      : group.feeds.filter((feed: Dataset) => feed.datatype === DISPLAY_DATE_TOGGLE_DATATYPE)
          .length > 0;

  return (
    <GroupDataContext.Provider value={{ groupId: id }}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={'query-blder-feed-group-container'}
        style={{ borderColor: isFocused ? '#939393' : '#D6D6D6' }}
        data-testid={'feed-group-' + id}
      >
        <div className={'query-bldr-group-sidebar'}>
          <div className={'query-bldr-group-label'}>{index + 1}</div>
        </div>

        <div className={'query-blder-feed-group-feeds'}>
          {!isReadOnlyView && <button onClick={() => setShowAddFeedModal(true)}>+</button>}
          {group.feeds.length ? (
            group.feeds.map((feed: Dataset) => (
              <Feed key={feed.id} removeFeed={removeFeed} feed={feed} isReadOnly={isReadOnlyView} />
            ))
          ) : (
            <div className={'query-blder-add-feed'}>Add feed</div>
          )}
        </div>

        <div className={'query-blder-feed-group-body'}>
          <div className={'query-blder-feed-group'}>
            {/* 
              DateCriteriaConfigure handles the date configuration--not display--independently
              of the other criteria, because it's not an option from the drop down
              but rather present in every query--even if it's for all dates
            */}
            {dateRangeCriteria ? (
              <div className={'query-blder-spacer'}></div>
            ) : (
              <DateCriteriaConfigure
                criteriaOptions={[{ value: 'date_range', label: 'Date Range' }]}
                addCriteria={addCriteria}
                setIsCriteriaHovered={setIsCriteriaHovered}
                setIsCriteriaConfiguring={() => {}}
                dateRangeToggleDisplay={dateRangeToggleDisplay}
              />
            )}
            {/* 
              For display, date is treated like the other components and appears
              with them in Criteria that have been configured, i.e. those for
              which 'criteria' is defined and mapped over
            */}
            {group.criteria.map((criteria: any, i: number) => (
              <Criteria
                key={i}
                criteria={criteria}
                criteriaOptions={criteriaOptions}
                addCriteria={addCriteria}
                removeCriteria={removeCriteria}
                setIsCriteriaHovered={setIsCriteriaHovered}
                setIsCriteriaConfiguring={() => {}}
              />
            ))}
            {/* 
              The remaining, unconfigured criteria are set here. As noted above, date is
              not included
            */}
            {!isReadOnlyView && (group.criteria.length === 0 || isCriteriaConfiguring) && (
              <Criteria
                criteria={null}
                criteriaOptions={criteriaOptions}
                addCriteria={addCriteria}
                removeCriteria={removeCriteria}
                setIsCriteriaHovered={setIsCriteriaHovered}
                setIsCriteriaConfiguring={setIsCriteriaConfiguring}
              />
            )}

            {/* Only render add criteria button when there are no criteria or one not being configured*/}
            {!isReadOnlyView && !!group.criteria.length && !isCriteriaConfiguring && (
              <button
                onClick={() => setIsCriteriaConfiguring(true)}
                className={'query-blder-add-criteria-group'}
              >
                +
              </button>
            )}
          </div>

          {!isReadOnlyView && (
            <div>
              <div
                data-testid={'feed-group-' + id + '-remove'}
                onClick={() => removeGroup(id)}
                className={'query-blder-close-group'}
              >
                <Icon name="close" />
              </div>
              <div
                data-testid={'feed-group-' + id + '-replicate'}
                className={'query-blder-close-group'}
                onClick={() => replicateGroup(id)}
              >
                <Icon name="clone" />
              </div>
            </div>
          )}
        </div>

        <AddFeedModal
          show={showAddFeedModal}
          close={() => setShowAddFeedModal(false)}
          datasets={nonSelectedDatasets}
          addFeeds={addFeeds}
        />
      </div>
    </GroupDataContext.Provider>
  );
};

type FeedProps = {
  feed: Dataset;
  removeFeed: (id: number) => void;
  isReadOnly: boolean;
};
const Feed = ({ feed, removeFeed, isReadOnly }: FeedProps) => {
  return (
    <div className={'query-blder-feed-container'}>
      <div className={`query-blder-feed-color datatype-color-${feed.datatype.toLowerCase()}`} />
      <div className={'query-blder-feed-name'}>{feed.name}</div>
      {!isReadOnly && (
        <div onClick={() => removeFeed(feed.id)} className={'query-blder-feed-remove'}>
          X
        </div>
      )}
    </div>
  );
};

export default Group;
