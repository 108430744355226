import GenericModal from './shared/GenericModal';
import './RunQueryModal.scss';
import { QueryDetailDisplaySettings, QueryDetails } from '../../types';
import { valueDisplayOptions } from '../../constants';
import { Input } from 'semantic-ui-react';
import React, { useState } from 'react';
import ModalErrorTag from './ModalErrorTag';

type RunQueryModalOppButtonProps = {
  queryDetails: QueryDetails;
  setQueryDetails: (value: QueryDetails) => void;
  wrongInput: boolean;
  onBlur: () => void;
};
const RunQueryModalOppButton = ({
  queryDetails,
  setQueryDetails,
  wrongInput,
  onBlur,
}: RunQueryModalOppButtonProps) => {
  return (
    <div className="query-blder-details-field-ids">
      <span className="query-blder-details-field-prefix">HV -</span>
      <Input
        name={'query-opp'}
        className={
          wrongInput
            ? 'query-blder-details-modal-input-element element-2 query-opp-id-input error'
            : 'query-blder-details-modal-input-element element-2 query-opp-id-input correct'
        }
        placeholder={'Enter opp. ID'}
        value={queryDetails.opportunity_id}
        onChange={(e) => setQueryDetails({ ...queryDetails, opportunity_id: e.target.value })}
        error={wrongInput}
        onBlur={onBlur}
      ></Input>
    </div>
  );
};

type RunQueryModalProps = {
  isOpen: boolean;
  onSubmit: (e: React.FormEvent) => void;
  close: () => void;
  queryDetails: QueryDetails;
  setQueryDetails: (value: QueryDetails) => void;
  queryDetailsDisplay: Partial<QueryDetailDisplaySettings>;
};
const RunQueryModal = ({
  isOpen,
  onSubmit,
  close,
  queryDetails,
  setQueryDetails,
  queryDetailsDisplay,
}: RunQueryModalProps) => {
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);

  // Report name input
  const isNameValid = queryDetails.name !== undefined && queryDetails.name !== '';
  const shouldShowNameError = wasFormSubmitted && !isNameValid;

  // Opportunity ID input
  const isOppIdValid =
    queryDetails.opportunity_id && /^[0-9]{6}$/.test(queryDetails.opportunity_id);
  const shouldShowOppIdError = wasFormSubmitted && !isOppIdValid;

  const areAllFieldsValid = isNameValid && isOppIdValid;

  const closeModal = () => {
    close();
    setWasFormSubmitted(false);
  };

  const submitButton = (e: React.FormEvent): void => {
    if (areAllFieldsValid) {
      onSubmit(e);
    }
    setWasFormSubmitted(true);
    e.preventDefault();
  };

  return (
    <GenericModal
      onSubmit={submitButton}
      close={closeModal}
      headerText={'Run query'}
      show={isOpen}
      dismissButtonText={'Cancel'}
      customHeaderClassName={'query-blder-details-header'}
      modalBodyClassName={'query-blder-details-body'}
      customModalClassName={'query-blder-details-modal'}
      confirmButtonText={'Run query     ►'}
      fixedWidth="740px"
      fixedHeight="55vh"
    >
      <h5>Query name</h5>
      <Input
        name={'query-name'}
        className={'query-blder-details-modal-input-element element-1 query-name-input'}
        placeholder={'Enter name'}
        value={queryDetails.name}
        onChange={(e) => setQueryDetails({ ...queryDetails, name: e.target.value })}
      ></Input>
      {shouldShowNameError && <ModalErrorTag isIndented={false} text="Enter a valid name" />}

      {queryDetailsDisplay?.opportunity_id?.display_type !== valueDisplayOptions.HIDDEN && (
        <React.Fragment>
          <h5>Opp. ID</h5>
          <RunQueryModalOppButton
            queryDetails={queryDetails}
            setQueryDetails={setQueryDetails}
            wrongInput={shouldShowOppIdError}
            onBlur={() => {}}
          ></RunQueryModalOppButton>
          {shouldShowOppIdError && (
            <ModalErrorTag text="Please enter a 6-digit opp. ID" isIndented={false} />
          )}
        </React.Fragment>
      )}

      <h5>Notes</h5>
      <textarea
        name={'query-desc'}
        className={'query-blder-details-modal-textarea-element'}
        placeholder={'Add notes'}
        value={queryDetails.description}
        onChange={(e) => setQueryDetails({ ...queryDetails, description: e.target.value })}
      ></textarea>
    </GenericModal>
  );
};
export default RunQueryModal;
