import { useEffect, useRef, useState } from 'react';
import { Icon, Input, Form, ButtonProps, Container } from 'semantic-ui-react';

const DEFAULT_MIN_SEARCH_LENGTH = 3;
const DEFAULT_DEBOUNCE_TIME_MS = 150;
type Timer = ReturnType<typeof setTimeout>;

export default function SearchBar({
  onSearchSubmit,
  onSearchClear,
  searchPlaceholderText,
  isLoading,
  minSearchLength = DEFAULT_MIN_SEARCH_LENGTH,
}: {
  onSearchSubmit: (searchValue: string) => void;
  onSearchClear: () => void;
  searchPlaceholderText?: string;
  isLoading?: boolean;
  minSearchLength?: number;
}) {
  const [input, setInput] = useState<string>('');
  const [hasActiveSearch, setHasActiveSearch] = useState<boolean>(false);
  const timer = useRef<Timer | null>(null);

  function runSearchHandler(_event?: React.FormEvent<HTMLFormElement>): void {
    _event?.preventDefault();
    if (minSearchLength && input.length >= minSearchLength) {
      // If we have enough characters, search
      onSearchSubmit(input);
      setHasActiveSearch(input ? true : false);
    } else {
      // Otherwise, do not filter
      onSearchSubmit('');
    }
  }

  function clearSearchHandler(event: React.FormEvent<HTMLButtonElement>, data: ButtonProps) {
    setInput('');
    onSearchClear();
    setHasActiveSearch(false);
  }

  useEffect(() => {
    // Timer provides debounce for input
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      runSearchHandler();
    }, DEFAULT_DEBOUNCE_TIME_MS);
  }, [input]);

  // only show clear button if we have a loaded search, otherwise show search icon
  let icon: any = 'search';
  if (hasActiveSearch && !isLoading) {
    icon = <Icon name="close" onClick={clearSearchHandler} link />;
  }

  return (
    <Container fluid>
      <Input
        name="search"
        icon={icon}
        placeholder={searchPlaceholderText ? searchPlaceholderText : 'Search...'}
        onChange={(e: any) => {
          setInput(e.target.value);
        }}
        value={input}
        fluid
        loading={isLoading}
        style={{ flex: 1 }}
        onKeyPress={(e: any) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      />
    </Container>
  );
}
