import { useAuth0 } from '@auth0/auth0-react';

import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import ReportTable from '../Table/ReportTable';
import './ReportList.scss';
import plus from '../../images/plus.svg';
import DisplayToggle from '../DisplayToggle/DisplayToggle';
import CreateReportModal from '../Modal/CreateReportModal';
import { ReportPayload, ReportPayloadDisplaySettings } from 'src/types';
import { displayPageLoadingAtom } from '../LoadingOverlay';
import { valueDisplayOptions } from '../../constants';
import getOppID from '../../utils/get_opp_id';

export default function ReportList({
  refreshReportListData,
  createReport,
}: {
  refreshReportListData: () => Promise<void>;
  createReport: (payload: ReportPayload) => Promise<void>;
}) {
  const setPageLoading = useSetRecoilState(displayPageLoadingAtom);

  const { user } = useAuth0();
  
  const defaultPayload: ReportPayload = {
    name: undefined,
    location: undefined,
    opportunity_id: getOppID(user?.tenant_id),
    created_by: undefined,
    parameters: undefined,
    query_name: '',
  };

  let displaySettings: Partial<ReportPayloadDisplaySettings> = {};

  if (getOppID(user?.tenant_id)) {
    displaySettings.opportunity_id = { display_type: valueDisplayOptions.HIDDEN };
  }

  const [payload, setPayload] = useState<ReportPayload>(defaultPayload);
  const [isOpen, setOpen] = useState<boolean>(false);

  const clickHandler = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
    setPayload(defaultPayload);
  };
  const submitHandler = async () => {
    setPageLoading(true);
    await createReport(payload);
    setPageLoading(false);
    closeHandler();
  };
  return (
    <div className="report-list-container">
      <div className="report-list-header">
        <span className="report-list-title">HealthVerity Marketplace cohort reports</span>
        <DisplayToggle isQuery={false} />
        <div className="report-list-buttons">
          <div className="new-report-btn">
            <div className="new-report-icon">
              <img height="13" width="13" src={plus} />
            </div>

            <div className="new-report-text" onClick={clickHandler}>
              <span>New report</span>
            </div>
          </div>
        </div>
      </div>
      <hr className="report-list-divider" />
      <div className="report-list-content" data-testid="report-list-content">
        <ReportTable refreshReportListData={refreshReportListData} />
      </div>
      <CreateReportModal
        isOpen={isOpen}
        onSubmit={submitHandler}
        onClose={closeHandler}
        payload={payload}
        setPayload={setPayload}
        payloadDisplay={displaySettings}
      />
    </div>
  );
}
