import { StandardizedReferenceDataRow } from '../types';

export const dropDuplicateData = (data: StandardizedReferenceDataRow[]) => {
  /**
   * Drops any duplicate values from the input. Data will only be considered duplicated if
   * its the same across all StandardizedReferenceDataRow attributes
   *
   * @param data - Reference data in standardized format
   *
   * @returns De-duplicated data in standard format
   */
  return data.filter(
    (tag, index, array) =>
      array.findIndex(
        (t) =>
          t.code === tag.code &&
          t.name === tag.name &&
          t.level1 === tag.level1 &&
          t.level2 === tag.level2 &&
          t.level3 === tag.level3 &&
          t.level4 === tag.level4
      ) == index
  );
};

export const capitalize = (s: string) => {
  return (s.toLowerCase() && s[0].toUpperCase() + s.toLowerCase().slice(1)) || '';
};

/**
 * Diagnosis data
 */

// Single unformatted Diagnosis entity as returned by the Reference Service
export type UnformattedDiagnosisDataType = {
  icd10: {
    code: string;
    desc: string;
  };
  icd9: {
    code: string;
    desc: string;
  };
  level1: {
    code: string;
    desc: string;
  };
  level2: {
    code: string;
    desc: string;
  };
  level3: {
    code: string;
    desc: string;
  };
};

export const formatDiagnosisData = (
  data: UnformattedDiagnosisDataType[]
): StandardizedReferenceDataRow[] => {
  /**
   * @param data - Raw diagnosis data from the Reference Data Service
   * @returns StandardizedReferenceDataRow[] - Reference Data that has been put into standard schema
   */

  // First, create a mapping of all ICD10 codes -> ICD9 codes
  // We do this because we display data by ICD10 codes, but we want to
  // display any ICD9 codes that could also be associated with an ICD10 codes
  const dataGroupedByICD10Codes = data.reduce(
    (res: { [key: string]: string[] }, row: UnformattedDiagnosisDataType) => {
      const icd10Code = row['icd10']['code'];
      const icd9Code = row['icd9']['code'];
      if (icd9Code) {
        if (icd10Code in res) {
          res[icd10Code].push(icd9Code);
        } else {
          res[icd10Code] = [icd9Code];
        }
      }

      return res;
    },
    {}
  );

  // Formats the data using the grouped ICD9 codes in the name field
  const groupedData = data.map((row: UnformattedDiagnosisDataType) => {
    let name = capitalize(row['icd10']['desc']);
    const icd9Codes =
      row['icd10']['code'] in dataGroupedByICD10Codes
        ? dataGroupedByICD10Codes[row['icd10']['code']]
        : [];
    if (icd9Codes.length > 0) {
      name = name + ' (ICD9: ' + icd9Codes.join(', ') + ')';
    }
    return {
      code: row['icd10']['code'],
      name: name,
      level1: row['level1']['code'] + ': ' + capitalize(row['level1']['desc']),
      level2: row['level2']['code'] + ': ' + capitalize(row['level2']['desc']),
      level3: row['level3']['code'] + ': ' + capitalize(row['level3']['desc']),
    };
  });

  // Drops duplicates after grouping
  return dropDuplicateData(groupedData);
};

/**
 * Drug data
 */

// Single unformatted Drug entity as returned by the Reference Service
export type UnformattedDrugDataType = {
  ndc_code: string;
  level1: string;
  level2: string;
  level3: string;
  level4: string;
};

export const formatDrugData = (data: UnformattedDrugDataType[]): StandardizedReferenceDataRow[] => {
  /**
   * @param data - Raw drug data from the Reference Data Service
   * @returns StandardizedReferenceDataRow[] - Reference Data that has been put into standard schema
   */
  return data.map((row: UnformattedDrugDataType) => {
    return {
      code: row['ndc_code'],
      name: row['level4'],
      level1: row['level1'],
      level2: row['level2'],
      level3: row['level3'],
    };
  });
};

// Single procedure ICD10 Drug entity as returned by the Reference Service
export type UnformattedProcedureICD10DataType = {
  name: string;
  icd10_code: string;
  icd10_desc: string;
  icd10_order: string | number;
  icd9_code: string;
  icd9_desc: string;
  level1_desc: string;
  level2_desc: string;
  level3_desc: string;
  // Note: The following levels are part of the return but are always empty strings
  level4_desc?: string;
  level5_desc?: string;
  level6_desc?: string;
};

export const formatProcedureICD10Data = (
  data: UnformattedProcedureICD10DataType[]
): StandardizedReferenceDataRow[] => {
  /**
   * @param data - Raw procedure ICD10 data from the Reference Data Service
   * @returns StandardizedReferenceDataRow[] - Reference Data that has been put into standard schema
   */

  // First, create a mapping of all ICD10 codes -> ICD9 codes
  // We do this because we display data by ICD10 codes, but we want to
  // display any ICD9 codes that could also be associated with an ICD10 codes
  const dataGroupedByICD10Codes = data.reduce(
    (res: { [key: string]: string[] }, row: UnformattedProcedureICD10DataType) => {
      const icd10Code = row['icd10_code'];
      const icd9Code = row['icd9_code'];
      if (icd9Code) {
        if (icd10Code in res) {
          res[icd10Code].push(icd9Code);
        } else {
          res[icd10Code] = [icd9Code];
        }
      }

      return res;
    },
    {}
  );

  // Formats the data using the grouped ICD9 codes in the name field
  const groupedData = data.map((row: UnformattedProcedureICD10DataType) => {
    let name = capitalize(row['name']);
    const icd9Codes =
      row['icd10_code'] in dataGroupedByICD10Codes
        ? dataGroupedByICD10Codes[row['icd10_code']]
        : [];
    if (icd9Codes.length > 0) {
      name = name + ' (ICD9: ' + icd9Codes.join(', ') + ')';
    }
    return {
      code: row['icd10_code'],
      name: name,
      level1: 'ICD10',
      level2: row['level1_desc'],
      level3: row['level2_desc'],
      level4: row['level3_desc'],
    };
  });

  // Drops duplicates after grouping
  return dropDuplicateData(groupedData);
};

// Single procedure HCPCS/CPT Drug entity as returned by the Reference Service
export type UnformattedProcedureHCPCSCPTDataType = {
  code: string;
  name: string;
  vaccine?: string;
};

export const formatProcedureHCPCSCPTData = (
  data: UnformattedProcedureHCPCSCPTDataType[]
): StandardizedReferenceDataRow[] => {
  /**
   * @param data - Raw procedure HCPCS/CPT data from the Reference Data Service
   * @returns StandardizedReferenceDataRow[] - Reference Data that has been put into standard schema
   */
  return data.map((row: UnformattedProcedureHCPCSCPTDataType) => {
    return {
      code: row['code'],
      name: capitalize(row['name']),
      level1: 'CPT/HCPCS',
    };
  });
};

// Single lab entity as returned by the Reference Service
export type UnformattedLabDataType = {
  loinc_code: string;
  long_common_name: string;
  loinc_component: string;
  body_system: string;
  loinc_class: string;
  loinc_method: string;
  related: string;
  order_num: number;
};

export const formatLabData = (
  data: UnformattedLabDataType[]
): StandardizedReferenceDataRow[] => {
  /**
   * @param data - Raw procedure Lab data from the Reference Data Service
   * @returns StandardizedReferenceDataRow[] - Reference Data that has been put into standard schema
   */

  const sortDataByOrderNum = (data: UnformattedLabDataType[]) => data.sort((a,b) => a.order_num - b.order_num)

  return sortDataByOrderNum(data).map((row: UnformattedLabDataType) => {
    return {
      code: row['loinc_code'],
      name: capitalize(row['long_common_name']),
    };
  });
};