import { User } from '@auth0/auth0-react';
import Navbar from '../Navbar/Navbar';

import { HV_TENANT_ID } from '../../constants';

type UnauthorizedPageProps = {
  user: User | undefined;
  handleLogout: () => void;
};
const UnauthorizedPage = ({ user, handleLogout }: UnauthorizedPageProps) => {
  // HealthVerity unauthorized messaging
  let unauthorizedInstructionsContent = (
    <p>
      You do not have access to Cohort Feasibility. Please reach out for access on the Slack channel{' '}
      <i>#application-cohort-feasibility</i>.
    </p>
  );
  let userGuideContent = (
    <a
      className="float-right"
      href="https://healthverity.atlassian.net/wiki/spaces/TV/pages/1588166660/Cohort+Feasibility+-+User+Guide"
    >
      Cohort Feasibility User Guide
    </a>
  );

  // Non-HealthVerity unauthorized messaging
  if (user && !(user.tenant_id === HV_TENANT_ID)) {
    unauthorizedInstructionsContent = (
      <p>
        For any troubleshooting or issues within your HealthVerity Cohort Feasibility account,
        please contact your account team or <i>support@healthverity.com</i> for resolution.
      </p>
    );
    userGuideContent = <></>;
  }

  return (
    <div className="app-container">
      <Navbar />
      <div className="unauthorized-message">
        <h1>Cohort Feasibility</h1>
        <hr className="content-divider" />
        <div className="message-content">
          {unauthorizedInstructionsContent}
          <p>
            If you've recently been provided access and you are still seeing this message, please
            use the "Log out" button below to try again.
          </p>
          <p>
            <a href="#" onClick={handleLogout}>
              Log out
            </a>
            {userGuideContent}
          </p>
        </div>
      </div>
    </div>
  );
};
export default UnauthorizedPage;
