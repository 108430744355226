import {
  StandardizedReferenceDataRow,
  HierarchicalReferenceDataLevel,
  ReferenceCode,
} from '../types';

export const formatReferenceDataIntoHierarchy = (
  data: StandardizedReferenceDataRow[]
): HierarchicalReferenceDataLevel[] => {
  /** 
      Formats & nests data recursively based on the how many "levels" the data contains
  
      @param data - Data in StandardizedReferenceDataRow[] format
      @returns HierarchicalReferenceDataLevel[] - Data that has been grouped groupByLevels levels
      */

  if (!data.length) {
    return [];
  }

  if (data[0]['level1'] === undefined) {
    // Non-hierarchical data provided
    throw new Error('Unexpected non-hierarchical data');
  }

  // Return data put into hierarchies
  // Escape hatch is built into the recursive method so grouping
  // will occur on only the levels of data provided
  return recursiveGroupCurrentLevelReferenceDataRows(data, 'level1', 'level2', 'level3', 'level4');
};

const filterRowsByLevel = (
  currentLevelReferenceDataRows: readonly any[], // TODO: fix typehint - currentLevelReferenceDataRows is StandardizedReferenceDataRow[]
  level_key: PropertyKey,
  level_value: string
) => {
  /**
      Gets all StandardizedReferenceDataRow rows where the provided level_key has the provided level_value
      Useful for getting all relevant rows up front
  
      @param currentLevelReferenceDataRows - Data in StandardizedReferenceDataRow[] format
      @param level_key - The level attribute name (provided in descending order) to be checked.
      @param level_value - The level value that we're attempting to match
      @returns StandardizedReferenceDataRow[] - all matching levels
      */
  return currentLevelReferenceDataRows.filter((row) => row[level_key] === level_value);
};

const recursiveGroupCurrentLevelReferenceDataRows = (
  currentLevelReferenceDataRows: readonly any[], // TODO: fix typehint - currentLevelReferenceDataRows is StandardizedReferenceDataRow[]
  ...groupByLevels: Array<PropertyKey>
): HierarchicalReferenceDataLevel[] => {
  /**
   * Recursive method that takes in a flat array of reference code data in StandardizedReferenceDataRow[] format
   * and creates a nested data structure that better represents the real shape of the data based on the values of the
   * levels contained in each row.
   * The method accepts an array of flat reference data rows and an array of levels by which to group them. 
   *
   * @param currentLevelReferenceDataRows - Data in StandardizedReferenceDataRow[] format
   * @param groupByLevels - Array of levels that this data will recursively sorted into
   *
   * @returns HierarchicalReferenceDataLevel[]
   */

  // Final level reached, return empty array
  if (!groupByLevels.length) return [];

  // Holder for this level of data
  let currentLevelNestedHierarchyData: HierarchicalReferenceDataLevel[] = [];

  // Unpack remaining levels:
  // currentLevelAttrName is the current level being evaluated, ie "level1"
  // remainingAttrNames is an array of the lower child levels that will be evaluated recursively below
  const [currentLevelAttrName, ...remainingAttrNames] = groupByLevels;
  for (const currentRow of currentLevelReferenceDataRows) {
    let currentLevelName = currentRow[currentLevelAttrName]; // currentLevelName is the name/value of the current

    // Escape hatch - if the level name is undefined, don't return data here
    if (!currentLevelName) {
      return [];
    }

    // Check if this level is already added currentLevelNestedHierarchyData
    let isCurrentRowLevelAdded = currentLevelNestedHierarchyData.find(
      (level) => level['level_name'] === currentLevelName
    );

    // The data contained in this row already exists in currentLevelNestedHierarchyData so continue
    if (isCurrentRowLevelAdded) {
      continue;
    }

    // Create new HierarchicalReferenceDataLevel entry for this row's level
    // and all child levels

    // Get all rows for that match this level attribute
    const allRowsForCurrentLevel: StandardizedReferenceDataRow[] = filterRowsByLevel(
      currentLevelReferenceDataRows,
      currentLevelAttrName,
      currentLevelName
    );

    // Get the data for all rows with only the required display data
    const allRowsForCurrentLevelAsReferenceCode: ReferenceCode[] = allRowsForCurrentLevel.map((obj) => ({
      code: obj.code,
      name: obj.name,
    }));

    // Array of single codes, ["code1", "code2", ...]
    const allRowsForCurrentLevelCodesOnly: string[] = allRowsForCurrentLevel.map((obj) => obj.code);

    // Recursively get any child levels
    const childLevels: HierarchicalReferenceDataLevel[] = recursiveGroupCurrentLevelReferenceDataRows(
      allRowsForCurrentLevel,
      ...remainingAttrNames
    );

    let currentRowLevelData: HierarchicalReferenceDataLevel = {
      level_name: currentLevelName,
      level_codes_only: allRowsForCurrentLevelCodesOnly,
      // Note we only care about the level_codes data on the final entry of a tree
      // so set to an empty array if child levels exist
      level_codes: childLevels.length === 0 ? allRowsForCurrentLevelAsReferenceCode : [],
      child_levels: childLevels,
    };

    // Add the new object to thisLevels
    currentLevelNestedHierarchyData.push(currentRowLevelData);
  }

  return currentLevelNestedHierarchyData;
};
