import { codeTypes } from '../constants';

export const stringMatchesRegex = (input: string, regex: string): boolean => {
  const regExp = new RegExp(regex);
  return regExp.test(input);
};

export const getCodeTypeValidationRegex = (codeType: string) => {
  switch (codeType) {
    case codeTypes.DRUG_CODE:
      return '^[0-9]{11}$';

    case codeTypes.DIAGNOSIS_CODE:
      // Diag Regex: top line is ICD-9, bottom line is ICD-10
      // FIRST SECTIONS have optional character, non-optional wildcard
      // MIDDLE SECTIONS have non-optional (incomplete) codes, non-optional wildcard
      // LAST SECTIONS have non-optional (complete) codes, optional wildcards
      // THIS IS TO ENSURE THAT ONLY COMPLETE CODES HAVE OPTIONAL WILDCARDS
      return (
        '^(([0-9e-vE-V]?%|[0-9e-vE-V][0-9]%|[0-9e-vE-V][0-9]{2,4}%?)|' +
        '([a-zA-Z]?%|[a-zA-Z][0-9]%|[a-zA-Z][0-9][a-zA-Z0-9]{1,5}%?))$'
      );

    case codeTypes.PROCEDURE_CODE:
      return '^([0-9]{3,4}|[a-zA-Z0-9]{7}|[a-zA-Z0-9]{5}|[a-zA-Z0-9][0-9]{4})$';

    case codeTypes.LAB_CODE:
      return '^[0-9]{4,8}$';

    case codeTypes.LAB_TEXT:
      return "^[a-zA-Z0-9()#-_%'., ><*?/@]+$";

    case codeTypes.CLINICAL_OBSERVATION:
      return "^[a-zA-Z0-9()#-_%'., ><*?/@]+$";

    case codeTypes.CHARGE_DESCRIPTION:
      return "^[a-zA-Z0-9()#-_%'., ><*?/@]+$";

    default:
      return '';
  }
};
