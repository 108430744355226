import { RestApiClient } from '@healthverity/rest';
import { StandardizedReferenceDataRow } from '../types';
import {
  UnformattedDiagnosisDataType,
  UnformattedDrugDataType,
  UnformattedProcedureICD10DataType,
  UnformattedProcedureHCPCSCPTDataType,
  UnformattedLabDataType,
  formatDiagnosisData,
  formatDrugData,
  formatProcedureICD10Data,
  formatProcedureHCPCSCPTData,
  formatLabData,
} from '../utils/reference_data_formatters';

type QueryParams = {
  [search_terms: string]: string;
};

/**
 * Class to handle API Requests to the Reference Data Service.
 */
export class ReferenceServiceApiClient extends RestApiClient {
  /**
   * Backend client to hit the Reference Data Service
   *
   * @param params
   * @returns
   */

  async searchDiagnosis(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    const rawApiResponse = await this.request<UnformattedDiagnosisDataType[]>({
      path: 'diagnosis/search',
      method: 'GET',
      queryString: params,
    });
    return formatDiagnosisData(rawApiResponse);
  }

  async searchDrug(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    const rawApiResponse = await this.request<UnformattedDrugDataType[]>({
      path: 'drug/search',
      method: 'GET',
      queryString: params,
    });
    return formatDrugData(rawApiResponse);
  }

  async searchProcedureICD10(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    const rawApiResponse = await this.request<UnformattedProcedureICD10DataType[]>({
      path: 'procedure/icd10/search',
      method: 'GET',
      queryString: params,
    });
    return formatProcedureICD10Data(rawApiResponse);
  }

  async searchProcedureHCPCSCPT(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    const rawApiResponse = await this.request<UnformattedProcedureHCPCSCPTDataType[]>({
      path: 'procedure/hcpcs_cpt/search',
      method: 'GET',
      queryString: params,
    });
    return formatProcedureHCPCSCPTData(rawApiResponse);
  }

  async searchLab(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    const rawApiResponse = await this.request<UnformattedLabDataType[]>({
      path: 'lab/loinc/search',
      method: 'GET',
      queryString: params,
    });
    return formatLabData(rawApiResponse);
  }

}

export class StubReferenceServiceApiClient extends ReferenceServiceApiClient {
  async searchDiagnosis(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    return formatDiagnosisData([]);
  }
  async searchDrug(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    return formatDrugData([]);
  }
  async searchProcedureICD10(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    return formatProcedureICD10Data([]);
  }
  async searchProcedureHCPCSCPT(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    return formatProcedureHCPCSCPTData([]);
  }
  async searchLab(params: QueryParams): Promise<StandardizedReferenceDataRow[]> {
    return formatLabData([]);
  }
}
