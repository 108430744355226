export const formatDate = (dateStr: string, showTime = true): string => {
  if (!dateStr) {
    return '--';
  }

  const dateObj = new Date(dateStr);

  const formattedDay = dateObj.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  });
  const formattedYear = dateObj.toLocaleString('en-US', { year: 'numeric' });
  if (!showTime) {
    return `${formattedDay}, ${formattedYear}`;
  }

  const formattedTime = dateObj.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });
  return `${formattedDay}, ${formattedYear} [${formattedTime}]`;
};

const formatMillisecondRunTime = (milliseconds: number) => {
  let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  let runTimeStr = '';
  if (hours) {
    runTimeStr += hours + ' hrs ';
  }
  runTimeStr += minutes + ' mins';
  return runTimeStr;
};

export const calculateRunTimeStr = (startTimeStr: string, completionTimeStr: string): any => {
  if (!startTimeStr || !completionTimeStr) {
    return '--';
  }

  const startTimeDate = new Date(startTimeStr);
  const completionTimeDate = new Date(completionTimeStr);

  const runTimeMilliseconds = completionTimeDate.getTime() - startTimeDate.getTime();
  return formatMillisecondRunTime(runTimeMilliseconds);
};
