type SeparatorTupleProps = {
  index: number;
  value: string;
  sep: string;
  sepStyle?: string;
};

export const SeparatorTuple = ({ index, value, sep, sepStyle }: SeparatorTupleProps) => {
  const separatorClassName = sepStyle ? sepStyle : 'separator-string';
  return (
    <div className={'separator-tuple'}>
      {index ? <div className={separatorClassName}>{sep}</div> : <div />}
      <div className={'separator-value'}>{value}</div>
    </div>
  );
};
