import {
  Dataset,
  Query,
  QueryFeedGroup,
  QueryFeedGroups,
  QueryBlock,
  QueryBlocks,
  QueryExecutionBlock,
} from '../types';

export const generateRandomId = () => Math.random().toString(16).slice(4);

export const executionBlockToQueryBlock = (
  queryExecutionBlock: QueryExecutionBlock,
  datasetList: Dataset[],
  generateGroupId = generateRandomId
): QueryBlock => {
  let queryFeedGroups: QueryFeedGroups = {};

  if (queryExecutionBlock.feed_groups) {
    for (let exeFeedGroup of queryExecutionBlock.feed_groups) {
      if (!exeFeedGroup.dataset_ids.length) {
        continue;
      }

      // Convert Datasets
      let datasets: Dataset[] = [];
      for (let datasetId of exeFeedGroup.dataset_ids) {
        const matchedDataset = datasetList.find((dataset) => dataset.id.toString() === datasetId);
        if (matchedDataset) {
          datasets.push(matchedDataset);
        }
      }

      let feedGroup: QueryFeedGroup = {
        feeds: datasets,
        criteria: exeFeedGroup.criteria,
      };
      queryFeedGroups[generateGroupId()] = feedGroup;
    }
  }

  return {
    join_type: queryExecutionBlock.join_type,
    feed_groups: queryFeedGroups,
  };
};

export const backendQueryToQueryBlocks = (
  backendQuery: Query,
  datasetList: Dataset[]
): QueryBlocks => {
  const inclusionBlock: QueryBlock = executionBlockToQueryBlock(
    backendQuery.inclusion_block,
    datasetList
  );
  const exclusionBlock: QueryBlock = executionBlockToQueryBlock(
    backendQuery.exclusion_block,
    datasetList
  );
  return { inclusion_block: inclusionBlock, exclusion_block: exclusionBlock };
};
