import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import EntryPointAuth0 from './components/EntryPointAuth0';
import reportWebVitals from './reportWebVitals';

import '@healthverity/hv-semantic-ui/dist/semantic.min.css';
import './index.scss';

/*
 * For debugging, it may be necessary or helpful to
 * enable React.StrictMode as follows:
 *
 * root.render(
 *   <React.StrictMode>
 *     <RecoilRoot>
 *       <EntryPointAuth0 />
 *     </RecoilRoot>
 *   </React.StrictMode>
 * );
 */

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <RecoilRoot>
    <EntryPointAuth0 />
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
