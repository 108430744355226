import { useContext, useState } from 'react';
import { Popup, Table } from 'semantic-ui-react';
import { ValueSetContext } from '../AppContent';
import { ValueSet } from '../../types';
import { formatDate } from '../../utils/format_date';
import { SortArrows } from './SortArrows';

import './ValueSetSelector.scss';

const vsColumns = {
  NAME: 'name',
  CREATION_DATE: 'creation_date',
};

const areEqualValues = (val1: any, val2: any, field: string) => {
  let areEqual = false;
  // As only the date (and not the time) is used when evaluating time in the UI, we do the same here
  if (field === vsColumns.CREATION_DATE) {
    areEqual =
      val1.getFullYear() === val2.getFullYear() &&
      val1.getMonth() === val2.getMonth() &&
      val1.getDate() === val2.getDate();
  } else if (field === vsColumns.NAME) {
    areEqual = val1.toLocaleLowerCase() === val2.toLocaleLowerCase();
  }

  return areEqual;
};

export function ValueSetSelector({
  codeType,
  onValueSetSelection,
}: {
  codeType: string;
  onValueSetSelection: (valueSet: ValueSet) => void;
}) {
  const { valueSets } = useContext(ValueSetContext);
  const [sortSettings, setSortSettings] = useState({
    activeColumn: vsColumns.CREATION_DATE,
    isAscending: false,
  });

  // Handles the user changing the sort paremeters
  const handleUpdateSort = (selectedColumn: string, currentSettings: any) => {
    // Default to descending (for new column selection)
    let isAscending = false;

    // If selecting current column, toggle ascending/descending instead
    if (selectedColumn === currentSettings.activeColumn) {
      isAscending = currentSettings.isAscending ? false : true;
    }

    setSortSettings({
      activeColumn: selectedColumn,
      isAscending: isAscending,
    });
  };

  // Get value sets with the selected code type
  const valueSetsByCode = valueSets.filter((set) => {
    return set.code_type === codeType;
  });

  // If no value sets (or still loading) display loading icon
  if (valueSets.length === 0) {
    return <div className="component-loading" />;
  }

  // Sort value sets into an ordered list for display based on current sorting parameters
  valueSetsByCode.sort((vs1: ValueSet, vs2: ValueSet) => {
    // Selected column gives primary sorting values in form [vs1Val, vs2Val]; other column gives secondary
    let primarySortValues: any = [0, 0];
    let secondarySortValues: any = [0, 0];
    const comparisonResult = sortSettings.isAscending ? -1 : 1;

    // load primary and secondary sorting values for each value set
    if (sortSettings.activeColumn === vsColumns.CREATION_DATE) {
      primarySortValues = [vs1.creation_date ?? new Date(0), vs2.creation_date ?? new Date(0)];
      secondarySortValues = [vs1.name.toLocaleLowerCase(), vs2.name.toLocaleLowerCase()];
    } else {
      primarySortValues = [vs1.name.toLocaleLowerCase(), vs2.name.toLocaleLowerCase()];
      secondarySortValues = [vs1.creation_date ?? new Date(0), vs2.creation_date ?? new Date(0)];
    }

    // If primary values are equal, sort on secondary
    if (areEqualValues(primarySortValues[0], primarySortValues[1], sortSettings.activeColumn)) {
      if (secondarySortValues[0] > secondarySortValues[1]) {
        return comparisonResult;
      } else {
        return comparisonResult * -1;
      }
    }

    // Otherwise, sort on primary
    if (primarySortValues[0] > primarySortValues[1]) {
      return comparisonResult * -1;
    } else {
      return comparisonResult;
    }
  });

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textalign="left">
            <div onClick={() => handleUpdateSort(vsColumns.NAME, sortSettings)}>
              <SortArrows sortSettings={sortSettings} thisCol={vsColumns.NAME} />
              Value set
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textalign="right">
            <div onClick={() => handleUpdateSort(vsColumns.CREATION_DATE, sortSettings)}>
              <SortArrows sortSettings={sortSettings} thisCol={vsColumns.CREATION_DATE} />
              Date created
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {valueSetsByCode.length === 0 && (
          <Table.Row key="no-results">
            <Table.Cell>-</Table.Cell>
            <Table.Cell textalign="right">-</Table.Cell>
          </Table.Row>
        )}
        {valueSetsByCode.map((valueSet: ValueSet, index: number) => (
          <Table.Row key={'value-set-' + index}>
            <Table.Cell>
              <Popup
                content={valueSet.values.toString().replace(/,/g, ', ')}
                wide="very"
                trigger={
                  <div
                    data-testid="valueSet"
                    className="ui label value-set-label"
                    onClick={() => onValueSetSelection(valueSet)}
                  >
                    {valueSet.name}
                  </div>
                }
              ></Popup>
            </Table.Cell>
            <Table.Cell textalign="right">
              {valueSet.creation_date?.toLocaleString()
                ? formatDate(valueSet.creation_date?.toLocaleString(), false)
                : ''}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
