import { Button, Segment, Table } from 'semantic-ui-react';
import { ValueSet } from '../../types';
import './ValueSetSelections.scss';

import { determineDelimiterToUse } from '../../utils/string_to_csv';

export function ValueSetSelections({
  valueSets,
  setValueSets,
  csvString,
  setCsvString,
  codeType,
}: {
  valueSets: ValueSet[];
  setValueSets: React.Dispatch<React.SetStateAction<ValueSet[]>>;
  csvString: string;
  setCsvString: React.Dispatch<React.SetStateAction<string>>;
  codeType: string;
}) {
  const deselectValueSet = (valueSet: ValueSet) => {
    valueSets.splice(valueSets.indexOf(valueSet), 1);
    setValueSets([...valueSets]);
  };

  const moveValueSetToText = (valueSet: ValueSet) => {
    const delimiterToUse = determineDelimiterToUse(codeType);
    const valueSetString = valueSet.values.join(delimiterToUse);

    // Avoiding hanging delimiters prevents creating a csvString that doesn't scan cleanly to codes
    if (csvString == '') {
      setCsvString(valueSetString);
    } else {
      setCsvString(csvString.concat(delimiterToUse, valueSetString));
    }
    deselectValueSet(valueSet);
  };

  return (
    <Table>
      <Table.Body>
        {valueSets.map((valueSet: ValueSet, index: number) => {
          const joinedValuesString = '"' + valueSet.values.join('", "') + '"';
          return (
            <Table.Row key={'selected-value-set-' + index} className="value-set-selection-row">
              <Segment className="selected-value-set">
                <span className="selected-value-set-name">{valueSet.name}</span>
                <span className="selected-value-set-description">
                  {' - '.concat(valueSet.description)}
                </span>
                <Button
                  circular
                  icon="close"
                  floated="right"
                  type="button"
                  className="discard-button selected-value-set-button"
                  onClick={() => {
                    deselectValueSet(valueSet);
                  }}
                ></Button>
                <div>{joinedValuesString}</div>
                <Button
                  className="unpack-button selected-value-set-button"
                  type="button"
                  onClick={() => moveValueSetToText(valueSet)}
                  compact={true}
                >
                  UNPACK VALUE SET
                </Button>
              </Segment>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
