import { useAuth0 } from '@auth0/auth0-react';

import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { atom } from 'recoil';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { CohortServiceApiClient } from '../../backend';
import {
  QueryDetailDisplaySettings,
  QueryDetails,
  QueryBlocks,
  QueryBlock,
  QuerySummary,
} from '../../types';
import { valueDisplayOptions } from '../../constants';
import { validateQueryBlocks } from '../../utils/query_validation';
import { backendQueryToQueryBlocks } from '../../utils/query_exe_transformation';
import { ApiDataContext } from '../AppContent';
import { CenterModal } from '../Modal/shared/GenericModal';
import RunQueryModal from '../Modal/RunQueryModal';
import Block, { inclusionBlockAtom, exclusionBlockAtom, createEmptyGroup } from './QueryBlock';
import getOppID from '../../utils/get_opp_id';

import './QueryBuilder.scss';
import { ReactComponent as HomeIcon } from '../../images/home-icon.svg';
import { ReactComponent as ArrowIcon } from '../../images/run-query-arrow-icon.svg';
import { displayPageLoadingAtom } from '../LoadingOverlay';

export function QueryBuilderFindSummaryWrapper({
  queryBuilderBackend,
}: {
  queryBuilderBackend: CohortServiceApiClient;
}) {
  let { queryId } = useParams();
  const { querySummaries } = useContext(ApiDataContext);
  const matchedQuerySummary = querySummaries.find((summary) => summary.request_id === queryId);

  return useMemo(() => {
    if (!matchedQuerySummary) {
      return <div data-testid="details-loading" className="component-loading" />;
    }
    return (
      <QueryBuilder queryBuilderBackend={queryBuilderBackend} querySummary={matchedQuerySummary} />
    );
  }, [matchedQuerySummary]);
}

type ToolbarProps = {
  onClick: () => void;
};
function QueryBuilderToolbar({ onClick }: ToolbarProps) {
  return (
    <div className={'query-blder-toolbar'}>
      <div className={'nav-left nav-section'}>
        <div className={'query-blder-toolbar-details-button'}>
          <Link to="/">
            <span className={'button-icon'}>
              <HomeIcon />
            </span>
          </Link>
        </div>
      </div>
      <div className={'nav-right'}>
        <button className={'query-blder-toolbar-run-query-button'} onClick={onClick}>
          Run query <ArrowIcon style={{ marginLeft: '13px', marginTop: '-1px' }} />
        </button>
      </div>
    </div>
  );
}

type IndexEventOccurence = 'earliest' | 'latest';
export const indexEventOccurenceAtom = atom<IndexEventOccurence>({
  key: 'indexEventOccurenceData',
  default: 'earliest',
});

export const ReadOnlyContext = createContext<boolean>(true);

type QueryBuilderProps = {
  queryBuilderBackend: CohortServiceApiClient;
  querySummary?: QuerySummary | null;
  readOnlyView?: boolean;
};
export default function QueryBuilder({
  queryBuilderBackend,
  querySummary = null,
  readOnlyView = false,
}: QueryBuilderProps) {
  const navigate = useNavigate();

  const { user, logout } = useAuth0();
  const { datasets } = useContext(ApiDataContext);

  const [queryDetails, setQueryDetails] = useState<QueryDetails>({
    name: '',
    description: '',
    opportunity_id: getOppID(user?.tenant_id) || '',
  });
  const [showRunQueryModal, setShowRunQueryModal] = useState(false);
  const [submissionError, setSubmissionError] = useState<string>('');

  const setPageLoading = useSetRecoilState(displayPageLoadingAtom);
  const [indexEventOccurrence, setIndexEventOccurence] = useRecoilState(indexEventOccurenceAtom);
  const [inclusionBlock, setInclusionBlock] = useRecoilState(inclusionBlockAtom);
  const [exclusionBlock, setExclusionBlock] = useRecoilState(exclusionBlockAtom);

  const determineIndexEventOccurrence = (inclusionBlock: QueryBlock) => {
    for (const feedGroup of Object.values(inclusionBlock.feed_groups)) {
      for (const criteria of feedGroup.criteria) {
        if ('index_event' in criteria) {
          const occurrence_type = criteria.index_event?.index_event_occurrence;
          if (occurrence_type) {
            return occurrence_type;
          }
        }
      }
    }
    return 'earliest';
  };

  useEffect(() => {
    if (querySummary) {
      // Pre-load the inclusion block, exclusion block,
      // index-event data and query details from the duplicated query
      const matchedQuery = JSON.parse(querySummary.query);
      const queryBlocks: QueryBlocks = backendQueryToQueryBlocks(matchedQuery, datasets);
      setInclusionBlock(queryBlocks.inclusion_block);
      setExclusionBlock(queryBlocks.exclusion_block);
      setIndexEventOccurence(determineIndexEventOccurrence(queryBlocks.inclusion_block));
      setQueryDetails({
        name: querySummary.name,
        description: '',
        opportunity_id: querySummary.opportunity_id,
      });
      return;
    }
    setInclusionBlock({
      join_type: 'and',
      feed_groups: createEmptyGroup(),
    });
    setExclusionBlock({
      join_type: 'and',
      feed_groups: createEmptyGroup(),
    });
    setIndexEventOccurence('earliest');
  }, [datasets, querySummary]);

  const submitQueryToBackend = (e: React.FormEvent) => {
    e.preventDefault();

    let [queryblocksAreValid, validationErrorMessage] = validateQueryBlocks(
      inclusionBlock,
      exclusionBlock
    );
    if (!queryblocksAreValid) {
      setSubmissionError(validationErrorMessage);
      return;
    }

    // Logout if user information cannot be gathered from Auth0
    if (!user || !user.email) {
      logout();
      return;
    }

    // Check for presence of Name and Opportunity ID
    if (queryDetails.name === '' || queryDetails.opportunity_id === '') {
      setSubmissionError('The fields "Name" and "Opportunity ID" are required');
      return;
    }
    setPageLoading(true);

    queryBuilderBackend
      .submitQuery(queryDetails, user.email, indexEventOccurrence, inclusionBlock, exclusionBlock)
      .then(async () => {
        navigate('/');
        setPageLoading(false);
      })
      .catch((reason) => {
        console.log(`submitQuery encountered an error: ${reason}`);
        setPageLoading(false);
      });
  };

  // Manage display settings based on user tenancy
  let displaySettings: Partial<QueryDetailDisplaySettings> = {};

  if (getOppID(user?.tenant_id)) {
    displaySettings.opportunity_id = { display_type: valueDisplayOptions.HIDDEN };
  }

  const showExclusionBlock = !readOnlyView || Object.keys(exclusionBlock.feed_groups).length > 0;
  return (
    <ReadOnlyContext.Provider value={readOnlyView}>
      {readOnlyView || <QueryBuilderToolbar onClick={() => setShowRunQueryModal(true)} />}
      <div data-testid="query-builder" className="query-bldr-container">
        <Block blockType={'include'} block={inclusionBlock} datasets={datasets} />
        {showExclusionBlock && <div> ----- AND ----- </div>}
        {showExclusionBlock && (
          <Block blockType={'exclude'} block={exclusionBlock} datasets={datasets} />
        )}
      </div>

      <RunQueryModal
        isOpen={showRunQueryModal}
        onSubmit={submitQueryToBackend}
        close={() => setShowRunQueryModal(false)}
        queryDetails={queryDetails}
        setQueryDetails={setQueryDetails}
        queryDetailsDisplay={displaySettings}
      />

      <CenterModal show={submissionError !== ''} close={() => setSubmissionError('')}>
        <div className="generic-modal">
          <div style={{ margin: '25px' }}>{submissionError}</div>
        </div>
      </CenterModal>
    </ReadOnlyContext.Provider>
  );
}
