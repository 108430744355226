import React, { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { ReportContext } from '../AppContent';
import { ReportModel } from '../../types';
import { ReactComponent as RefreshIcon } from '../../images/refresh-icon.svg';
import { formatDate } from '../../utils/format_date';
import { displayPageLoadingAtom } from '../LoadingOverlay';

import './Table.scss';

const QUERY_CHUNK_SIZE = 100;

type HeaderDisplayElement = {
  displayElement: JSX.Element;
  pixelWidth: string;
};

const getHeadersObject = (
  refreshReportListData: () => Promise<void>
): { [key: string]: HeaderDisplayElement } => {
  return {
    report_name: {
      displayElement: <span>Report name</span>,
      pixelWidth: '230px',
    },
    opp_id: {
      displayElement: <span>Opp. ID</span>,
      pixelWidth: '90px',
    },
    saved_ran: {
      displayElement: <span>Saved/Ran</span>,
      pixelWidth: '170px',
    },
    completed: {
      displayElement: <span>Completed</span>,
      pixelWidth: '170px',
    },
    status: {
      displayElement: <span>Status</span>,
      pixelWidth: '80px',
    },
    created_by: {
      displayElement: <span>Created By</span>,
      pixelWidth: '100px',
    },
    refresh_button: {
      displayElement: (
        <span
          onClick={() => {
            refreshReportListData();
          }}
          className={'refresh-button-icon'}
        >
          <RefreshIcon />
        </span>
      ),
      pixelWidth: '40px',
    },
  };
};

export const STATUS_MAP: { [key: string]: string } = {
  success: 'Complete',
  failed: 'Error',
  pending: 'Pending',
  running: 'Running',
  canceled: 'Canceled',
};

/*
 * Renders one table using data passed in as props. Will display a no results
 * component when data prop is an empty list. Only displays valid rows. In this
 * case, rows that contain a query name
 */
export default function ReportTable({
  refreshReportListData,
}: {
  refreshReportListData: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const { reports, reportingBackend, isRefreshingReportData } = useContext(ReportContext);
  const [numReportsToDisplay, _setNumReportsToDisplay] = useState(QUERY_CHUNK_SIZE);
  const numReportsRef = React.useRef(numReportsToDisplay);

  const setNumQueriesToDisplay = (data: number) => {
    numReportsRef.current = data;
    _setNumReportsToDisplay(data);
  };
  const scrollableTableRef = useBottomScrollListener(
    () => {
      setNumQueriesToDisplay(numReportsRef.current + QUERY_CHUNK_SIZE);
    },
    { offset: 20 }
  );
  let shouldShowTable = true;
  let preTableContent = null;

  if (isRefreshingReportData) {
    shouldShowTable = false;
    preTableContent = <div data-testid="table-loading" className="component-loading" />;
  } else if (reports.length === 0) {
    shouldShowTable = false;
    preTableContent = <NoResults />;
  }
  const rowsToDisplay = reports.slice(0, numReportsToDisplay);
  const headersObject = getHeadersObject(async () => {
    setNumQueriesToDisplay(QUERY_CHUNK_SIZE);
    refreshReportListData();
  });

  let tableContent = (
    <table className={`query-table ${!shouldShowTable ? 'hidden' : ''}`}>
      <thead>
        <tr className={'table-headers'}>
          {Object.keys(headersObject).map((header) => (
            <th
              key={header}
              className={'table-header'}
              style={{ width: headersObject[header].pixelWidth }}
            >
              {headersObject[header].displayElement}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowsToDisplay.map((report: ReportModel, index: number) => (
          <MemoizedRow
            key={index}
            index={index}
            report={report}
            reportingBackend={reportingBackend}
            navigate={navigate}
          />
        ))}
      </tbody>
    </table>
  );
  return (
    <div>
      <div
        data-testid="query-list-table"
        ref={scrollableTableRef as React.LegacyRef<HTMLDivElement>}
        className={'table-container'}
      >
        {preTableContent}
        {tableContent}
      </div>
    </div>
  );
}

type RowProperties = {
  index: number;
  report: ReportModel;
  reportingBackend: any;
  navigate: NavigateFunction;
};

/* Renders an individual report row in the table */
function Row({ index, report, reportingBackend, navigate }: RowProperties) {
  const setPageLoading = useSetRecoilState(displayPageLoadingAtom);

  const triggerDownloadReport = async (reportId: string) => {
    setPageLoading(true);
    const isDownloadSuccessful = await reportingBackend.downloadReport(report.id);
    if (!isDownloadSuccessful) {
      toast.warn('There was an error downloading this report.', {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
    setPageLoading(false);
  };

  let downloadLinkElement = <td className="details-link incomplete-report">{report.name}</td>;
  if (STATUS_MAP[report.status] === 'Complete') {
    downloadLinkElement = (
      <td className="details-link">
        <a
          onClick={() => {
            triggerDownloadReport(report.id);
          }}
        >
          <b>{report.name}</b>
        </a>
      </td>
    );
  }

  return (
    <tr data-testid={`table-row-${index}`} className={'table-row'} style={{ cursor: 'auto' }}>
      {downloadLinkElement}
      <td>{report.opportunity_id}</td>
      <td>{formatDate(report.start_time)}</td>
      <td>{formatDate(report.completion_time)}</td>
      <td>{STATUS_MAP[report.status]}</td>
      <td>{report.created_by}</td>
    </tr>
  );
}
const rowPropsAreEqual = (prevProps: RowProperties, newProps: RowProperties) => {
  if (prevProps.index !== newProps.index || prevProps.report !== newProps.report) {
    return false;
  }

  // Do not check equality of navigate or queryActions,
  // since these are functions that never change
  return true;
};
const MemoizedRow = React.memo(Row, rowPropsAreEqual);

const NoResults = () => {
  return (
    <div data-testid="table-no-results" className="no-results">
      <span>No Reports Found</span>
    </div>
  );
};
