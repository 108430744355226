import { Query, QueryCriteriaDateRange, QueryExecutionFeedGroup, SupplierOption } from '../types';
import { criteriaTypes } from '../constants';
import { validateExecutionQuery } from './query_validation';

/*
 * Takes in a date string of the form YYYY-MM-DD and returns a Date object
 */
export const ymdStringToDate = (ymdString: string | undefined): Date | undefined => {
  if (ymdString === undefined) {
    return undefined;
  }
  const allowedSeparationChars = ['-', '/', '.'];
  let ymd: Array<string> = [];

  allowedSeparationChars.forEach((char: string) => {
    if (ymdString.includes(char)) {
      ymd = ymdString.split(char);
    }
  });

  if (ymd.length !== 3) {
    // Invalid date format
    return undefined;
  }

  if (ymd[0].length !== 4 || ymd[1].length > 2 || ymd[2].length > 2) {
    // invalid date format
    return undefined;
  }

  return new Date(
    parseInt(ymd[0]), // year
    parseInt(ymd[1]) - 1, // month index
    parseInt(ymd[2]) // day of month
  );
};

/*
 * Returns the first start and last end dates covered by a query's criteria.
 * We handle the dates as Date objects internally in order to compare them, but convert
 * to strings before returning (in format YYYY-MM-DD).
 */
export const getDateRangeFromQuery = (
  query: string
): [startDate: string | undefined, endDate: string | undefined] => {
  let [isValid, errorMsg] = validateExecutionQuery(query);
  if (!isValid) {
    return [undefined, undefined];
  }

  const jsonQuery: Query = JSON.parse(query);
  var firstStartDate = new Date('01-01-3000');
  var lastEndDate = new Date('01-01-1000');

  jsonQuery.inclusion_block.feed_groups.forEach((feedGroup) => {
    feedGroup.criteria.forEach((crit) => {
      if (crit.criteria_type === criteriaTypes.DATE_RANGE) {
        let startDate =
          ymdStringToDate((<QueryCriteriaDateRange>crit).start_date ?? '') ?? firstStartDate;
        let endDate = ymdStringToDate((<QueryCriteriaDateRange>crit).end_date ?? '') ?? lastEndDate;

        firstStartDate = firstStartDate < startDate ? firstStartDate : startDate;
        lastEndDate = lastEndDate > endDate ? lastEndDate : endDate;
      }
    });
  });

  return [
    firstStartDate.toISOString().substring(0, 10),
    lastEndDate.toISOString().substring(0, 10),
  ];
};

/*
 * Return a unique array of inclusion supplier ids from query string
 */
export const getInclusionSupplierIdsFromQuery = (query: string): Array<string> | undefined => {
  let [isValid] = validateExecutionQuery(query);
  if (!isValid) {
    return undefined;
  }

  const jsonQuery: Query = JSON.parse(query);
  const inclusionFeedGroups: Array<QueryExecutionFeedGroup> =
    jsonQuery.inclusion_block?.feed_groups;

  if (!inclusionFeedGroups) return undefined;

  // Flatten the array of suppliers in each feed group of the inclusion feeds. Result is
  // a single array of supplier dataset ids
  const inclusionSuppliers = inclusionFeedGroups.flatMap((feedGroup) => feedGroup.dataset_ids);

  // Make elements unique by converting Array to Set and back again
  const uniqueSupplierIds = Array.from(new Set(inclusionSuppliers));

  return uniqueSupplierIds;
};

/*
 * Take an array of <value: Array<String>, key: String> and group the values by label into
 * an Array of SupplierOptions, i.e. with a label and a combined Array of values
 */
export const groupByKey = (
  list: Array<{ value: Array<string>; label: string }>
): Array<SupplierOption> => {
  const map: Map<string, Array<string>> = new Map();
  list.forEach((item) => {
    const key = item.label;
    const collection = map.get(key);
    if (!collection) {
      map.set(key, item.value);
    } else {
      map.set(key, collection.concat(item.value));
    }
  });
  return Array.from(map, ([label, value]) => ({ label, value }));
};

/*
 * Takes in a list of selected supplier ids, and a list of all supplier options.
 * Returns a list of selected supplier options including the suppliers from the input selection
 * and also any supplier options that give enrollment info for the input selection.
 */
export const getSupplierOptionsFromSupplierIds = (
  uniqueSupplierIds: Array<string>,
  suppliers: Array<SupplierOption>
): Array<SupplierOption> | undefined => {
  if (!uniqueSupplierIds) return undefined;

  const sortAlphabetical = (a: SupplierOption, b: SupplierOption) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  };

  // Filter SupplierOptions that include one of our supplier IDs
  const supplierOptions = suppliers
    .filter((option) => {
      return option.value.some((supplierId) => uniqueSupplierIds.includes(supplierId));
    })
    .sort(sortAlphabetical);
  return supplierOptions;
};

/*
 * Return a sorted array of SupplierOptions corresponding to an array of unique supplier ids and
 * an array of all available SupplierOptions
 */
export const getSupplierIdsIncludingEnrollmentFromSupplierOptions = (
  supplierOptions: Array<SupplierOption>
): Array<string> | undefined => {
  if (!supplierOptions) return undefined;

  return supplierOptions.flatMap((option) => option.value);
};
