import './Navbar.scss';
import logo from '../../images/logo.png';

export default function Navbar({ onLogout }: { onLogout?: () => void }) {
  let logoutContent = undefined;
  if (onLogout) {
    logoutContent = (
      <a href="#" onClick={onLogout}>
        Log out
      </a>
    );
  }

  return (
    <div className="nav-container">
      <div className="nav-left nav-section" />
      <div className="nav-center nav-section">
        <img src={logo} />
      </div>
      <div className={'nav-right nav-section'}>{logoutContent}</div>
    </div>
  );
}
