import { QueryCriteriaMortality } from '../../../types';
import { formatCriteriaType } from './utils';
import { SeparatorTuple } from './SeparatorTuple';

import './EnrollmentCriteria.scss';

export const MortalityCriteriaConfigure = ({
  criteria_type,
  onSubmit,
}: {
  criteria_type: string;
  onSubmit: () => void;
}) => {
  // There is currently no configuration that can be done for this criteria
  onSubmit();
  return <div></div>;
};

export const MortalityCriteriaDisplay = ({ criteria }: { criteria: QueryCriteriaMortality }) => {
  return (
    <>
      <span className={'query-blder-criteria-name'}>
        {formatCriteriaType(criteria.criteria_type)}:
      </span>
      <div className="separator-value">During date of interest above</div>
    </>
  );
};
