import { codeTypes } from '../constants';

const structuredInputTypes = [
  codeTypes.DIAGNOSIS_CODE,
  codeTypes.PROCEDURE_CODE,
  codeTypes.DRUG_CODE,
  codeTypes.LAB_CODE,
];

export const determineDelimiterToUse = (string_code_type: string): string => {
  // Given a code type, determine a valid delimiter to use for that code type
  if (structuredInputTypes.includes(string_code_type)) {
    return ', ';
  }
  return '\n';
};

export const stringToCsv = (input_string: string, string_code_type: string): string[] => {
  // Structured data like LOINC codes can be delimited by comma, space, \n
  // Unstructured data delimited by \n so it can contain comma and space
  const structuredInputTypes = [
    codeTypes.DIAGNOSIS_CODE,
    codeTypes.PROCEDURE_CODE,
    codeTypes.DRUG_CODE,
    codeTypes.LAB_CODE,
    codeTypes.MEDICAL_ENROLLMENT,
    codeTypes.PHARMACY_ENROLLMENT,
  ];

  const Delimiter = {
    STRUCTURED: new RegExp(/, | |\n|,/),
    UNSTRUCTURED: new RegExp(/\n/),
  };
  let value_list: string[] = [];
  if (input_string !== '') {
    value_list = structuredInputTypes.includes(string_code_type)
      ? input_string.split(Delimiter.STRUCTURED)
      : input_string.split(Delimiter.UNSTRUCTURED);
  }

  return value_list;
};
