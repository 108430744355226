import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Input } from 'semantic-ui-react';

import Navbar from '../Navbar/Navbar';
import './ErrorPage.scss';
const TIMEOUT_DELAY = 10000;
const AUTH0_CLIENT_ID = process.env['REACT_APP_AUTH0_CLIENT_ID'] || '';
const AUTH0_DOMAIN = process.env['REACT_APP_AUTH0_DOMAIN'] || '';

const PasswordExpiredPage = () => {
  const { logout } = useAuth0();

  const [message, setMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isValid, setIsValid] = useState(false);

  const validEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i;

  const handlePasswordReset = () => {
    const url = `https://${AUTH0_DOMAIN}/dbconnections/change_password`;
    const data = {
      client_id: AUTH0_CLIENT_ID,
      email: userEmail,
      connection: 'Username-Password-Authentication',
    };

    if (!isValid) {
      return undefined;
    }

    const logoutHandler = () => logout({ returnTo: window.location.origin });

    // Send request to Auth0 Authentication API to send
    // password reset email
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      cache: 'default',
    })
      .then((response) => {
        //handle response
        setMessage(
          `Password reset email has been sent to ${userEmail}. Please follow the instructions in the email.`
        );
        setTimeout(logoutHandler, TIMEOUT_DELAY);
      })
      .catch((error) => {
        //handle error
        setMessage(
          'Sorry, something went wrong. Contact us at support@healthVerity.com if you are having trouble with your account'
        );
        setTimeout(logoutHandler, TIMEOUT_DELAY);
      });
  };

  return (
    <div className="app-container">
      <Navbar />
      <div className="unauthorized-message">
        <h1>Cohort Feasibility</h1>
        <hr className="content-divider" />
        <div className="message-content">
          {message ? (
            <div>
              <p>{message}</p>
            </div>
          ) : (
            <div>
              <p>
                Your password has expired and must be reset. Please type in your email address and
                click the “Reset Password” button.
              </p>
              <p>
                Contact us at support@healthVerity.com if you are having trouble with your account.
              </p>
              <p>
                <Input
                  name={'user-email'}
                  className={'email-input'}
                  placeholder={'Enter email address'}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setIsValid(validEmail.test(e.target.value));
                  }}
                ></Input>
              </p>
              <p className="invalid-email-message">
                {!isValid ? <span>Please enter a valid email address</span> : <span> </span>}
              </p>
              <p>
                <button onClick={handlePasswordReset} disabled={!isValid}>
                  Reset Password
                </button>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PasswordExpiredPage;
