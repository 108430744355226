import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as KebabMenuIcon } from '../../images/kebab-menu-white.svg';
import { ReactComponent as HomeIcon } from '../../images/home-icon.svg';

import { CohortServiceApiClient } from '../../backend';
import {
  Dataset,
  ReportPayload,
  QueryActions,
  QuerySummary,
  FeedResultRow,
  FeedGroupResult,
} from '../../types';
import { calculateRunTimeStr, formatDate } from '../../utils/format_date';
import { getDistinctPatientCount } from '../../utils/query_result_handler';
import { STATUS_MAP, QueryExecutionActions } from '../Table/Table';
import { ApiDataContext, QueryActionsContext } from '../AppContent';
import QueryBuilder from '../QueryBuilder/QueryBuilder';

import './QueryDetail.scss';
import '../Table/Table.scss';

function QueryDetailAttributeTable({ querySummary }: { querySummary: QuerySummary }) {
  const SCHEMA = 'query_builder';

  // Temporary logic to handle if created_time is empty
  let createdTimeValue = querySummary.created_time || querySummary.start_time;

  return (
    <div className={'query-details-table-container'}>
      <table className={'query-table query-details-table'}>
        <thead>
          <tr className={'table-headers'}>
            <th className={'table-header'}>Query attributes</th>
            <th className={'table-header'}>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Opportunity ID</td>
            <td>HV-{querySummary.opportunity_id}</td>
          </tr>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Status</td>
            <td>{STATUS_MAP[querySummary.status]}</td>
          </tr>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Saved / Ran</td>
            <td>{formatDate(createdTimeValue)}</td>
          </tr>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Completed</td>
            <td>{formatDate(querySummary.completion_time)}</td>
          </tr>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Run time</td>
            <td>{calculateRunTimeStr(createdTimeValue, querySummary.completion_time)}</td>
          </tr>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Created by</td>
            <td>{querySummary.created_by || '--'}</td>
          </tr>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Notes</td>
            <td>{querySummary.description ? querySummary.description : '--'}</td>
          </tr>
          <tr className={'table-row'} style={{ cursor: 'auto' }}>
            <td>Overall resulting table location</td>
            <td>
              {querySummary.status === 'success' ? `${SCHEMA}.${querySummary.request_id}` : '--'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function ExistenceCountsTable({
  querySummary,
  datasets,
}: {
  querySummary: QuerySummary;
  datasets: Dataset[];
}) {
  const resultsObj = JSON.parse(querySummary.results_json);
  const existenceCountsMapping = resultsObj?.patient_counts?.existence_in_dataset_results;
  if (!existenceCountsMapping || datasets.length == 0) {
    // If no existence counts,
    // or datasets are still loading, do not display this table
    return <></>;
  }

  let tableRowElements = [];
  for (let datasetId in existenceCountsMapping) {
    const matchingDataset = datasets.find((dSet) => dSet.id.toString() == datasetId);
    if (matchingDataset === undefined) {
      continue;
    }

    const existenceCountString =
      existenceCountsMapping[datasetId as keyof typeof existenceCountsMapping].toLocaleString();
    const tableRowElement = (
      <tr key={datasetId} className={'table-row'} style={{ cursor: 'auto' }}>
        <td>
          {matchingDataset.name} - {matchingDataset.datatype}
        </td>
        <td>See below</td>
        <td>{existenceCountString}</td>
      </tr>
    );
    tableRowElements.push(tableRowElement);
  }

  return (
    <div className={'query-attributes-container query-results-container'}>
      <div className={'query-details-table-container'}>
        <div className={'results-block-title'}>
          <div className={'results-block-group'}>All Groups </div>
          <div className={'results-block-detail'}>
            Distinct Patient Count: {getDistinctPatientCount(querySummary)}
          </div>
        </div>
        <table
          id="provider-counts-table"
          className={'query-table query-results-table query-details-table'}
        >
          <thead>
            <tr className={'table-headers'}>
              <th className={'table-header'}>Data source</th>
              <th className={'table-header'}>Qualified in</th>
              <th className={'table-header'}>Exists in (for pricing purposes)</th>
            </tr>
          </thead>
          <tbody>{tableRowElements}</tbody>
        </table>
      </div>
    </div>
  );
}

const prettyPrint = (query: string | undefined) => {
  if (!query) return undefined;
  const jsonQuery = JSON.parse(query);
  return JSON.stringify(jsonQuery, null, 2);
};

export function combineCountsByFeed(
  feedGroupResult: FeedGroupResult,
  datasets: Array<Dataset>
): Array<FeedResultRow> {
  // Gather all the unique keys from existence and qualifying results from the
  // input FeedGroupResult

  const existenceKeys = Object.keys(feedGroupResult['existence_in_dataset_results']);
  const qualifyingKeys = Object.keys(feedGroupResult['qualifying_dataset_results']);
  const allKeys: Array<string> = Array.from(new Set(existenceKeys.concat(qualifyingKeys)));

  // Make an array of FeedResultRow objects using the set of keys and the existence
  // or qualifying values. Get name, subtype from datasets in context
  const feedResultRows: Array<FeedResultRow> = allKeys.map((key: string) => ({
    key: key,
    name: datasets.find((dataset) => dataset.id.toString() === key)?.name,
    data_type: datasets.find((dataset) => dataset.id.toString() === key)?.datatype,
    existence_in_dataset_results: feedGroupResult['existence_in_dataset_results'][key],
    qualifying_dataset_results: feedGroupResult['qualifying_dataset_results'][key],
  }));

  return feedResultRows;
}

function CountsTable({
  feedGroupBlock,
  datasets,
}: {
  feedGroupBlock: Array<FeedGroupResult>;
  datasets: Array<Dataset>;
}) {
  const feedGroupResults = feedGroupBlock.map((feed: FeedGroupResult, index: number) => ({
    group: index + 1,
    distinct_patient_count: feed.distinct_patient_count,
    feeds: combineCountsByFeed(feed, datasets),
  }));
  return (
    <div>
      {feedGroupResults.map((feedGroupResult, index) => {
        return (
          <div key={index} className={'query-attributes-container query-results-container'}>
            <div className={'query-details-table-container'}>
              <div className={'results-block-title'}>
                <div className={'results-block-group'}>Group: {feedGroupResult.group} </div>
                <div className={'results-block-detail'}>
                  Distinct Patient Count: {feedGroupResult.distinct_patient_count.toLocaleString()}
                </div>
              </div>
              <table className={'query-table query-results-table query-details-table'}>
                <thead>
                  <tr className={'table-headers'}>
                    <th className={'table-header'}>Data source</th>
                    <th className={'table-header'}>Qualifed in</th>
                    <th className={'table-header'}>Exists in (for pricing purposes)</th>
                  </tr>
                </thead>
                <tbody>
                  {feedGroupResult.feeds.map((feed, subIndex) => {
                    return (
                      <tr
                        key={`${index.toString()}-${subIndex.toString()}`}
                        className={'table-row'}
                        style={{ cursor: 'auto' }}
                      >
                        <td>
                          {feed.name}-{feed.data_type}
                        </td>
                        <td>
                          {feed.qualifying_dataset_results
                            ? feed.qualifying_dataset_results.toLocaleString()
                            : '0'}
                        </td>
                        <td>
                          {feed.existence_in_dataset_results
                            ? feed.existence_in_dataset_results.toLocaleString()
                            : '0'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function QueryDetailCounts({
  querySummary,
  datasets,
}: {
  querySummary: QuerySummary;
  datasets: Array<Dataset>;
}) {
  const resultsJson = JSON.parse(querySummary.results_json);
  const inclusionBlock = resultsJson?.inclusion_block?.feed_groups.map(
    (x: { patient_counts: any }) => x.patient_counts
  );
  const exclusionBlock = resultsJson?.exclusion_block?.feed_groups.map(
    (x: { patient_counts: any }) => x.patient_counts
  );

  return (
    <div id={'query-detail-counts'} className={'query-notes-container'}>
      <h4>COUNTS</h4>
      <h5>Overall:</h5>

      <ExistenceCountsTable querySummary={querySummary} datasets={datasets} />

      <div>
        {inclusionBlock ? (
          <>
            <h5>Inclusion:</h5>
            <CountsTable feedGroupBlock={inclusionBlock} datasets={datasets} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        {exclusionBlock ? (
          <>
            <h5>Exclusion:</h5>
            <CountsTable feedGroupBlock={exclusionBlock} datasets={datasets} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={'query-notes-text'}>
        <span className={'results-emphasis'}>‘Qualified In’</span> is defined as the unique count of
        patients who meet the specified criteria.
        <ul>
          <li>
            If there are multiple criteria (e.g. diagnosis code{' '}
            <span className={'results-emphasis'}>and</span> procedure code) with multiple suppliers,
            patients would need to have each applicable criteria in at least one of the suppliers.
            This means that if a patient had a diagnosis code in Supplier 1 and a procedure code in
            Supplier 2, they’d be counted for <span className={'results-emphasis'}>each</span> of
            those suppliers, but would just be counted{' '}
            <span className={'results-emphasis'}>once</span> in the ‘Overall’.
          </li>
          <li>
            Please note that if you have multiple inclusion blocks/groups, the counts by group shown
            here are exclusive of each other (i.e. this does{' '}
            <span className={'results-emphasis'}>not</span> function like a ‘waterfall’). If you’d
            like to know how many patients met <span className={'results-emphasis'}>all</span> the
            criteria, look at the ‘Overall’ count.
          </li>
        </ul>
      </div>
      <div className={'query-notes-text'}>
        <span className={'results-emphasis'}>‘Exists In’</span> is defined as the unique count of
        patients who have <span className={'results-emphasis'}>activity</span> in a given supplier
        (i.e. included in the ‘Overall’)
        <ul>
          <li>
            These counts should only be used for pricing purposes (i.e. understanding a particular
            supplier’s unique contribution to the cohort you are pricing).
          </li>
          <li>
            For most data suppliers, ‘activity’ is defined as any record for any healthcare service
            or clinical intervention (e.g. any diagnosis, procedure, test, treatment, visit,
            hospitalization, etc).{' '}
          </li>
          <li>
            For the data suppliers below, additional logic is applied to determine whether activity
            took place:
            <ul>
              <li>
                Closed medical, closed pharmacy claims: an enrollment record must be present in the
                time frame
              </li>
              <li>EMR, Chargemaster (CDM): an encounter must be present within the time frame</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

function QueryDetailQuery({ query }: { query: string | undefined }) {
  return (
    <div data-testid="details-page-query" id={'details-json'} className={'query-notes-container'}>
      <div>Query JSON</div>
      <div className={'query-notes-text'}>
        <pre>{prettyPrint(query)}</pre>
      </div>
    </div>
  );
}

function QueryDetailResultsJson({ resultsJsonStr }: { resultsJsonStr: string }) {
  return (
    <div
      data-testid="details-page-results"
      id={'details-results-json'}
      className={'query-notes-container'}
    >
      <div>Results JSON</div>
      <div className={'query-notes-text'}>
        <pre>{prettyPrint(resultsJsonStr)}</pre>
      </div>
    </div>
  );
}

export function OptionsButton() {
  return (
    <div className={'options-button'}>
      <KebabMenuIcon />
      <div>Options</div>
    </div>
  );
}

export default function QueryDetail({
  queryBuilderBackend,
  createReport,
}: {
  queryBuilderBackend: CohortServiceApiClient;
  createReport: (payload: ReportPayload) => Promise<void>;
}) {
  const params = useParams();
  const { datasets, querySummaries } = useContext(ApiDataContext);
  const querySummary = querySummaries?.find((qs) => qs.request_id === params.queryId);
  const queryActions: QueryActions = useContext(QueryActionsContext);

  if (!querySummary) {
    return <div data-testid="details-loading" className="component-loading" />;
  }
  return (
    <div>
      <div className={'query-detail-toolbar'}>
        <div className={'container'}>
          <div className={'home-icon element'}>
            <div className={'query-blder-toolbar-details-button'}>
              <Link to="/">
                <span className={'button-icon'}>
                  <HomeIcon />
                </span>
              </Link>
            </div>
          </div>
          <div className={'query-title element'}>{querySummary?.name}</div>
          <div data-testid="query-details-options" className={'options-menu element'}>
            <QueryExecutionActions
              querySummary={querySummary}
              dropdownIcon={<OptionsButton />}
              queryActions={queryActions}
              dropdownUpward={false}
              createReport={createReport}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={'query-details-container'}>
          <div data-testid="query-details-attributes" className={'query-attributes-container'}>
            <QueryDetailAttributeTable querySummary={querySummary} />
          </div>
        </div>
      </div>
      <div className={'query-details-container'}>
        {querySummary?.results_json && (
          <QueryDetailCounts querySummary={querySummary} datasets={datasets} />
        )}
        <div id={'query-builder-details'}>
          <QueryBuilder
            queryBuilderBackend={queryBuilderBackend}
            querySummary={querySummary}
            readOnlyView={true}
          />
        </div>
        <QueryDetailQuery query={querySummary.query} />
        {querySummary?.results_json && (
          <QueryDetailResultsJson resultsJsonStr={querySummary.results_json} />
        )}
      </div>
    </div>
  );
}
