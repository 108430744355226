import { QuerySummary } from '../types';

export const getDistinctPatientCount = (querySummary: QuerySummary): string => {
  if (querySummary.results_json) {
    let resultsJson = JSON.parse(querySummary.results_json);
    return resultsJson?.patient_counts?.distinct_patient_count?.toLocaleString();
  } else if (querySummary.result) {
    return querySummary.result.toLocaleString();
  }
  return '--';
};
