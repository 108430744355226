import { useState, useEffect } from 'react';
import Select from 'react-select';

import { QueryCriteriaAge } from '../../../types';
import { formatCriteriaType } from './utils';

const AGE_CRITERIA_OPTIONS = Array.from(Array(130).keys()).map((n) => ({ value: n, label: n }));

export const AgeCriteriaConfigure = ({
  onSubmit,
}: {
  onSubmit: (startAge: string, endAge: string) => void;
}) => {
  const [startAge, setStartAge] = useState<string | null>(null);
  const [endAge, setEndAge] = useState<string | null>(null);
  useEffect(() => {
    if (startAge && endAge) {
      onSubmit(startAge, endAge);
    }
  }, [startAge, endAge]);

  return (
    <div className={'query-blder-criteria-configure'}>
      <Select
        isClearable
        options={AGE_CRITERIA_OPTIONS}
        onChange={(age) => setStartAge(age ? age.value.toString() : age)}
      />
      <Select
        isClearable
        options={AGE_CRITERIA_OPTIONS}
        onChange={(age) => setEndAge(age ? age.value.toString() : age)}
      />
    </div>
  );
};

export const AgeCriteriaDisplay = ({ criteria }: { criteria: QueryCriteriaAge }) => {
  return (
    <>
      <span className={'query-blder-criteria-name'}>
        {formatCriteriaType(criteria.criteria_type)}:
      </span>
      <div className={'query-blder-criteria-display-values'}>
        <div className={'query-blder-criteria-item'}>
          <b>From:</b> {criteria.start_age}
        </div>
        <div className={'query-blder-criteria-item'}>
          <b>To:</b> {criteria.end_age}
        </div>
      </div>
    </>
  );
};
