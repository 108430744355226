import { MutableRefObject, useLayoutEffect, useState } from 'react';

// Hook for detecting if an element has overflowed horizontally
// ref:
//   Reference to the element that may be Horizontally Overflowing
// changeableValue:
//   A value that, if it changes, could effect
//   the Horizontal Width of the element in question
export const useDoesElementHorizontalOverflow = (
  ref: MutableRefObject<any>,
  changeableValue?: string
) => {
  const [doesElementOverflow, setDoesElementOverflow] = useState(false);
  useLayoutEffect(() => {
    const { current } = ref;
    const updateOnRefChange = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      setDoesElementOverflow(hasOverflow);
    };

    // Updated state if Ref exists
    if (current) {
      updateOnRefChange();
    }
  }, [ref, changeableValue]);

  return doesElementOverflow;
};
