import { RestApiClient } from '@healthverity/rest';
import { HttpRequest } from '@healthverity/rest';
import {
  ReportListResponse,
  ReportModel,
  ReportParameters,
  ReportPayload,
  GenericSuccessResponse,
  SupplierListResponse,
} from '../types';

export type getReportsType = {
  limit?: string;
  continuation_token?: string;
};

/** Class to handle API Requests to the Cohort Service. */
export class ReportingServiceApiClient extends RestApiClient {
  /**
   * getReports makes a request to the `/reports`
   * endpoint which lists all available reports
   */

  async getReports({ limit, continuation_token }: getReportsType): Promise<ReportListResponse> {
    const reportListResponse = await this.request<ReportListResponse>({
      path: 'reports',
      method: 'GET',
      queryString:
        limit || continuation_token
          ? { limit: limit || '', continuation_token: continuation_token || '' }
          : undefined,
    });
    return reportListResponse;
  }

  /**
   * createReport makes a POST request to the `/reorts` endpoint which
   * submits a report payload and kicks off the computation
   */
  async createReport(payload: ReportPayload): Promise<GenericSuccessResponse> {
    const submissionRequest: HttpRequest = {
      path: 'reports',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    };
    return await this.request<GenericSuccessResponse>(submissionRequest);
  }

  async downloadReport(reportId: string): Promise<boolean> {
    const downloadRequest: HttpRequest = {
      path: `reports/${reportId}/download`,
      method: 'GET',
    };

    return await this.request<any>(downloadRequest)
      .then((response) => {
        // create an ephemeral "a" Tag with href to presigned file
        const link = document.createElement('a');
        link.href = response['presigned_file_url'];
        document.body.appendChild(link);

        // click the newly created "a" Tag
        link.click();

        // clean up "a" Tag after download
        document.body.removeChild(link);

        return true;
      })
      .catch(() => {
        return false;
      });
  }

  async getSuppliers(): Promise<SupplierListResponse> {
    const supplierListResponse = await this.request<SupplierListResponse>({
      path: 'report-suppliers',
      method: 'GET',
    });
    return supplierListResponse;
  }
}

/** Class to mock API Requests to the Cohort Service.   */
export class StubReportingServiceApiClient extends ReportingServiceApiClient {
  /**
   * getReports makes a request to the `/reports`
   * endpoint which returns a mock empty ReportListResponse object
   * Returns one report model with a continuation token when called without a continuation
   * token.
   * Returns one report model without a continuation token when called with a continuation
   * token.
   */

  async getReports({ limit, continuation_token }: getReportsType): Promise<ReportListResponse> {
    const reportId = !continuation_token ? '1' : '2';
    const reportName = !continuation_token ? 'First report' : 'Second report';
    const nextToken = !continuation_token ? 'token' : '';
    const createdBy = !continuation_token ? 'Some guy' : 'Another guy';

    const reportParameters: ReportParameters = {
      supplier_ids: [],
      start_date: '2020-01-01',
      end_date: '2023-01-01',
    };
    const reportModel: ReportModel = {
      id: reportId,
      name: reportName,
      location: 'mock location',
      start_time: '2023-01-01',
      completion_time: '2023-01-02',
      opportunity_id: '123456',
      status: 'success',
      created_by: createdBy,
      parameters: reportParameters,
      report_tables: [],
    };
    const mockReportListResponse: ReportListResponse = {
      continuation_token: nextToken,
      reports: [reportModel],
    };
    return mockReportListResponse;
  }
}
