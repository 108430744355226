import {
  Query,
  QueryBlock,
  QueryCriteria,
  QueryCriteriaDiagnosis,
  QueryCriteriaProcedure,
  QueryCriteriaDrug,
  QueryCriteriaLab,
  QueryCriteriaLabText,
  QueryCriteriaClinicalObservation,
  QueryCriteriaChargeDescription,
  QueryCriteriaEnrollment,
} from '../types';
import { codeTypes, criteriaTypes } from '../constants';
import { stringMatchesRegex, getCodeTypeValidationRegex } from './code_validation';

const blockContainsOnePopulatedGroup = (queryBlock: QueryBlock): boolean => {
  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (feedGroup.feeds.length) {
      return true;
    }
  }
  return false;
};

const blockContainsDateRangeCrit = (queryBlock: QueryBlock): boolean => {
  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }
    let dateRangeCriteria = feedGroup.criteria.find(
      (criteria) => criteria.criteria_type === criteriaTypes.DATE_RANGE
    );
    if (!dateRangeCriteria) {
      return false;
    }
  }
  return true;
};

const blockValidDiagnosisCrit = (queryBlock: QueryBlock): boolean => {
  const diagnosisRegex = getCodeTypeValidationRegex(codeTypes.DIAGNOSIS_CODE);
  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }

    let matchingCriteria: QueryCriteria | undefined = feedGroup.criteria.find(
      (criteria) => criteria.criteria_type === codeTypes.DIAGNOSIS_CODE
    );
    if (matchingCriteria === undefined) {
      continue;
    }

    let diagnosisCriteria: QueryCriteriaDiagnosis = <QueryCriteriaDiagnosis>matchingCriteria;
    if (!diagnosisCriteria?.diagnosis_codes) {
      continue;
    }
    for (let code of diagnosisCriteria?.diagnosis_codes) {
      if (!stringMatchesRegex(code, diagnosisRegex)) return false;
    }
  }
  return true;
};

const blockValidProcedureCrit = (queryBlock: QueryBlock): boolean => {
  const procedureRegex = getCodeTypeValidationRegex(codeTypes.PROCEDURE_CODE);

  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }

    let matchingCriteria: QueryCriteria | undefined = feedGroup.criteria.find(
      (criteria) => criteria.criteria_type === codeTypes.PROCEDURE_CODE
    );
    if (matchingCriteria === undefined) {
      continue;
    }

    let procedureCriteria: QueryCriteriaProcedure = <QueryCriteriaProcedure>matchingCriteria;
    if (!procedureCriteria?.procedure_codes) {
      continue;
    }
    for (let code of procedureCriteria?.procedure_codes) {
      if (!stringMatchesRegex(code, procedureRegex)) return false;
    }
  }
  return true;
};

const blockValidDrugCrit = (queryBlock: QueryBlock): boolean => {
  const drugRegex = getCodeTypeValidationRegex(codeTypes.DRUG_CODE);

  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }

    let matchingCriteria: QueryCriteria | undefined = feedGroup.criteria.find(
      (criteria) => criteria.criteria_type === codeTypes.DRUG_CODE
    );
    if (matchingCriteria === undefined) {
      continue;
    }

    let drugCriteria: QueryCriteriaDrug = <QueryCriteriaDrug>matchingCriteria;
    if (!drugCriteria?.drug_codes) {
      continue;
    }
    for (let code of drugCriteria?.drug_codes) {
      if (!stringMatchesRegex(code, drugRegex)) return false;
    }
  }
  return true;
};

const blockValidLabCrit = (queryBlock: QueryBlock): boolean => {
  const labRegex = getCodeTypeValidationRegex(codeTypes.LAB_CODE);

  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }

    let matchingCriteria: QueryCriteria | undefined = feedGroup.criteria.find(
      (criteria) => criteria.criteria_type === codeTypes.LAB_CODE
    );
    if (matchingCriteria === undefined) {
      continue;
    }

    let labCriteria: QueryCriteriaLab = <QueryCriteriaLab>matchingCriteria;
    if (!labCriteria?.lab_codes) {
      continue;
    }
    for (let code of labCriteria?.lab_codes) {
      if (!stringMatchesRegex(code, labRegex)) return false;
    }
  }
  return true;
};

const blockValidLabTextCrit = (queryBlock: QueryBlock): boolean => {
  const labTextRegex = getCodeTypeValidationRegex(codeTypes.LAB_TEXT);

  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }
    let labTextCriteria: QueryCriteriaLabText | undefined = feedGroup.criteria.find(
      (criteria) => criteria.criteria_type === codeTypes.LAB_TEXT
    );
    if (!labTextCriteria?.lab_texts) {
      continue;
    }
    for (let code of labTextCriteria?.lab_texts) {
      if (!stringMatchesRegex(code, labTextRegex)) return false;
    }
  }
  return true;
};

const blockValidClinicalObservationCrit = (queryBlock: QueryBlock): boolean => {
  const observationRegex = getCodeTypeValidationRegex(codeTypes.CLINICAL_OBSERVATION);

  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }
    let clinicalObservationCriteria: QueryCriteriaClinicalObservation | undefined =
      feedGroup.criteria.find(
        (criteria) => criteria.criteria_type === codeTypes.CLINICAL_OBSERVATION
      );
    if (!clinicalObservationCriteria?.clinical_observations) {
      continue;
    }
    for (let code of clinicalObservationCriteria?.clinical_observations) {
      if (!stringMatchesRegex(code, observationRegex)) return false;
    }
  }
  return true;
};

const blockValidChargeDescriptionCrit = (queryBlock: QueryBlock): boolean => {
  const chargeDescriptionRegex = getCodeTypeValidationRegex(codeTypes.CHARGE_DESCRIPTION);

  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }
    let chargeDescriptionCriteria: QueryCriteriaChargeDescription | undefined =
      feedGroup.criteria.find(
        (criteria) => criteria.criteria_type === codeTypes.CHARGE_DESCRIPTION
      );
    if (!chargeDescriptionCriteria?.charge_descriptions) {
      continue;
    }
    for (let code of chargeDescriptionCriteria?.charge_descriptions) {
      if (!stringMatchesRegex(code, chargeDescriptionRegex)) return false;
    }
  }
  return true;
};

const blockValidEnrollmentCrit = (queryBlock: QueryBlock): boolean => {
  const enrollmentRegex = '^[0-9]+$';

  for (let feedGroup of Object.values(queryBlock.feed_groups)) {
    if (!feedGroup.feeds.length) {
      continue;
    }
    let enrollmentDescriptionCriteria: QueryCriteriaEnrollment | undefined =
      feedGroup.criteria.find(
        (criteria) => criteria.criteria_type === codeTypes.MEDICAL_ENROLLMENT
      );
    if (!enrollmentDescriptionCriteria?.enrollment_suppliers) {
      continue;
    }

    if (!enrollmentDescriptionCriteria?.duration) {
      continue;
    }

    for (let supplier of enrollmentDescriptionCriteria?.enrollment_suppliers) {
      if (!stringMatchesRegex(supplier, enrollmentRegex)) return false;
    }
    if (enrollmentDescriptionCriteria?.duration) {
      if (!stringMatchesRegex(enrollmentDescriptionCriteria?.duration, enrollmentRegex))
        return false;
    }
  }
  return true;
};

export const validateQueryBlocks = (
  inclusionBlock: QueryBlock,
  exclusionBlock: QueryBlock
): [boolean, string] => {
  if (!blockContainsOnePopulatedGroup(inclusionBlock)) {
    return [false, 'The Inclusion Block must contain one feed group with a selected Datafeed'];
  }

  if (!blockContainsDateRangeCrit(inclusionBlock) || !blockContainsDateRangeCrit(exclusionBlock)) {
    return [false, 'Each Datafeed grouping must contain criteria of type "Service Date"'];
  }

  if (!blockValidDiagnosisCrit(inclusionBlock) || !blockValidDiagnosisCrit(exclusionBlock)) {
    return [false, 'Diagnosis codes must be in ICD-9 or ICD-10 format'];
  }

  if (!blockValidProcedureCrit(inclusionBlock) || !blockValidProcedureCrit(exclusionBlock)) {
    return [false, 'Procedure codes must be in ICD-9, ICD-10, CPT or HCPCS format'];
  }

  if (!blockValidDrugCrit(inclusionBlock) || !blockValidDrugCrit(exclusionBlock)) {
    return [false, 'Drug codes must be in 11 digit NDC format'];
  }

  if (!blockValidLabCrit(inclusionBlock) || !blockValidLabCrit(exclusionBlock)) {
    return [false, 'Lab codes must be in LOINC format'];
  }

  if (!blockValidLabTextCrit(inclusionBlock) || !blockValidLabTextCrit(exclusionBlock)) {
    return [false, 'Lab text strings contain forbidden characters'];
  }

  if (
    !blockValidClinicalObservationCrit(inclusionBlock) ||
    !blockValidClinicalObservationCrit(exclusionBlock)
  ) {
    return [false, 'Clinical observations strings contain forbidden characters'];
  }

  if (
    !blockValidChargeDescriptionCrit(inclusionBlock) ||
    !blockValidChargeDescriptionCrit(exclusionBlock)
  ) {
    return [false, 'Charge descriptions contain forbidden characters'];
  }

  if (!blockValidEnrollmentCrit(inclusionBlock) || !blockValidEnrollmentCrit(exclusionBlock)) {
    return [false, 'Enrollment must specify enrollment provider(s) and # of Days'];
  }
  return [true, ''];
};

// A high-level validation for executable queries
export const validateExecutionQuery = (queryString: string): [boolean, string] => {
  const queryJson: Query = JSON.parse(queryString);
  if (
    queryJson.version === undefined ||
    queryJson.exclusion_block === undefined ||
    queryJson.inclusion_block === undefined
  ) {
    return [false, 'Invalid query format'];
  }

  if (
    queryJson.inclusion_block.join_type === undefined ||
    queryJson.inclusion_block.feed_groups === undefined
  ) {
    return [false, 'Invalid query format'];
  }

  if (queryJson.inclusion_block.feed_groups.length === 0) {
    return [false, 'No feed groups found'];
  }

  return [true, ''];
};
