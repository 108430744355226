import { Input } from 'semantic-ui-react';
import './RunQueryModal.scss';
import { ReportPayload } from '../../types';

type Props = {
  payload: ReportPayload;
  setPayload: (value: ReportPayload) => void;
  wrongInput: boolean;
  onBlur: () => void;
};

const ReportOppButton = ({ payload, setPayload, wrongInput, onBlur }: Props) => {
  return (
    <div className="query-blder-details-field-ids">
      <span className="query-blder-details-field-prefix">HV -</span>
      <Input
        name={'query-opp'}
        className={
          wrongInput
            ? 'query-blder-details-modal-input-element element-2 query-opp-id-input error'
            : 'query-blder-details-modal-input-element element-2 query-opp-id-input correct'
        }
        placeholder={'Enter opp. ID'}
        value={payload.opportunity_id}
        onChange={(e) => setPayload({ ...payload, opportunity_id: e.target.value })}
        error={wrongInput}
        onBlur={onBlur}
      ></Input>
    </div>
  );
};

export default ReportOppButton;
