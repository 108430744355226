import { RestApiClient } from '@healthverity/rest';
import { Dataset } from '../types';

export type ListDatasetsResponse = {
  datasets: Dataset[];
  total: number;
  continuation_token: string;
};

type QueryParams = {
  [querystringKey: string]: string;
};

/**
 * Class to handle API Requests to the Dataset Management Service (DSM).
 */
export class DatasetServiceApiClient extends RestApiClient {
  async listDatasets(params: QueryParams): Promise<ListDatasetsResponse> {
    return await this.request<ListDatasetsResponse>({
      path: 'datasets',
      method: 'GET',
      queryString: params,
    });
  }
}


/**
 * Class to mock API Requests to the Dataset Management Service (DSM).
 */
export class StubDatasetServiceApiClient extends DatasetServiceApiClient {
  async listDatasets(params: QueryParams): Promise<ListDatasetsResponse> {
    // Mock return
    return {
      datasets: [
        {
          description: "Description for dataset 1",
          status: "active",
          ingest_date: "2022-01-01",
          data_provider: 1,
          id: 1,
          datatype: "MEDICALCLAIMS",
          name: "Dataset 1",
          is_public: true,
        },
        {
          description: "Description for dataset 2",
          status: "active",
          ingest_date: "2022-02-02",
          data_provider: 2,
          id: 2,
          datatype: "RXCLAIMS",
          name: "Dataset 2",
          is_public: true,
        },
      ],
      total: 2,
      continuation_token: "no_continue",
    };
  }
}
